import { AxiosPromise } from 'axios'
import axios from '../api/axios'
import { AuthenticateRequest, JWT } from './types'

export const authenticate = (data: AuthenticateRequest): AxiosPromise<JWT> =>
  axios.post<JWT>('/orreca/v1/users/authenticate', data)

export const logout = (): AxiosPromise<void> => axios.post<void>('/orreca/v1/users/logout')

export const refreshToken = (jwt: JWT): AxiosPromise<JWT> =>
  axios.post<JWT>('/aam/v1/refresh-jwt', { jwt: jwt.token })
