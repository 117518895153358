import classNames from 'classnames'
import React, { FC } from 'react'

interface Props {
  className?: string
}

const Body: FC<Props> = ({ className, children }) => (
  <section className={classNames(['modal-card-body', className])}>{children}</section>
)

export default Body
