import { useInfiniteScroll } from '../api/hooks'
import { UseInfiniteScroll } from '../api/types'
import { Comment, UseQetPaginatedCommentsQueryParams } from './types'

export const useGetPaginatedComments = (
  params: UseQetPaginatedCommentsQueryParams
): UseInfiniteScroll<Comment> =>
  useInfiniteScroll({
    url: `/orreca/v1/comments`,
    params,
  })
