import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetEvents, useGroupedEvents } from '../../events/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import Link from '../../router/components/Link'
import EventGroup from './EventGroup'
import LoadingEvents from './LoadingEvents'

const UpcomingEvents: FC = () => {
  const [language] = useCurrentLanguage()
  const [request] = useGetEvents({ perPage: 4, lang: language })
  const { data: events = [], isLoading } = request
  const groupedEvents = useGroupedEvents(events)

  if (isLoading) {
    return <LoadingEvents />
  }

  return (
    <div className="column is-6-tablet is-4-desktop has-margin-bottom-6-tablet">
      <div className="card content-block">
        <header className="card-header is-shadowless is-borderless has-padding-top-4 has-padding-bottom-3 has-padding-left-4">
          <h2 className="title is-4">
            <FormattedMessage
              id="front-page.UpcomingEvents.title"
              description="Upcoming events block title on front page"
              defaultMessage="Upcoming <span>Events</span>"
              values={{
                span: (msg: string) => <span className="highlight">{msg}</span>,
              }}
            />
          </h2>
        </header>
        <div className="card-content has-padding-x-0 has-padding-top-0 has-padding-bottom-0">
          {Object.keys(groupedEvents).map((date) => (
            <EventGroup key={date} date={date} events={groupedEvents[date]} />
          ))}
        </div>
        <footer className="card-footer">
          <Link to="/events" className="card-footer-item has-text-weight-bold">
            <FormattedMessage
              id="front-page.UpcomingEvents.moreEvents"
              description="More events link in block title on front page"
              defaultMessage="More Events"
            />
          </Link>
        </footer>
      </div>
    </div>
  )
}

export default UpcomingEvents
