import React, { FC } from 'react'
import Categories from '../../posts/components/Categories'
import Price from '../../products/components/Price'
import ProductImage from '../../products/components/ProductImage'
import { Product as ProductType } from '../../products/types'
import { getProductUrl } from '../../products/utils'
import Link from '../../router/components/Link'

const Product: FC<ProductType> = (props) => {
  const productUrl = getProductUrl(props)

  return (
    <div className="column is-3">
      <div className="card shop-item is-shadowless is-borderless is-transparent">
        <div className="card-image">
          <Link to={productUrl}>
            <ProductImage image={props.featuredImage} size="orreca_shop_thumbnail" />
          </Link>
        </div>
        <div className="card-content has-padding-x-0 has-padding-bottom-4 has-padding-bottom-2-tablet has-padding-top-4">
          <div className="content">
            <h2 className="title is-5 has-margin-bottom-2">
              <Link to={productUrl}>{props.title}</Link>
            </h2>
            <span className="has-text-primary has-margin-bottom-7">
              <Price priceHtml={props.priceHtml} />
            </span>
            <Categories categories={props.categories} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
