import React, { FC, useEffect, useState } from 'react'
import { useEvent } from '../../utils/hooks'
import { JwtContext } from '../context'
import { JWT, JWTEvent } from '../types'
import { getJwt, removeJwt, storeJwt } from '../utils'

const JwtProvider: FC = ({ children }) => {
  const [jwt, setJwt] = useState<JWT | undefined>(getJwt())

  useEffect(() => {
    jwt ? storeJwt(jwt) : removeJwt()
  }, [jwt])

  useEvent<JWTEvent>('jwtStorage', (event) => {
    setJwt(event.detail)
  })

  return <JwtContext.Provider value={{ jwt, setJwt }}>{children}</JwtContext.Provider>
}

export default JwtProvider
