import React, { FC } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'
import Link from '../../router/components/Link'
import { useCurrentUser } from '../../user/hooks'

const messages = defineMessages({
  subscribe: {
    id: 'newsletter.SubscribeBlock.subscribe',
    description: 'Subscribe button text in newsletter subscription block',
    defaultMessage: 'Subscribe',
  },
})

const SubscribeBlock: FC = () => {
  const [currentUser] = useCurrentUser()
  const { formatMessage } = useIntl()
  const { open } = useModal()

  return (
    <div className="column is-4 is-hidden-mobile">
      <div className="card content-block">
        <header className="card-header is-shadowless is-borderless has-padding-top-4 has-padding-bottom-3 has-padding-left-4 has-padding-right-4">
          <h2 className="title is-4">
            <FormattedMessage
              id="newsletter.SubscribeBlock.title"
              description="Title in newsletter subscription block"
              defaultMessage="Subscribe for <span>Newsletter</span>"
              values={{
                span: (msg: string) => <span className="highlight">{msg}</span>,
              }}
            />
          </h2>
        </header>
        <div className="card-content has-padding-x-4 has-padding-top-0">
          <p className="is-size-5">
            <FormattedMessage
              id="newsletter.SubscribeBlock.description"
              description="Description text in newsletter subscription block"
              defaultMessage="We inform you regularly about specialised news, news in the world of standard, current topics and further more."
            />
          </p>
          {currentUser ? (
            <Link
              to="/account/newsletter"
              className="button is-link is-fullwidth is-medium has-margin-top-4 has-text-weight-bold"
            >
              {formatMessage(messages.subscribe)}
            </Link>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              onClick={() => open(ModalType.NewsletterSignup)}
              className="button is-link is-fullwidth is-medium has-margin-top-4 has-text-weight-bold"
            >
              {formatMessage(messages.subscribe)}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default SubscribeBlock
