import React, { FC, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import QuantityPicker from '../../products/components/QuantityPicker'
import { useQuickview } from '../../quickview/hooks'
import Link from '../../router/components/Link'
import { useDebouncedFn } from '../../utils/hooks'
import { useCart } from '../hooks'
import { Item } from '../types'

const messages = defineMessages({
  remove: {
    id: 'cart.CartItem.remove',
    description: 'Remove from cart button in cart panel',
    defaultMessage: 'Remove',
  },
})

const CartItem: FC<Item> = (props) => {
  const [, { addItem, removeItem }] = useCart()
  const [quantity, setQuantity] = useState(props.quantity)
  const [changeState, handleChange] = useDebouncedFn(addItem, 350)
  const [isRemoved, setIsRemoved] = useState(false)
  const { formatMessage } = useIntl()
  const { close } = useQuickview()

  useEffect(() => {
    setQuantity(props.quantity)
  }, [props.quantity])

  return (
    <div className="cart-row">
      <div className="description">
        {props.product.isVisible ? (
          <Link to={`/shop/${props.product.id}`} onClick={() => close()}>
            <span dangerouslySetInnerHTML={{ __html: props.product.name }} />
          </Link>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: props.product.name }} />
        )}
      </div>
      <div className="amount">
        <QuantityPicker
          min={1}
          value={quantity}
          isDisabled={changeState.isLoading}
          onChange={(newQuantity: number) => {
            setQuantity(newQuantity)
            handleChange(props.product.id, newQuantity)
          }}
        />
      </div>
      <div className="price" dangerouslySetInnerHTML={{ __html: props.lineTotalHtml }} />
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a
        className="remove"
        title={formatMessage(messages.remove)}
        onClick={() => {
          if (!isRemoved) {
            setIsRemoved(true)
            removeItem(props.id)
          }
        }}
      >
        {formatMessage(messages.remove)}
      </a>
    </div>
  )
}

export default CartItem
