import { Field, FieldProps, Form, Formik } from 'formik'
import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import HorizontalField from '../../formik/components/HorizontalField'
import Status from '../../formik/components/Status'
import SubmitButton from '../../formik/components/SubmitButton'
import { useCurrentLanguage } from '../../locale/hooks'
import Modal from '../../modal/components/Modal'
import { ModalType } from '../../modal/enums'
import { useModal, usePageModal } from '../../modal/hooks'
import { useGetTermsOfService } from '../../pages/hooks'
import { createUser } from '../api'
import { useRegistrationSchema } from '../schema'

const messages = defineMessages({
  agreeToTerms: {
    id: 'user.RegistrationForm.agreeToTerms',
    description: 'Agree to terms checkbox in registration form',
    defaultMessage: 'I accept the Terms of Service',
  },
  email: {
    id: 'user.RegistrationForm.email',
    description: 'Email field in registration form',
    defaultMessage: 'Email',
  },
  username: {
    id: 'user.RegistrationForm.username',
    description: 'Username field in registration form',
    defaultMessage: 'Username',
  },
  password: {
    id: 'user.RegistrationForm.password',
    description: 'Password field in registration form',
    defaultMessage: 'Password',
  },
  passwordConfirm: {
    id: 'user.RegistrationForm.passwordConfirm',
    description: 'Repeat password field in registration form',
    defaultMessage: 'Repeat password',
  },
  submit: {
    id: 'user.RegistrationForm.submit',
    description: 'Submit button in registration form',
    defaultMessage: 'Register',
  },
  modalTitle: {
    id: 'user.RegistrationForm.modalTitle',
    description: 'Registration modal title',
    defaultMessage: 'Register',
  },
  termsOfServiceTitle: {
    id: 'user.RegistrationForm.termsOfServiceTitle',
    description: 'Terms of service block title in registration form',
    defaultMessage: 'Terms of Service',
  },
  termsOfService: {
    id: 'user.RegistrationForm.termsOfService',
    description: 'Terms of service block text in registration form',
    defaultMessage:
      'With your registration you agree to our general terms and conditions, as well as terms of service, cancellation policy and data protection regulations.',
  },
  termsOfServiceLink: {
    id: 'user.RegistrationForm.termsOfServiceLink',
    description: 'Terms of service link in registration form',
    defaultMessage: 'Terms of Service.',
  },
  unknownError: {
    id: 'user.RegistrationForm.unknownError',
    description: 'Unknown error message in registration form',
    defaultMessage: 'Something went wrong! Please try again later.',
  },
  cancel: {
    id: 'user.RegistrationForm.cancel',
    description: 'Cancel button text in registration form',
    defaultMessage: 'Cancel',
  },
})

const textFields = [
  {
    name: 'email',
    label: messages.email,
    type: 'email',
    required: true,
    isNarrow: false,
  },
  {
    name: 'password',
    label: messages.password,
    type: 'password',
    required: true,
    isNarrow: true,
    autoComplete: 'new-password',
  },
  {
    name: 'passwordConfirm',
    label: messages.passwordConfirm,
    type: 'password',
    required: true,
    isNarrow: true,
    autoComplete: 'new-password',
  },
  {
    name: 'nickname',
    label: messages.username,
    type: 'nickname',
    required: false,
    isNarrow: false,
    helpText:
      'With User name you will be morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
  },
]

const RegistrationForm: FC = () => {
  const [language] = useCurrentLanguage()
  const validationSchema = useRegistrationSchema()
  const { formatMessage } = useIntl()
  const { open, close } = useModal()
  const { pathname } = useLocation()
  const { open: openPage } = usePageModal()
  const [tosRequest] = useGetTermsOfService(language)
  const { data: tosPage } = tosRequest

  return (
    <Formik
      initialValues={{
        email: '',
        nickname: '',
        password: '',
        passwordConfirm: '',
        agreeToTerms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={({ email, password, nickname }, actions) => {
        const data = { email, password, nickname, return: pathname }
        createUser(data)
          .then(() => {
            actions.setStatus(undefined)
            open(ModalType.RegisterSuccess)
          })
          .catch(({ response: { data } }) => {
            actions.setSubmitting(false)
            actions.setStatus(data.message || formatMessage(messages.unknownError))
          })
      }}
    >
      {() => (
        <Form noValidate>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <Status />
            <div className="columns">
              <div className="column has-margin-top-1">
                {textFields.map(({ label, ...props }) => (
                  <Field
                    key={props.name}
                    component={HorizontalField}
                    label={formatMessage(label)}
                    {...props}
                  />
                ))}
                <div className="box tos-box is-shadowless has-margin-top-4">
                  <h2 className="title is-5 has-margin-bottom-2">
                    {formatMessage(messages.termsOfServiceTitle)}
                  </h2>
                  <p className="has-margin-0 has-padding-0">
                    <span className="has-margin-right-2">
                      {formatMessage(messages.termsOfService)}
                    </span>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => tosPage && openPage(tosPage.id)}>
                      {formatMessage(messages.termsOfServiceLink)}
                    </a>
                  </p>
                  <Field required name="agreeToTerms">
                    {(props: FieldProps) => (
                      <label className="checkbox has-margin-top-2">
                        <input type="checkbox" className="has-margin-right-3" {...props.field} />
                        {formatMessage(messages.agreeToTerms)}
                      </label>
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="is-aligned-right">
            <button
              type="button"
              className="button is-text is-medium has-text-weight-bold is-pulled-right"
              onClick={close}
            >
              {formatMessage(messages.cancel)}
            </button>
            <SubmitButton className="is-pulled-right">
              {formatMessage(messages.submit)}
            </SubmitButton>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default RegistrationForm
