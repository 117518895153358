import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingVideoListItem: FC = () => (
  <div className="column is-4-tablet is-3-desktop">
    <Skeleton height={206} />
  </div>
)

export default LoadingVideoListItem
