import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { getFeaturedMedia, isNewContent } from '../../posts/utils'
import { ImageSize } from '../../products/types'
import { Video } from '../types'

type Props = Video & {
  size?: ImageSize
}

const VideoThumbnail: FC<Props> = (props) => {
  const { size = 'orreca_front_small', ...video } = props

  const media = getFeaturedMedia(video)

  const mediaDetails = (media && media.media_details) || undefined
  const backgroundImage = mediaDetails ? mediaDetails.sizes[size] : undefined

  return (
    <div className="card-content has-margin-0">
      <div
        className="thumb"
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage.source_url})` : undefined,
        }}
      >
        <span className="duration">{video.crb_video_duration}</span>
      </div>
      <div className="txt has-padding-4">
        <span dangerouslySetInnerHTML={{ __html: video.title.rendered }} />
        {isNewContent(video) && (
          <span className="tag is-medium is-uppercase is-new is-rounded has-margin-left-2">
            <FormattedMessage
              id="videos.VideosList.isNew"
              description="The 'is new' tag of a video item in the video list."
              defaultMessage="New"
            />
          </span>
        )}
      </div>
    </div>
  )
}

export default VideoThumbnail
