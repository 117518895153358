import React, { FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentLanguage } from '../../locale/hooks'
import { useGetProducts } from '../../products/hooks'
import LoadingProducts from './LoadingProducts'
import Product from './Product'

const LatestProducts: FC = () => {
  const [language] = useCurrentLanguage()
  const [request] = useGetProducts({
    perPage: 4,
    orderby: 'date',
    lang: language,
  })

  const { data: products = [], isLoading } = request

  if (isLoading) {
    return <LoadingProducts />
  }

  return (
    <Fragment>
      <h1 className="title is-4 has-margin-bottom-4">
        <FormattedMessage
          id="front-page.LatestProducts.title"
          description="Latest products title on front page"
          defaultMessage="Latest in <span>Shop</span>"
          values={{
            span: (msg: string) => <span className="highlight">{msg}</span>,
          }}
        />
      </h1>
      <div className="columns">
        {products.map((product) => (
          <Product key={product.id} {...product} />
        ))}
      </div>
    </Fragment>
  )
}

export default LatestProducts
