import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import avatarFallback from '../../assets/img/ico_profile.svg'
import FormattedDateTime from '../../dates/components/FormattedDateTime'
import { useCurrentUser } from '../../user/hooks'
import { deleteComment } from '../api'
import { Comment } from '../types'

const messages = defineMessages({
  commentRemoved: {
    id: 'comments.Comments.isRemoved',
    description: 'Placeholder for a removed comment',
    defaultMessage: 'This comment has been removed',
  },
  commentRemove: {
    id: 'comments.Comments.remove',
    description: 'Delete button text',
    defaultMessage: 'Delete',
  },
})

const CommentListItem: FC<Comment> = (props) => {
  const { formatMessage } = useIntl()
  const [currentUser] = useCurrentUser()
  const [isDeleted, setIsDeleted] = useState(props.trashed)

  const userId = currentUser ? currentUser.id : null
  const canDelete = userId === props.userId && !isDeleted

  return (
    <div className="comment-item box is-radiusless is-borderless is-shadowless has-margin-0 has-margin-bottom-4">
      {canDelete && (
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <a
          className="delete is-pulled-right"
          onClick={async () => {
            try {
              if (!isDeleted) {
                setIsDeleted(true)
                await deleteComment(props.id)
              }
            } catch (error) {
              setIsDeleted(false)
            }
          }}
        >
          {formatMessage(messages.commentRemove)}
        </a>
      )}
      <div className="comment">
        <div className="meta is-clearfix has-margin-bottom-3">
          <figure className="image is-48x48 is-pulled-left has-margin-right-3">
            <img
              alt={props.username}
              src={props.avatar || avatarFallback}
              className="profile-pic"
            />
          </figure>
          <div className="text is-pulled-left">
            <p className="name has-text-weight-bold has-margin-bottom-0">{props.username}</p>
            <span className="date has-margin-0 has-text-grey">
              <FormattedDateTime value={props.date} />
            </span>
          </div>
        </div>
        {isDeleted ? (
          <p className="has-text-grey is-italic">{formatMessage(messages.commentRemoved)}</p>
        ) : (
          <p>{props.comment}</p>
        )}
      </div>
    </div>
  )
}

export default CommentListItem
