import React, { FC } from 'react'
import { FormattedDate } from 'react-intl'
import avatar from '../../assets/img/ico_profile.svg'
import FormattedCategory from '../../categories/components/FormattedCategory'
import { Post } from '../types'
import { getAuthor } from '../utils'

const PostMeta: FC<Post> = (props) => {
  const author = getAuthor(props)

  return (
    <div className="meta">
      {author && (
        <figure className="image is-48x48 is-pulled-left has-margin-right">
          <img className="profile-pic is-rounded" src={author.avatar || avatar} alt={author.name} />
        </figure>
      )}
      <div className="text is-pulled-left">
        <p className="name has-text-weight-bold has-margin-bottom-0">{author && author.name}</p>
        <span className="date has-margin-0 has-text-grey">
          <FormattedDate
            value={props.date}
            year="numeric"
            month="long"
            day="numeric"
            weekday="long"
          />
        </span>
      </div>
      <div className="tags">
        {props.categories.map((category) => (
          <FormattedCategory as="a" id={category} key={category} />
        ))}
      </div>
    </div>
  )
}

export default PostMeta
