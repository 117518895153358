import React, { FC } from 'react'
import { useModal } from '../../hooks'

interface Props {
  title?: string
  canClose?: boolean
  onClose?: () => void
}

const Header: FC<Props> = ({ title, canClose = true, onClose }) => {
  const { close } = useModal()

  return (
    <header className="modal-card-head">
      <p className="modal-card-title">{title}</p>
      {canClose && (
        <button
          type="button"
          className="delete is-large"
          aria-label="close"
          onClick={() => {
            onClose && onClose()
            close()
          }}
        />
      )}
    </header>
  )
}

export default Header
