import { Field, Form, Formik } from 'formik'
import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import Status from '../../formik/components/Status'
import SubmitButton from '../../formik/components/SubmitButton'
import TextField from '../../formik/components/TextField'
import Modal from '../../modal/components/Modal'
import { useModal } from '../../modal/hooks'
import { forgotPassword } from '../api'
import { useForgotPasswordSchema } from '../schema'

const messages = defineMessages({
  email: {
    id: 'user.ForgotPasswordForm.email',
    description: 'Email field in forgot password form',
    defaultMessage: 'Email',
  },
  submit: {
    id: 'user.ForgotPasswordForm.submit',
    description: 'Submit button in forgot password form',
    defaultMessage: 'Submit',
  },
  modalTitle: {
    id: 'user.ForgotPasswordForm.modalTitle',
    description: 'Forgot password form modal title',
    defaultMessage: 'Forgot password',
  },
  unknownError: {
    id: 'user.ForgotPasswordForm.unknownError',
    description: 'Unknown error message in forgot password form',
    defaultMessage: 'Something went wrong! Please try again later.',
  },
})

const ForgotPasswordForm: FC = () => {
  const { formatMessage } = useIntl()
  const { close } = useModal()
  const validationSchema = useForgotPasswordSchema()

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={({ email }, actions) => {
        forgotPassword(email)
          .then(() => {
            actions.setStatus(undefined)
            close()
          })
          .catch(({ response: { data } }) => {
            actions.setSubmitting(false)
            actions.setStatus(data.message || formatMessage(messages.unknownError))
          })
      }}
    >
      {() => (
        <Form noValidate>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <Status />
            <Field
              required
              type="email"
              name="email"
              autocomplete="username"
              component={TextField}
              label={formatMessage(messages.email)}
            />
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton>{formatMessage(messages.submit)}</SubmitButton>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
