import { useContext } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { useCurrentLanguage } from '../locale/hooks'
import { UseState } from '../utils/types'
import { CategoryContext } from './context'
import { Category } from './types'

export const useCategories = (): UseState<Category[]> => {
  const { categories, setCategories } = useContext(CategoryContext)
  return [categories, setCategories]
}

export const useSelectedCategories = (): UseState<number[]> => {
  const context = useContext(CategoryContext)
  return [context.selectedCategories, context.setSelectedCategories]
}

export const useGetCategories = (): UseAxios<Category[]> => {
  const [language] = useCurrentLanguage()
  return useAxios<Category[]>({
    url: '/wp/v2/categories',
    params: { lang: language },
  })
}
