import { Field, FieldProps } from 'formik'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

const AgreeToTerms: FC = () => (
  <div className="column is-12 has-margin-bottom-3">
    <div className="box tos-box is-shadowless">
      <label className="checkbox has-margin-top-2">
        <Field name="paymentMethod.agreeToTerms">
          {(props: FieldProps) => {
            const { value, ...field } = props.field
            return (
              <input type="checkbox" className="has-margin-right-3" checked={value} {...field} />
            )
          }}
        </Field>
        <span className="txt">
          <FormattedMessage
            id="checkout.AgreeToTerms.terms"
            description="Terms block in checkout form"
            defaultMessage="By confirming the payment I agree to the general terms and conditions, as well as terms of service, cancellation policy and data protection regulations."
          />
        </span>
      </label>
    </div>
  </div>
)

export default AgreeToTerms
