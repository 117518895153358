import { Field, FieldProps, Form, Formik } from 'formik'
import React, { FC } from 'react'
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl'
import SubmitButton from '../../formik/components/SubmitButton'
import { useCurrentLanguage } from '../../locale/hooks'
import Modal from '../../modal/components/Modal'
import { useModal, usePageModal } from '../../modal/hooks'
import { useGetPrivacyPolicy } from '../../pages/hooks'
import { useConsents } from '../hooks'
import { useConsentSchema } from '../schema'
import { CookieType } from '../types'

interface Option {
  code: CookieType
  name: MessageDescriptor
  description: MessageDescriptor
  required?: boolean
}

const messages = defineMessages({
  modalTitle: {
    id: 'consents.CookieConsentForm.modalTitle',
    description: 'Cookie consent modal title',
    defaultMessage: 'Accept cookies',
  },
  basicName: {
    id: 'consents.CookieConsentForm.basicName',
    description: 'Basic cookie consent name in cookie consent modal',
    defaultMessage: 'Basic',
  },
  basicDescription: {
    id: 'consents.CookieConsentForm.basicDescription',
    description: 'Basic cookie consent description in cookie consent modal',
    defaultMessage: 'Cookies that are necessary for the website to function correctly.',
  },
  comfortName: {
    id: 'consents.CookieConsentForm.comfortName',
    description: 'Comfort cookie consent name in cookie consent modal',
    defaultMessage: 'Comfort',
  },
  comfortDescription: {
    id: 'consents.CookieConsentForm.comfortDescription',
    description: 'Comfort cookie consent description in cookie consent modal',
    defaultMessage:
      'External Media - Content from video platforms and social media platforms are blocked by default. If cookies from external media are accepted, access to this content no longer requires manual consent.',
  },
  statisticsName: {
    id: 'consents.CookieConsentForm.statisticsName',
    description: 'Statistics cookie consent name in cookie consent modal',
    defaultMessage: 'Statistics',
  },
  statisticsDescription: {
    id: 'consents.CookieConsentForm.statisticsDescription',
    description: 'Statistics cookie consent description in cookie consent modal',
    defaultMessage:
      'Statistics Cookies collect information anonymously. This information helps us to understand how our visitors use our website.',
  },
})

const types: Option[] = [
  {
    code: 'basic',
    name: messages.basicName,
    description: messages.basicDescription,
    required: true,
  },
  {
    code: 'comfort',
    name: messages.comfortName,
    description: messages.comfortDescription,
  },
  {
    code: 'statistics',
    name: messages.statisticsName,
    description: messages.statisticsDescription,
  },
]

const ConsentForm: FC = () => {
  const { close } = useModal()
  const { formatMessage } = useIntl()
  const [, setConsents] = useConsents()
  const validationSchema = useConsentSchema()
  const [language] = useCurrentLanguage()
  const [request] = useGetPrivacyPolicy(language)
  const { open } = usePageModal()
  const { data: page } = request

  return (
    <Formik
      isInitialValid
      validationSchema={validationSchema}
      initialValues={{
        basic: true,
        comfort: false,
        statistics: false,
      }}
      onSubmit={(values) => {
        setConsents(values)
        close()
      }}
    >
      {(form) => (
        <Form>
          <Modal.Header title={formatMessage(messages.modalTitle)} canClose={false} />
          <Modal.Body className="pr22nik">
            <p>
              <FormattedMessage
                id="consents.CookieConsentForm.description"
                description="Description text in cookie consent modal"
                defaultMessage="We use cookies so that we can offer you the best possible website experience. This includes cookies which are necessary for the operation of the website and to manage our corporate commercial objectives, as well as other cookies which are used solely for anonymous statistical purposes, for more comfortable website settings, or for the display of personalised content. You are free to decide which categories you would like to permit. Please note that depending on the settings you choose, the full functionality of the website may no longer be available. Further information can be found in our <a>Privacy policy</a>."
                values={{
                  a: (msg: string) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a onClick={() => page && open(page.id)}>{msg}</a>
                  ),
                }}
              />
            </p>
            {types.map((type) => (
              <Field name={type.code} key={type.code}>
                {(props: FieldProps<boolean>) => (
                  <div className="field" key={type.code}>
                    <input
                      {...props.field}
                      id={type.code}
                      value={type.code}
                      type="checkbox"
                      className="is-checkradio"
                      disabled={type.required}
                      checked={props.field.value}
                      onChange={(event) => {
                        props.field.onChange(event)

                        const checked = event.target.checked
                        if (type.code === 'statistics' && checked) {
                          props.form.setFieldValue('comfort', true)
                        }

                        if (type.code === 'comfort' && !checked) {
                          props.form.setFieldValue('statistics', false)
                        }
                      }}
                    />
                    <label className="checkbox" htmlFor={type.code}>
                      <strong>{formatMessage(type.name)}</strong> -{' '}
                      {formatMessage(type.description)}
                    </label>
                  </div>
                )}
              </Field>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <div className="field is-grouped">
              <SubmitButton
                color="link"
                className="has-text-weight-bold"
                onClick={() => {
                  form.setValues({
                    basic: true,
                    comfort: true,
                    statistics: true,
                  })
                }}
              >
                <FormattedMessage
                  id="consents.CookieConsentForm.selectAll"
                  description="Select all button in cookie consent modal"
                  defaultMessage="Select all"
                />
              </SubmitButton>
              <SubmitButton color="text" className="has-text-weight-bold">
                <FormattedMessage
                  id="consents.CookieConsentForm.confirmSelected"
                  description="Confirm selected button in cookie consent modal"
                  defaultMessage="Confirm selected"
                />
              </SubmitButton>
            </div>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default ConsentForm
