import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingPodcast: FC = () => (
  <section className="section has-padding-top-4 has-padding-top-5-tablet">
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="card is-borderless is-shadowless is-transparent">
            <Skeleton height={300} />
            <div className="has-margin-top-5">
              <Skeleton width="80%" />
              <Skeleton width="40%" />
              <Skeleton width="70%" />
              <Skeleton width="60%" />
            </div>
          </div>
        </div>
        <div className="column is-4 sidebar has-margin-bottom-6">
          <Skeleton height={400} />
        </div>
      </div>
    </div>
  </section>
)

export default LoadingPodcast
