import classNames from 'classnames'
import React, { FC, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import LatestPosts from './LatestPosts'
import LatestProducts from './LatestProducts'
import UpcomingEvents from './UpcomingEvents'

const messages = defineMessages({
  title: {
    defaultMessage: 'ORRECA',
    description: 'Page title for home page',
    id: 'front-page.FrontPage.title',
  },
})

const FrontPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <section className="section has-padding-top-4 has-padding-top-6-tablet">
        <div className="container">
          <div className="news-blocks columns is-multiline">
            <LatestPosts />
            <UpcomingEvents />
          </div>
        </div>
      </section>
      <section
        className={classNames([
          'section',
          'highlighted',
          'has-padding-top-4',
          'has-padding-top-5-tablet',
          'has-padding-x-4',
          'has-padding-bottom-5',
          'has-padding-bottom-6-tablet',
          'has-margin-right-0',
          'has-margin-left-0',
          'has-margin-top-4',
        ])}
      >
        <div className="container">
          <LatestProducts />
        </div>
      </section>
    </Fragment>
  )
}

export default FrontPage
