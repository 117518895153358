import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Attachment as AttachmentType } from '../types'

interface Props {
  attachments: AttachmentType[]
}

const Attachments: FC<Props> = ({ attachments }) => {
  if (!attachments.length) {
    return null
  }

  return (
    <div className="column is-8 is-field">
      <label>
        <FormattedMessage
          id="events.Attachments.label"
          description="Attachment label in event page"
          defaultMessage="Attachments"
        />
      </label>
      <ul className="attachments has-padding-top-2">
        {attachments.map((attachment) => (
          <li key={attachment.id}>
            <span className="name">{attachment.name}</span>
            <a href={attachment.url} className="download" target="_blank" rel="noopener noreferrer">
              <FormattedMessage
                id="events.Attachments.download"
                description="Attachment download link in event page"
                defaultMessage="Download"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Attachments
