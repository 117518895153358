import CartPanel from '../cart/components/CartPanel'
import CategoriesPanel from '../categories/components/CategoriesPanel'
import MobileMenu from '../header/components/MobileMenu'
import SearchPanel from '../search/components/SearchPanel'
import FilterPanel from '../shop/components/FilterPanel'
import { QuickviewType } from './enums'

const quickviews = {
  [QuickviewType.Cart]: CartPanel,
  [QuickviewType.Categories]: CategoriesPanel,
  [QuickviewType.Menu]: MobileMenu,
  [QuickviewType.Search]: SearchPanel,
  [QuickviewType.ShopFilter]: FilterPanel,
}

export const getComponentForQuickview = (
  quickview?: QuickviewType
): React.FunctionComponent<{}> | null => (quickview ? quickviews[quickview] || null : null)
