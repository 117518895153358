import classNames from 'classnames'
import { FieldProps } from 'formik'
import { get } from 'lodash-es'
import React, { FC } from 'react'
import ErrorMessage from './ErrorMessage'

interface Props extends FieldProps {
  label: string
}

const TextArea: FC<Props> = ({ field, form, label, ...props }) => {
  const isTouched = get(form.touched, field.name)
  const hasError = isTouched && get(form.errors, field.name)
  const inputClasses = ['textarea', 'is-medium', hasError && 'is-danger']

  return (
    <div className="field">
      <label htmlFor={field.name} className="label is-medium">
        {label}
      </label>
      <div className="control">
        <textarea className={classNames(inputClasses)} {...field} {...props} />
      </div>
      <ErrorMessage name={field.name} />
    </div>
  )
}

export default TextArea
