import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetPaginatedOrders } from '../../../orders/hooks'
import { useCurrentUser } from '../../../user/hooks'
import LoadMoreButton from './LoadMoreButton'
import Order from './Order'

const ShoppingHistory: FC = () => {
  const [currentUser] = useCurrentUser()
  const [request, loadMore] = useGetPaginatedOrders({
    userId: currentUser ? currentUser.id : 0,
    perPage: 8,
  })

  const { items: orders = [], total, isLoading } = request

  return (
    <div className="panel">
      <p className="panel-heading">
        <FormattedMessage
          id="account.ShoppingHistory.heading"
          description="Shopping history panel heading"
          defaultMessage="Your orders"
        />
      </p>
      {orders.map((order) => (
        <Order key={order.id} {...order} />
      ))}
      <LoadMoreButton isLoading={isLoading} itemsLeft={total - orders.length} onClick={loadMore} />
    </div>
  )
}

export default ShoppingHistory
