import React, { FC, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useQuery } from '../../router/hooks'
import { useDebounce } from '../../utils/hooks'
import { TopicsFilterQueryParams } from '../types'

const messages = defineMessages({
  search: {
    id: 'topics.TopicsFilter.search',
    description: 'Free text search field placeholder in topics list',
    defaultMessage: 'Search for topics',
  },
})

const TopicsFilter: FC = () => {
  const { formatMessage } = useIntl()
  const [query, setQuery] = useQuery<TopicsFilterQueryParams>()
  const [search, setSearch] = useState(query.search || '')
  const [privateOnly, setPrivateOnly] = useState(!!query.private)

  const updateQuery = (): void =>
    setQuery({
      search: search || undefined,
      private: privateOnly ? '1' : undefined,
    })

  useDebounce(updateQuery, 350, [search, privateOnly])

  return (
    <header className="qa-filter card-header is-shadowless is-borderless has-padding-4">
      <div className="qa-type-toggle has-margin-top-2 has-padding-top-1">
        <input
          id="private-switch"
          type="checkbox"
          className="switch is-rounded is-medium"
          checked={privateOnly}
          onChange={() => setPrivateOnly(!privateOnly)}
        />
        <label htmlFor="private-switch">
          <FormattedMessage
            id="topics.TopicsFilter.filterPrivate"
            description="Switch that allows user to filter private topics"
            defaultMessage="Show private topics only"
          />
        </label>
      </div>

      <div className="field qa-search has-margin-bottom-2 has-margin-bottom-0-tablet has-padding-right-4">
        <p className="control has-icons-right">
          <input
            className="input is-medium search-input"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={formatMessage(messages.search)}
            aria-label={formatMessage(messages.search)}
          />
          <span className="icon is-medium is-right">
            <i className="icon icon-search" />
          </span>
        </p>
      </div>
    </header>
  )
}

export default TopicsFilter
