import React, { FC, useEffect, useRef } from 'react'
import usePortal from 'react-useportal'
import { useQuery } from '../../router/hooks'
import { useKeypress } from '../../utils/hooks'
import { ModalType } from '../enums'
import { useModal } from '../hooks'
import { getComponentForModal } from '../utils'

const ModalContainer: FC = () => {
  const { Portal } = usePortal()
  const { modal, open, close } = useModal()
  const [query, setQuery] = useQuery<{ modal: ModalType }>()
  const escapePressed = useKeypress('Escape')
  const ref = useRef<HTMLDivElement>(null)

  // Open modal if it is required by query param
  if (Object.values(ModalType).includes(query.modal)) {
    open(query.modal as ModalType)
    // Remove query param so that refresh does not open the dialog again
    setQuery({ modal: undefined })
  }

  useEffect(() => {
    modal && ref.current && ref.current.focus()
  }, [modal])

  const Component = getComponentForModal(modal)
  if (!Component) {
    return null
  }

  // Close modal on Escape key press
  if (escapePressed && modal !== ModalType.CookieConsent) {
    close()
  }

  return (
    <Portal>
      <div ref={ref} role="dialog" className="modal is-active" aria-modal="true" tabIndex={-1}>
        <div className="modal-background" />
        <div className="modal-card">
          <Component />
        </div>
      </div>
    </Portal>
  )
}

export default ModalContainer
