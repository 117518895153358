import { ParsedQuery, parse, stringify } from 'query-string'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SetQueryOptions, UseQuery } from './types'

export const useQuery = <T extends ParsedQuery>(
  options: SetQueryOptions = { replace: false }
): UseQuery<T> => {
  const history = useHistory()
  const { search } = useLocation()
  const { replace } = options

  const query = useMemo(() => parse(search), [search])

  const setQuery = useCallback(
    (patch: Partial<T>): void => {
      const newQuery = { ...query, ...patch }
      const newSearch = stringify(newQuery)
      if (replace) {
        history.replace({ search: newSearch })
      } else {
        history.push({ search: newSearch })
      }
    },
    [history, query, replace]
  )

  return [query as T, setQuery]
}
