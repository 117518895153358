import { kebabCase } from 'lodash-es'
import { stringify } from 'query-string'
import React, { FC, useMemo } from 'react'
import ReactDOM from 'react-dom'
import Spinner from '../../bulma/components/Spinner'
import { useScript } from '../../utils/hooks'
import { PayPalButtonsProps, PayPalOptions } from '../paypal'

interface Options {
  [key: string]: string | number | boolean | undefined
}

const PayPalButton: FC<PayPalButtonsProps> = (props) => {
  const { options } = props

  const queryParams = useMemo(
    () =>
      options &&
      stringify(
        Object.keys(options).reduce((carry: Options, key: string) => {
          carry[kebabCase(key)] = options[key as keyof PayPalOptions]
          return carry
        }, {})
      ),
    [options]
  )

  const [isLoaded] = useScript(`https://www.paypal.com/sdk/js?${queryParams}`)
  if (!isLoaded || window.paypal === undefined) {
    return <Spinner />
  }

  const Buttons = window.paypal.Buttons.driver('react', { React, ReactDOM })

  return <Buttons {...props} />
}

export default PayPalButton
