import React, { FC } from 'react'
import { useCurrentLanguage } from '../../locale/hooks'
import { usePageModal } from '../../modal/hooks'
import { useGetPages } from '../../pages/hooks'

const Pages: FC = () => {
  const { open } = usePageModal()
  const [lang] = useCurrentLanguage()
  const [request] = useGetPages({ lang, orderby: 'menu_order', order: 'asc' })
  const { data: pages = [] } = request

  return (
    <div className="secondary-footer has-text-centered">
      <nav className="nav">
        <div className="nav-center has-padding-top-3 has-padding-bottom-3">
          {pages.map((page) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={page.id}
              className="nav-item has-padding-left-3 has-padding-right-3"
              dangerouslySetInnerHTML={{ __html: page.title.rendered }}
              onClick={() => open(page.id)}
            />
          ))}
        </div>
      </nav>
    </div>
  )
}

export default Pages
