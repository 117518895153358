import React, { FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import Spinner from '../../bulma/components/Spinner'
import Modal from '../../modal/components/Modal'
import { useModal } from '../../modal/hooks'
import { useQuery } from '../../router/hooks'
import { useGetPage } from '../hooks'

const PageModal: FC = () => {
  const [query, setQuery] = useQuery<{ pid: string }>()
  const [request] = useGetPage(+query.pid)
  const { close } = useModal()
  const { data: page, isLoading } = request

  if (isLoading) {
    return <Spinner />
  }

  const closeModal = (): void => {
    setQuery({ pid: undefined })
    close()
  }

  if (!page) {
    closeModal()
    return null
  }

  return (
    <Fragment>
      <Modal.Header title={page.title.rendered} onClose={() => setQuery({ pid: undefined })} />
      <Modal.Body>
        <div className="content" dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="button is-primary is-medium has-text-weight-bold"
          onClick={closeModal}
        >
          <FormattedMessage
            id="pages.PageModal.close"
            description="Page modal close button text"
            defaultMessage="Close"
          />
        </button>
      </Modal.Footer>
    </Fragment>
  )
}

export default PageModal
