import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import logo from '../../assets/img/logo_orreca.png'
// import LanguagePicker from '../../locale/components/LanguagePicker'
// import { useCurrentLanguage } from '../../locale/hooks'
import NavLink from '../../router/components/NavLink'
import { useCurrentUser } from '../../user/hooks'
import { links } from '../links'
import LoginButton from './LoginButton'
import MobileMenuButton from './MobileMenuButton'
import RegisterButton from './RegisterButton'
import SearchField from './SearchField'
import SearchMenuButton from './SearchMenuButton'
import UserDropdown from './UserDropdown'

const Header: FC = () => {
  const { formatMessage } = useIntl()
  // const [currentLanguage, setCurrentLanguage] = useCurrentLanguage()
  const [currentUser] = useCurrentUser()

  return (
    <header>
      <div className="container">
        <nav className="navbar has-padding-top-1" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <SearchMenuButton />
            <MobileMenuButton />
            <NavLink to="/" className="navbar-item">
              <img src={logo} width="170" height="38" alt="ORRECA" />
            </NavLink>
          </div>
          <div className="navbar-search has-padding-left-5 is-hidden-mobile is-hidden-tablet-only">
            <SearchField />
            {/* <LanguagePicker onChange={setCurrentLanguage} value={currentLanguage} /> */}
          </div>
          <div className="navbar-menu">
            {links.map(({ text, ...props }) => (
              <NavLink
                className="navbar-item has-padding-4 has-text-weight-bold"
                activeClassName="is-active"
                key={props.to}
                {...props}
              >
                {formatMessage(text)}
              </NavLink>
            ))}
          </div>
          <div className="navbar-end is-hidden-mobile is-hidden-tablet-only">
            <div className="navbar-item">
              {currentUser ? (
                <UserDropdown />
              ) : (
                <div className="buttons">
                  <LoginButton />
                  <RegisterButton />
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
