import { noop } from 'lodash-es'
import { createContext } from 'react'
import { SetState } from '../utils/types'
import { Category } from './types'

interface Value {
  categories: Category[]
  setCategories: SetState<Category[]>
  selectedCategories: number[]
  setSelectedCategories: SetState<number[]>
}

export const CategoryContext = createContext<Value>({
  categories: [],
  setCategories: noop,
  selectedCategories: [],
  setSelectedCategories: noop,
})
