import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { parsePaginationHeaders } from '../../api/utils'
import { useSelectedCategories } from '../../categories/hooks'
import { Language } from '../../locale/enums'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetPodcasts } from '../hooks'

const AllPodcastsSwitch: FC = () => {
  const [categories] = useSelectedCategories()
  const [query, setQuery] = useQuery<{ allLanguages: string }>()

  const [language] = useCurrentLanguage()
  const [request] = useGetPodcasts({
    categories,
    lang: language === Language.English ? Language.German : Language.English,
  })

  const { headers } = request
  const { total } = parsePaginationHeaders(headers || {})

  if (total < 1) {
    return null
  }

  return (
    <div className="box is-bordered has-padding-4 has-margin-bottom-2">
      <input
        id="allPodcastsSwitch"
        type="checkbox"
        className="switch is-rounded is-medium"
        checked={query.allLanguages === 'true'}
        onChange={() =>
          setQuery({
            allLanguages: query.allLanguages === 'true' ? undefined : 'true',
          })
        }
      />
      <label htmlFor="allPodcastsSwitch">
        <FormattedMessage
          id="podcasts.AllPodcastsSwitch.foreignVideosCount"
          description="Information message of the foreign podcasts count on the podcasts page. For the German translation the translation text should reference the English language"
          defaultMessage="There are {count} Podcasts in German. Show German content."
          values={{
            count: total,
          }}
        />
      </label>
    </div>
  )
}

export default AllPodcastsSwitch
