import React, { FC } from 'react'
import Details from './Details'
import Pages from './Pages'

const Footer: FC = () => (
  <footer className="footer">
    <Details />
    <Pages />
  </footer>
)

export default Footer
