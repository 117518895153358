import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { downloadInvoice } from '../api'
import { Order } from '../types'
import OrderItem from './OrderItem'

const OrderDetails: FC<Order> = (props) => (
  <div className="panel">
    <p className="panel-heading">
      <FormattedMessage
        id="orders.OrderPage.orderDetails"
        description="Order details heading in order page"
        defaultMessage="Order Details"
      />
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      {!!(props.customerId && props.customerId > 0) && (
        <a
          onClick={() => downloadInvoice(props.orderKey)}
          className="button is-medium is-outlined is-pulled-right"
        >
          <FormattedMessage
            id="orders.OrderPage.getInvoice"
            description="Invoice link in order page"
            defaultMessage="Get Invoice"
          />
        </a>
      )}
    </p>

    {props.lineItems.map((item) => (
      <OrderItem key={item.id} item={item} />
    ))}

    <div className="panel-block is-value-pair has-padding-top-5" />

    <div className="panel-block is-value-pair">
      <span>
        <FormattedMessage
          id="orders.OrderPage.subtotal"
          description="Subtotal field in order page"
          defaultMessage="Total without tax"
        />
      </span>
      <span dangerouslySetInnerHTML={{ __html: props.subtotalHtml }} />
    </div>
    {props.taxes.map((tax) => (
      <div key={tax.name} className="panel-block is-value-pair">
        <span>
          <FormattedMessage
            id="orders.OrderPage.tax"
            description="Tax field in order page"
            defaultMessage="Tax ({name})"
            values={{ name: tax.name }}
          />
        </span>
        <span dangerouslySetInnerHTML={{ __html: tax.amountHtml }} />
      </div>
    ))}
    <div className="panel-block is-value-pair has-text-weight-bold">
      <span>
        <FormattedMessage
          id="orders.OrderPage.total"
          description="Total field in order page"
          defaultMessage="Total"
        />
      </span>
      <span dangerouslySetInnerHTML={{ __html: props.totalHtml }} />
    </div>
  </div>
)

export default OrderDetails
