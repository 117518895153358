import { defineMessages } from 'react-intl'
import Billing from './components/Billing'
import Consents from './components/Consents'
import Content from './components/Content'
import Deactivate from './components/Deactivate'
import Details from './components/Details'
import Newsletter from './components/Newsletter'
import Security from './components/Security'
import ShoppingHistory from './components/ShoppingHistory'
import { Section } from './types'

const messages = defineMessages({
  account: {
    id: 'account.section.settings',
    defaultMessage: 'Account & Settings',
    description: 'Account settings section title in user profile page',
  },
  profile: {
    id: 'account.section.settings.profile',
    defaultMessage: 'User Profile',
    description: 'User profile subsection title in user profile page',
  },
  security: {
    id: 'account.section.settings.security',
    defaultMessage: 'Account Security',
    description: 'Account security subsection title in user profile page',
  },
  consents: {
    id: 'account.section.settings.consents',
    defaultMessage: 'Agreed Consents',
    description: 'Agreed consents subsection title in user profile page',
  },
  deactivate: {
    id: 'account.section.settings.deactivate',
    defaultMessage: 'Deactivate Account',
    description: 'Deactivate account subsection title in user profile page',
  },
  content: {
    id: 'account.section.content',
    defaultMessage: 'Content',
    description: 'Content section title in user profile page',
  },
  categories: {
    id: 'account.section.content.categories',
    defaultMessage: 'Content Preferences',
    description: 'Content preferences subsection title in user profile page',
  },
  newsletter: {
    id: 'account.section.content.newsletter',
    defaultMessage: 'Newsletter Preferences',
    description: 'Newsletter preferences subsection title in user profile page',
  },
  billing: {
    id: 'account.section.billing',
    defaultMessage: 'Billing & Payments',
    description: 'Billing section title in user profile page',
  },
  plans: {
    id: 'account.section.billing.plans',
    defaultMessage: 'Billing & Plans',
    description: 'Billing and plans subsection title in user profile page',
  },
  history: {
    id: 'account.section.billing.history',
    defaultMessage: 'Shopping History',
    description: 'Shopping history subsection title in user profile page',
  },
})

export const sections: Section[] = [
  {
    title: messages.account,
    links: [
      {
        id: 'profile',
        url: '/account/profile',
        text: messages.profile,
        component: Details,
      },
      {
        id: 'security',
        url: '/account/security',
        text: messages.security,
        component: Security,
      },
      {
        id: 'consents',
        url: '/account/consents',
        text: messages.consents,
        component: Consents,
      },
      {
        id: 'deactivate',
        url: '/account/deactivate',
        text: messages.deactivate,
        component: Deactivate,
      },
    ],
  },
  {
    title: messages.content,
    links: [
      {
        id: 'content',
        url: '/account/content',
        text: messages.categories,
        component: Content,
      },
      {
        id: 'newsletter',
        url: '/account/newsletter',
        text: messages.newsletter,
        component: Newsletter,
      },
    ],
  },
  {
    title: messages.billing,
    links: [
      {
        id: 'billing',
        url: '/account/billing',
        text: messages.plans,
        component: Billing,
      },
      {
        id: 'shopping-history',
        url: '/account/shopping-history',
        text: messages.history,
        component: ShoppingHistory,
      },
    ],
  },
]
