import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingTopic: FC = () => (
  <section className="section has-padding-top-4 has-padding-top-5-tablet">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-7">
          <Skeleton width="50%" height={30} />
          <div className="has-margin-top-6">
            <Skeleton width="100%" />
            <Skeleton width="85%" />
            <Skeleton width="72%" />
            <Skeleton width="65%" />
            <Skeleton width="90%" />
            <Skeleton width="83%" />
            <Skeleton width="66%" />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default LoadingTopic
