import { useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { SearchResult, UseGetSearchResultsQueryParams } from './types'

export const useGetSearchResults = (
  params: UseGetSearchResultsQueryParams
): UseAxios<SearchResult[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<SearchResult[]>({ url: `/orreca/v1/search`, params }, [mParams])
}
