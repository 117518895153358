import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'

const LoginButton: FC = () => {
  const { open } = useModal()

  return (
    <span className="button is-white has-text-weight-bold" onClick={() => open(ModalType.Login)}>
      <FormattedMessage
        id="header.LoginButton.text"
        description="Link to Login in navbar"
        defaultMessage="Log in"
      />
    </span>
  )
}

export default LoginButton
