import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { LanguageContext } from './context'
import { Language } from './enums'
import { getLanguageFromCookie, getLanguageFromPathname } from './utils'

export const useCurrentLanguage = (): [Language, (Language: Language) => void] => {
  const { language, setLanguage } = useContext(LanguageContext)
  return [language, setLanguage]
}

export const useDefaultLanguage = (): Language => {
  const location = useLocation()

  return (
    getLanguageFromPathname(location.pathname) ||
    getLanguageFromCookie() ||
    (process.env.REACT_APP_DEFAULT_LANGUAGE as Language)
  )
}
