import { AxiosPromise } from 'axios'
import axios from '../api/axios'
import { CreateTopicFormValues, Topic } from './types'

export const createTopic = (values: CreateTopicFormValues): AxiosPromise<Topic> => {
  const formData = new FormData()
  formData.append('title', values.title)
  formData.append('content', values.content)
  formData.append('isPrivate', values.isPrivate ? '1' : '0')

  values.files.map((file, i) => formData.append(`file${i}`, file))
  values.categories.map((category) => formData.append('categories[]', String(category)))

  return axios.post('/orreca/v1/topics', formData)
}

export const followTopic = (id: number): AxiosPromise<void> =>
  axios.post(`/orreca/v1/topics/${id}/follow`)

export const unfollowTopic = (id: number): AxiosPromise<void> =>
  axios.post(`/orreca/v1/topics/${id}/unfollow`)
