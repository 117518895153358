import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import Categories from '../../posts/components/Categories'
import FeaturedMedia from '../../posts/components/FeaturedMedia'
import PostTags from '../../posts/components/PostTags'
import { Post as PostType } from '../../posts/types'
import { getAuthor, getPostUrl, isNewContent } from '../../posts/utils'
import Link from '../../router/components/Link'

const messages = defineMessages({
  published: {
    defaultMessage: '{published} by {author}',
    description: 'Published date with author name in front page news list',
    id: 'front-page.Post.published',
  },
})

type Props = PostType & {
  size: 'medium' | 'large'
}

const Post: FC<Props> = ({ size, ...post }) => {
  const { formatMessage, formatDate } = useIntl()
  const author = getAuthor(post)
  const postUrl = getPostUrl(post)
  const imgSize = size === 'large' ? 'orreca_front_large' : 'orreca_front_small'
  const published = formatDate(post.date, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <div className="news-item card is-shadowless is-borderless is-transparent has-margin-bottom-0 has-margin-bottom-4-tablet">
      <div className="card-image">
        <Link to={postUrl}>
          <FeaturedMedia
            post={post}
            size={imgSize}
            isHidden={size === 'large' ? undefined : 'mobile'}
          />
        </Link>
      </div>
      <div className="card-content has-padding-0 has-padding-bottom-2 has-padding-top-4 has-padding-top-4-tablet">
        <div className="content">
          <h3 className="title is-3 has-margin-bottom-2">
            <Link to={postUrl}>{post.title.rendered}</Link>
            <PostTags isNew={isNewContent(post)} isPremium={post.crb_premium} size={size} />
          </h3>
          {author && published && (
            <span className="meta has-margin-bottom-0">
              {formatMessage(messages.published, {
                published,
                author: author.name,
              })}
            </span>
          )}
          <div
            className="has-padding-bottom-0 has-margin-bottom-1"
            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
          />
          <Categories categories={post.categories} />
        </div>
      </div>
    </div>
  )
}

export default Post
