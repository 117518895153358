import React, { FC, Fragment } from 'react'
import useBoolean from 'react-hanger/array/useBoolean'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { Route } from 'react-router'
import NavLink from '../../router/components/NavLink'
import Redirect from '../../router/components/Redirect'
import { useCurrentUser } from '../../user/hooks'
import { useCurrentLink } from '../hooks'
import { sections } from '../sections'

const AccountPage: FC = () => {
  const { formatMessage } = useIntl()
  const [currentUser] = useCurrentUser()
  const [isMenuOpen, { toggle, setFalse }] = useBoolean(false)
  const currentLink = useCurrentLink()

  if (!currentUser) {
    return <Redirect to="/" />
  }

  return (
    <section className="section has-padding-top-0">
      <Helmet>
        <title>{currentLink && formatMessage(currentLink.text)}</title>
      </Helmet>
      <div className="container">
        <div className="columns is-multiline is-centered is-gapless">
          <div className="column is-narrow is-sidebar-menu is-hidden-touch has-padding-top-6">
            <aside className="menu">
              {sections.map((section, i) => (
                <Fragment key={i}>
                  <p className="menu-label">{formatMessage(section.title)}</p>
                  <ul className="menu-list">
                    {section.links.map(({ id, url, text }) => (
                      <li key={id}>
                        <NavLink to={url} exact activeClassName="is-active">
                          {formatMessage(text)}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </Fragment>
              ))}
            </aside>
          </div>
          <div className="column is-sidebar-content">
            <div className="columns is-centered has-padding-y-4 has-padding-y-6-tablet">
              <div className="column is-8">
                <div
                  className={`dropdown has-margin-bottom-4 is-hidden-tablet is-settings-nav is-fullwidth ${
                    isMenuOpen ? 'is-active' : ''
                  }`}
                >
                  <div className="dropdown-trigger">
                    <button
                      onClick={toggle}
                      className="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span>Settings menu</span>
                      <span className="ico ico-menu" />
                    </button>
                  </div>
                  <div className="dropdown-menu is-fullwidth" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                      {sections.map((section, i) => (
                        <Fragment key={i}>
                          <span className="dropdown-item divider-title has-text-weight-bold">
                            {formatMessage(section.title)}
                          </span>
                          {section.links.map(({ id, url, text }) => (
                            <NavLink
                              exact
                              key={id}
                              to={url}
                              onClick={setFalse}
                              className="dropdown-item"
                              activeClassName="is-active"
                            >
                              {formatMessage(text)}
                            </NavLink>
                          ))}
                          <hr className="dropdown-divider" />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
                {sections.map((section, i) => (
                  <Fragment key={i}>
                    {section.links.map(({ id, component }) => (
                      <Route exact key={id} path={`/:lang/account/${id}`} component={component} />
                    ))}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AccountPage
