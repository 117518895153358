import { first } from 'lodash-es'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useCart } from '../../cart/hooks'
import SignupBlock from '../../posts/components/SignupBlock'
import Redirect from '../../router/components/Redirect'
import { useCurrentUser } from '../../user/hooks'
import { useGetEvent } from '../hooks'
import { getMetaDescription } from '../utils'
import Attachments from './Attachments'
import Buttons from './Buttons'
import Capacity from './Capacity'
import Categories from './Categories'
import Duration from './Duration'
import Gallery from './Gallery'
import LoadingEvent from './LoadingEvent'
import Price from './Price'
import Venue from './Venue'
import Website from './Website'

const messages = defineMessages({
  signupBlockTitle: {
    id: 'events.EventPage.signupBlockTitle',
    description: 'Signup block title in event page',
    defaultMessage: 'Get your seat now!',
  },
  signupBlockDescription: {
    id: 'events.EventPage.signupBlockDescription',
    description: 'Signup block description in event page',
    defaultMessage: 'For registering use the green button and fill in the mandatory fields.',
  },
})

const EventPage: FC = () => {
  const { formatMessage } = useIntl()
  const params = useParams<{ id: string; slug: string }>()
  const [isRegistering, setIsRegistering] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [currentUser] = useCurrentUser()
  const [, { addItem }] = useCart()
  const [request] = useGetEvent(+params.id)
  const { data: event, isLoading } = request

  if (redirect) {
    return <Redirect push to="/shop/checkout" />
  }

  if (isLoading) {
    return <LoadingEvent />
  }

  if (!event) {
    return <Redirect to="/events" />
  }

  if (event.id === +params.id && params.slug !== event.slug) {
    return <Redirect to={`/events/${event.id}/${event.slug}`} />
  }

  const ticket = first(event.tickets)
  const canRegister = !!(currentUser || !ticket || !ticket.priceHtml)

  return (
    <section className="section has-margin-y-4 has-margin-y-5-tablet">
      <Helmet>
        <title>{event.title}</title>
        <meta name="description" content={getMetaDescription(event)} />
      </Helmet>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-7">
            <div className="panel is-event">
              <div className="panel-heading">
                <h1 className="is-title is-5 has-text-weight-bold">{event.title}</h1>
                <Duration from={event.startDate} to={event.endDate} />
              </div>
              <div className="panel-content">
                <div dangerouslySetInnerHTML={{ __html: event.contentHtml }} />
                {event.website && <Website url={event.website} />}
                <div className="columns is-multiline">
                  <Venue {...event.venue} />
                  {ticket && <Price price={ticket.priceHtml} />}
                  <Capacity {...event.capacity} />
                  <Categories categories={event.categories} />
                  <Attachments attachments={event.attachments} />
                  <Gallery images={event.gallery} />
                </div>
              </div>
              {canRegister && ticket && (
                <Buttons
                  isSubmitting={isRegistering}
                  onSubmit={async () => {
                    try {
                      setIsRegistering(true)
                      await addItem(event.tickets[0].id)
                      setIsRegistering(false)
                      setRedirect(true)
                    } catch (error) {
                      setIsRegistering(false)
                    }
                  }}
                />
              )}
            </div>
            {!canRegister && (
              <SignupBlock
                title={formatMessage(messages.signupBlockTitle)}
                description={formatMessage(messages.signupBlockDescription)}
                icon="couch"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default EventPage
