import { FieldProps } from 'formik'
import React, { FC } from 'react'
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements'
import { getStripeOptions } from '../utils'

type ElementType = 'cardCVC' | 'cardNumber' | 'cardExpiry'

interface Props extends FieldProps {
  label: string
  type: ElementType
  options: object
}

const elements = {
  cardCVC: CardCVCElement,
  cardNumber: CardNumberElement,
  cardExpiry: CardExpiryElement,
}

const StripeField: FC<Props> = ({ form, label, field, type, options }) => {
  const CardElement = elements[type]

  return (
    <div className="field">
      <label className="label is-medium">{label}</label>
      <div className="control">
        <CardElement
          {...getStripeOptions(options)}
          onReady={() => {
            form.setFieldValue(field.name, false)
          }}
          onChange={({ error, complete }) => form.setFieldValue(field.name, complete && !error)}
        />
      </div>
    </div>
  )
}

export default StripeField
