import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom'
import Comments from '../../comments/components/Comments'
import SocialTabs from '../../posts/components/SocialTabs'
import { getMetaDescription } from '../../posts/utils'
import Redirect from '../../router/components/Redirect'
import { useCurrentUser } from '../../user/hooks'
import { htmlToText } from '../../utils'
import { useScrollTo } from '../../utils/hooks'
import { useGetPodcast } from '../hooks'
import LoadingPodcast from './LoadingPodcast'
import PodcastAuthorBlock from './PodcastAuthorBlock'
import PreviousPodcastsList from './PreviousPodcastsList'
import RelatedPodcasts from './RelatedPodcasts'

const PodcastDetailsPage: FC = () => {
  const [currentUser] = useCurrentUser()
  const [commentsRef, scrollToComments] = useScrollTo<HTMLDivElement>()
  const params = useParams<{ id: string; slug: string }>()
  const [request] = useGetPodcast(+params.id)
  const { data: podcast, isInitial, isLoading } = request

  if (isInitial && isLoading) {
    return <LoadingPodcast />
  }

  if (!podcast) {
    return <Redirect to="/videos-and-podcasts/podcasts" />
  }

  const hasLiked = !!(currentUser && currentUser.likes.includes(podcast.id))

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{htmlToText(podcast.title.rendered)}</title>
        <meta name="description" content={getMetaDescription(podcast)} />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column is-8">
            <div className="card is-borderless is-shadowless is-transparent">
              <PodcastAuthorBlock {...podcast} />
              <ReactPlayer url={podcast.crb_podcast_source} width="auto" height="auto" controls />
              <SocialTabs
                postId={podcast.id}
                likes={podcast.likes}
                comments={podcast.comments}
                canLike={!!currentUser}
                hasLiked={hasLiked}
                onCommentsClick={scrollToComments}
              />
              <div
                className="article-content"
                dangerouslySetInnerHTML={{ __html: podcast.content.rendered }}
              />
              <Comments containerRef={commentsRef} canComment={!!currentUser} postId={podcast.id} />
              <PreviousPodcastsList authorId={podcast.author} exclude={[podcast.id]} />
            </div>
          </div>
          <RelatedPodcasts {...podcast} />
        </div>
      </div>
    </section>
  )
}

export default PodcastDetailsPage
