import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ProductsFilterQueryParams } from '../../products/types'
import { useQuery } from '../../router/hooks'
import { useDebounce } from '../../utils/hooks'

const messages = defineMessages({
  searchPlaceholder: {
    id: 'shop.NameFilter.searchPlaceholder',
    description: 'Placeholder text in product list search bar filter',
    defaultMessage: 'Search from shop',
  },
})

const NameFilter: FC = () => {
  const [query, setQuery] = useQuery<ProductsFilterQueryParams>()
  const [value, setValue] = useState(query.search || '')
  const { formatMessage } = useIntl()

  useDebounce(() => setQuery({ search: value || undefined }), 350, [value])

  return (
    <div className="field">
      <div className="control">
        <p className="control has-icons-right">
          <input
            type="text"
            value={value}
            className="input is-rounded is-medium"
            placeholder={formatMessage(messages.searchPlaceholder)}
            onChange={(event) => setValue(event.target.value)}
          />
          <span className="icon is-small is-right">
            <i className="i icon icon-search" />
          </span>
        </p>
      </div>
    </div>
  )
}

export default NameFilter
