import classNames from 'classnames'
import React, { FC } from 'react'
import { Hide } from '../types'
import { getHideModifiers } from '../utils'

type Props = Hide & {
  src: string
  alt?: string
  width?: number
  height?: number
  className?: string
  isRounded?: boolean
}

const Image: FC<Props> = (props) => (
  <figure className={`image ${classNames(getHideModifiers(props.isHidden), props.className)}`}>
    <img src={props.src} alt={props.alt} className={props.isRounded ? 'is-rounded' : undefined} />
  </figure>
)

export default Image
