import { AxiosPromise } from 'axios'
import { stringify } from 'query-string'
import axios from '../api/axios'
import { Language } from '../locale/enums'
import { Cart } from './types'

export const getCart = (language: Language): AxiosPromise<Cart> =>
  axios.get<Cart>(`/orreca/v1/cart?${stringify({ lang: language })}`)

export const addToCart = (productId: number, quantity: number): AxiosPromise<Cart> =>
  axios.post<Cart>('/orreca/v1/cart/add-product', {
    productId,
    quantity,
  })

export const removeFromCart = (cartItem: string): AxiosPromise<Cart> =>
  axios.post<Cart>('/orreca/v1/cart/remove-product', { cartItem })

export const emptyCart = (): AxiosPromise<Cart> => axios.post<Cart>('/orreca/v1/cart/empty')
