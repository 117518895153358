import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom'
import Comments from '../../comments/components/Comments'
import SocialTabs from '../../posts/components/SocialTabs'
import { getMetaDescription } from '../../posts/utils'
import Redirect from '../../router/components/Redirect'
import { useCurrentUser } from '../../user/hooks'
import { htmlToText } from '../../utils'
import { useScrollTo } from '../../utils/hooks'
import { useGetVideo } from '../hooks'
import LoadingVideo from './LoadingVideo'
import RelatedVideos from './RelatedVideos'
import VideoAuthorBlock from './VideoAuthorBlock'

const VideoDetailsPage: FC = () => {
  const [currentUser] = useCurrentUser()
  const [commentsRef, scrollToComments] = useScrollTo<HTMLDivElement>()
  const params = useParams<{ id: string; slug: string }>()
  const [request] = useGetVideo(+params.id)
  const { data: video, isInitial, isLoading } = request

  if (isInitial && isLoading) {
    return <LoadingVideo />
  }

  if (!video) {
    return <Redirect to="/videos-and-podcasts/videos" />
  }

  const hasLiked = !!(currentUser && currentUser.likes.includes(video.id))

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{htmlToText(video.title.rendered)}</title>
        <meta name="description" content={getMetaDescription(video)} />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column is-8">
            <div className="card is-borderless is-shadowless is-transparent">
              <ReactPlayer
                url={video.crb_video_source}
                config={{ vimeo: { playerOptions: { responsive: true } } }}
                width="auto"
                height="auto"
                controls
              />
              <VideoAuthorBlock {...video} />
              <SocialTabs
                postId={video.id}
                likes={video.likes}
                comments={video.comments}
                canLike={!!currentUser}
                hasLiked={hasLiked}
                onCommentsClick={scrollToComments}
              />
              <div
                className="article-content"
                dangerouslySetInnerHTML={{ __html: video.content.rendered }}
              />
              <Comments containerRef={commentsRef} canComment={!!currentUser} postId={video.id} />
            </div>
          </div>
          <RelatedVideos {...video} />
        </div>
      </div>
    </section>
  )
}

export default VideoDetailsPage
