import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import FormattedCategory from '../../categories/components/FormattedCategory'

interface Props {
  categories: number[]
}

const Categories: FC<Props> = ({ categories }) => (
  <div className="column is-12 is-field">
    <label>
      <FormattedMessage
        id="events.Categories.label"
        description="Topics label in event page"
        defaultMessage="Topics"
      />
    </label>
    <div className="tags has-padding-top-2">
      {categories.map((category) => (
        <FormattedCategory as="a" id={category} key={category} className="tag is-medium is-white" />
      ))}
    </div>
  </div>
)

export default Categories
