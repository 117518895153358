/* eslint-disable @typescript-eslint/camelcase */
import { stringify } from 'querystring'
import { FC, useEffect } from 'react'
import { useScript } from '../../utils/hooks'
import { gtag } from '../utils'

const Analytics: FC = () => {
  const params = {
    id: process.env.REACT_APP_GTM_ID,
    gtm_auth: process.env.REACT_APP_GTM_AUTH,
    gtm_preview: process.env.REACT_APP_GTM_PREVIEW,
    gtm_cookies_win: 'x',
  }

  useScript(`https://www.googletagmanager.com/gtm.js?${stringify(params)}`)

  useEffect(() => {
    gtag({ event: 'gtm.js', 'gtm.start': new Date().getTime() })
  }, [])

  return null
}

export default Analytics
