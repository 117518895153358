import React, { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCurrentLanguage } from '../../../locale/hooks'
import { languageToText } from '../../../locale/utils'
import { ModalType } from '../../../modal/enums'
import { useModal } from '../../../modal/hooks'
import { useCurrentUser } from '../../../user/hooks'

const Profile: FC = () => {
  const { open } = useModal()
  const { formatMessage } = useIntl()
  const [user] = useCurrentUser()
  const [language] = useCurrentLanguage()
  if (!user) {
    return null
  }

  return (
    <div className="panel-block is-column is-readonly-list has-padding-5">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => open(ModalType.UserDetails)} className="button is-medium is-outlined">
        <FormattedMessage
          id="account.Details.Profile.edit"
          description="Edit profile button in user profile"
          defaultMessage="Edit profile"
        />
      </a>
      <dl className="value-list">
        <dt>
          <FormattedMessage
            id="account.Details.Profile.email"
            description="Email label in user profile"
            defaultMessage="Email"
          />
        </dt>
        <dd>{user.email}</dd>
        <dt>
          <FormattedMessage
            id="account.Details.Profile.username"
            description="Username label in user profile"
            defaultMessage="Username"
          />
        </dt>
        <dd>{user.name}</dd>
        <dt>
          <FormattedMessage
            id="account.Details.Profile.password"
            description="Password label in user profile"
            defaultMessage="Password"
          />
        </dt>
        <dd>●●●●●●●●</dd>
        <dt>
          <FormattedMessage
            id="account.Details.Profile.language"
            description="Language label in user profile"
            defaultMessage="Language"
          />
        </dt>
        <dd>{formatMessage(languageToText(user.locale || language))}</dd>
      </dl>
    </div>
  )
}

export default Profile
