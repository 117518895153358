import classNames from 'classnames'
import React, { FC } from 'react'
import usePortal from 'react-useportal'
import { useKeypress, useWindowSize } from '../../utils/hooks'
import { QuickviewType } from '../enums'
import { useQuickview } from '../hooks'
import { getComponentForQuickview } from '../utils'

const QuickviewContainer: FC = () => {
  const { Portal } = usePortal()
  const { quickview, close } = useQuickview()
  const escapePressed = useKeypress('Escape')
  const windowSize = useWindowSize()

  const mobileOnly = [QuickviewType.Categories, QuickviewType.Menu, QuickviewType.ShopFilter]

  const isDesktop = windowSize.width >= 1024
  const isMobileOnly = quickview && mobileOnly.includes(quickview)
  if (escapePressed || (isMobileOnly && isDesktop)) {
    close()
  }

  const Component = getComponentForQuickview(quickview)

  const classMap = {
    [QuickviewType.Cart]: ['cat-filters-panel'],
    [QuickviewType.Categories]: ['cat-filters-panel'],
    [QuickviewType.Menu]: ['main-nav'],
    [QuickviewType.Search]: ['main-nav'],
    [QuickviewType.ShopFilter]: ['main-nav'],
  }

  const containerClasses = ['quickview', Component && 'is-active']
  const extraClasses = quickview ? classMap[quickview] : []

  return (
    <Portal>
      <div className={classNames(...containerClasses, ...extraClasses)}>
        {Component && <Component />}
      </div>
    </Portal>
  )
}

export default QuickviewContainer
