import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import Redirect from '../../router/components/Redirect'
import { useCurrentUser } from '../../user/hooks'
import { isSubscriber } from '../../user/utils'
import { useGetTopic } from '../hooks'
import { getTopicUrl } from '../utils'
import LoadingTopic from './LoadingTopic'
import Topic from './Topic'

const TopicPage: FC = () => {
  const params = useParams<{ id: string; slug: string }>()
  const [request] = useGetTopic(+params.id, { _embed: true })
  const [currentUser] = useCurrentUser()

  if (!isSubscriber(currentUser)) {
    return <Redirect to="/topics" />
  }

  const { data: topic, isLoading } = request

  if (isLoading) {
    return <LoadingTopic />
  }

  if (!topic) {
    return <Redirect to="/topics" />
  }

  if (topic.id === +params.id && params.slug !== topic.slug) {
    return <Redirect to={getTopicUrl(topic)} />
  }

  return <Topic {...topic} />
}

export default TopicPage
