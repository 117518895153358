import React, { FC } from 'react'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'

interface Props {
  title: string
  subtitle?: string
  description?: string
  buttonText: string
  icon: string
}

const PremiumBlock: FC<Props> = (props) => {
  const { open } = useModal()

  return (
    <div className="box box-premium-info has-margin-top-4 has-padding-bottom-6 has-padding-x-6-desktop is-radiusless has-text-centered">
      <div className={`picto picto-${props.icon} has-margin-top-5`} />
      <h2 className="title is-4">{props.title}</h2>
      {props.subtitle && <h3 className="subtitle is-5">{props.subtitle}</h3>}
      {props.description && <p>{props.description}</p>}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => open(ModalType.Subscribe)} className="button is-link has-text-weight-bold">
        {props.buttonText}
      </a>
    </div>
  )
}

export default PremiumBlock
