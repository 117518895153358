import { Field } from 'formik'
import React, { FC, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import SelectField from '../../formik/components/SelectField'
import TextField from '../../formik/components/TextField'
import { useCountries } from '../../utils/hooks'

const messages = defineMessages({
  address: {
    id: 'user.ShippingAddressForm.address',
    description: 'Address field in user shipping address form',
    defaultMessage: 'Address',
  },
  country: {
    id: 'user.ShippingAddressForm.country',
    description: 'Country field in user shipping address form',
    defaultMessage: 'Country',
  },
  state: {
    id: 'user.ShippingAddressForm.state',
    description: 'State/Province field in user shipping address form',
    defaultMessage: 'State/Province',
  },
  postcode: {
    id: 'user.ShippingAddressForm.postcode',
    description: 'ZIP/Postal code field in user shipping address form',
    defaultMessage: 'ZIP/Postal code',
  },
  city: {
    id: 'user.ShippingAddressForm.city',
    description: 'City field in user shipping address form',
    defaultMessage: 'City',
  },
  company: {
    id: 'user.ShippingAddressForm.company',
    description: 'Company field in user shipping address form',
    defaultMessage: 'Company <o>(optional)</o>',
  },
  firstName: {
    id: 'user.ShippingAddressForm.firstName',
    description: 'First name field in user shipping address form',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'user.ShippingAddressForm.lastName',
    description: 'Last name field in user shipping address form',
    defaultMessage: 'Last name',
  },
})

const ShippingAddressForm: FC = () => {
  const { formatMessage } = useIntl()
  const countries = useCountries()
  const countryOptions = useMemo(
    () => countries.map(({ code, name }) => ({ value: code, label: name })),
    [countries]
  )

  return (
    <div className="columns is-multiline has-padding-top-2 has-padding-bottom-4 has-padding-x-3">
      <div className="column is-12 has-padding-bottom-1">
        <Field
          type="text"
          name="shippingAddress.address1"
          autocomplete="shipping street-address"
          label={formatMessage(messages.address)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-4 has-padding-bottom-1">
        <Field
          name="shippingAddress.country"
          autocomplete="shipping country"
          label={formatMessage(messages.country)}
          component={SelectField}
          options={countryOptions}
          required
        />
      </div>
      <div className="column is-4 has-padding-bottom-1">
        <Field
          type="text"
          name="shippingAddress.state"
          autocomplete="shipping region"
          label={formatMessage(messages.state)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-4 has-padding-bottom-1">
        <Field
          type="text"
          name="shippingAddress.postcode"
          autocomplete="shipping postal-code"
          label={formatMessage(messages.postcode)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="shippingAddress.city"
          autocomplete="shipping locality"
          label={formatMessage(messages.city)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="shippingAddress.company"
          component={TextField}
          label={formatMessage(messages.company, {
            o: (msg: string) => <span className="is-opt">{msg}</span>,
          })}
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="shippingAddress.firstName"
          autocomplete="shipping fname"
          label={formatMessage(messages.firstName)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="shippingAddress.lastName"
          autocomplete="shipping lname"
          label={formatMessage(messages.lastName)}
          component={TextField}
          required
        />
      </div>
    </div>
  )
}

export default ShippingAddressForm
