import React, { FC } from 'react'
import FormattedCategory from './FormattedCategory'

interface Props {
  id: number
  isSelected: boolean
  onClick: (id: number) => void
}

const CategoryTag: FC<Props> = ({ id, isSelected, onClick }) => (
  <span
    className={`button is-rounded is-outlined ${isSelected ? 'is-active' : ''}`}
    onClick={() => onClick(id)}
  >
    <FormattedCategory id={id} />
    <span className="icon is-small">
      <i className="icon-add" />
    </span>
  </span>
)

export default CategoryTag
