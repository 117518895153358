import classNames from 'classnames'
import { FieldProps } from 'formik'
import { get } from 'lodash-es'
import React, { FC } from 'react'
import ErrorMessage from './ErrorMessage'

interface Props extends FieldProps {
  label?: string
}

const TextField: FC<Props> = ({ field, form, label, ...props }) => {
  const isTouched = get(form.touched, field.name)
  const hasError = isTouched && get(form.errors, field.name)
  const inputClasses = ['input', 'is-medium', hasError && 'is-danger']

  return (
    <div className="field">
      {label && (
        <label htmlFor={field.name} className="label is-medium">
          {label}
        </label>
      )}
      <div className="control">
        <input className={classNames(inputClasses)} {...field} {...props} />
      </div>
      <ErrorMessage name={field.name} />
    </div>
  )
}

export default TextField
