import React, { FC } from 'react'
import { Redirect as BaseRedirect, RedirectProps, useLocation } from 'react-router-dom'
import { useCurrentLanguage } from '../../locale/hooks'

const Redirect: FC<RedirectProps> = ({ to, ...props }) => {
  const [currentLanguage] = useCurrentLanguage()
  const { pathname } = useLocation()

  if (typeof to === 'object') {
    const path = to.pathname ? to.pathname : pathname
    const prefixedPath = `/${currentLanguage}${path}`
    return <BaseRedirect to={{ ...to, pathname: prefixedPath }} {...props} />
  }

  const prefixedPath = `/${currentLanguage}${to ? to : pathname}`

  return <BaseRedirect to={prefixedPath} {...props} />
}

export default Redirect
