/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, Fragment, useRef } from 'react'
import { useBoolean } from 'react-hanger/array/useBoolean'
import { defineMessages, useIntl } from 'react-intl'
import * as t from '../../products/types'
import { useQuery } from '../../router/hooks'
import { useClickOutside } from '../../utils/hooks'

const messages = defineMessages({
  sort: {
    id: 'shop.OrderFilter.sort',
    description: 'Sort word before field name in product list',
    defaultMessage: 'Sort',
  },
  sortByPrice: {
    id: 'shop.OrderFilter.sortByPrice',
    description: 'Sort by price option in product list',
    defaultMessage: 'By price',
  },
  sortByPriceAscending: {
    id: 'shop.OrderFilter.sortByPriceAscending',
    description: 'Sort by price ascending option in product list',
    defaultMessage: 'Lowest first',
  },
  sortByPriceDescending: {
    id: 'shop.OrderFilter.sortByPriceDescending',
    description: 'Sort by price descending option in product list',
    defaultMessage: 'Highest first',
  },
  sortByTitle: {
    id: 'shop.OrderFilter.sortByTitle',
    description: 'Sort by name option in product list',
    defaultMessage: 'By name',
  },
  sortByTitleAscending: {
    id: 'shop.OrderFilter.sortByTitleAscending',
    description: 'Sort by name ascending option in product list',
    defaultMessage: 'From A to Z',
  },
  sortByTitleDescending: {
    id: 'shop.OrderFilter.sortByTitleDescending',
    description: 'Sort by name descending option in product list',
    defaultMessage: 'From Z to A',
  },
})

const options: { by: t.SelectableOrderBy; directions: t.ProductOrder[] }[] = [
  { by: 'price', directions: ['asc', 'desc'] },
  { by: 'title', directions: ['asc', 'desc'] },
]

const orderByMessages = {
  price: messages.sortByPrice,
  title: messages.sortByTitle,
}

const directionMessages = {
  price: {
    asc: messages.sortByPriceAscending,
    desc: messages.sortByPriceDescending,
  },
  title: {
    asc: messages.sortByTitleAscending,
    desc: messages.sortByTitleDescending,
  },
}

const OrderFilter: FC = () => {
  const { formatMessage } = useIntl()
  const [query, setQuery] = useQuery<t.ProductsFilterQueryParams>()
  const [isOpen, actions] = useBoolean(false)

  const ref = useRef(null)
  useClickOutside(ref, actions.setFalse)

  const { order = 'asc', orderby = 'title' } = query

  const onSelect = (orderby: t.SelectableOrderBy, order: t.ProductOrder): void => {
    setQuery({ order, orderby })
    actions.toggle()
  }

  return (
    <div ref={ref} className={`dropdown is-fullwidth shop-filter ${isOpen ? 'is-active' : ''}`}>
      <div className="dropdown-trigger">
        <button
          onClick={actions.toggle}
          className="button is-rounded is-medium"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span>
            <span className="has-margin-right-2">{formatMessage(messages.sort)}</span>
            <span className="has-text-weight-bold has-margin-right-2">
              {formatMessage(orderByMessages[orderby]).toLowerCase()}
            </span>
            ({formatMessage(directionMessages[orderby][order])})
          </span>
          <span className="icon is-small has-margin-left-0">
            <i className="icon icon-carret" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content is-transparent is-shadowless is-borderless">
          {options.map((option, i) => (
            <Fragment key={option.by}>
              <span className="dropdown-item divider-title">
                {formatMessage(orderByMessages[option.by])}
              </span>
              {option.directions.map((direction) => {
                const isSelected = option.by === orderby && direction === order

                return (
                  <a
                    key={option.by + direction}
                    onClick={() => onSelect(option.by, direction)}
                    className={`dropdown-item ${isSelected ? 'has-text-weight-bold' : ''}`}
                  >
                    {formatMessage(directionMessages[option.by][direction])}
                  </a>
                )
              })}
              {options.length !== i + 1 && <hr className="dropdown-divider" />}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OrderFilter
