import { Form, Formik } from 'formik'
import React, { FC, Fragment } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Status from '../../../formik/components/Status'
import SubmitButton from '../../../formik/components/SubmitButton'
import Modal from '../../../modal/components/Modal'
import { useModal } from '../../../modal/hooks'
import { updateBillingAddress } from '../../../user/api'
import BillingAddressFields from '../../../user/components/BillingAddressFields'
import { useCurrentUser } from '../../../user/hooks'
import { useAddressSchema } from '../../schema'

const messages = defineMessages({
  modalTitle: {
    id: 'account.Billing.AddressForm.modalTitle',
    description: 'Billing address modal title',
    defaultMessage: 'Billing address',
  },
  saveFailed: {
    id: 'account.Billing.AddressForm.saveFailed',
    description: 'General error message for billing address update failure',
    defaultMessage: "Sorry! We couldn't save your billing address. Please try again later.",
  },
})

const AddressForm: FC = () => {
  const { close } = useModal()
  const { formatMessage } = useIntl()
  const [currentUser, setCurrentUser] = useCurrentUser()
  const validationSchema = useAddressSchema()

  if (!currentUser) {
    return null
  }

  const { id, billingAddress } = currentUser

  return (
    <Formik
      initialValues={{
        billingAddress: {
          firstName: (billingAddress && billingAddress.firstName) || '',
          lastName: (billingAddress && billingAddress.lastName) || '',
          company: (billingAddress && billingAddress.company) || '',
          vatNo: (billingAddress && billingAddress.vatNo) || '',
          address1: (billingAddress && billingAddress.address1) || '',
          city: (billingAddress && billingAddress.city) || '',
          state: (billingAddress && billingAddress.state) || '',
          postcode: (billingAddress && billingAddress.postcode) || '',
          country: (billingAddress && billingAddress.country) || '',
          email: (billingAddress && billingAddress.email) || '',
          phone: (billingAddress && billingAddress.phone) || '',
        },
      }}
      validationSchema={validationSchema}
      isInitialValid={!!billingAddress}
      onSubmit={async (values, actions) => {
        try {
          actions.setStatus(undefined)
          actions.setSubmitting(true)

          const newAddress = values.billingAddress
          const { data: user } = await updateBillingAddress(id, newAddress)

          actions.setSubmitting(false)

          setCurrentUser(user)
          close()
        } catch (error) {
          actions.setStatus(formatMessage(messages.saveFailed))
          actions.setSubmitting(false)
        }
      }}
    >
      {() => (
        <Form noValidate>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <Status />
            <BillingAddressFields />
          </Modal.Body>
          <Modal.Footer className="is-aligned-right">
            <Fragment>
              <button
                type="button"
                className="button is-text is-medium has-text-weight-bold is-pulled-right"
                onClick={() => close()}
              >
                <FormattedMessage
                  id="account.Billing.AddressForm.cancel"
                  description="Cancel button in billing address modal"
                  defaultMessage="Cancel"
                />
              </button>
              <SubmitButton className="is-pulled-right">
                <FormattedMessage
                  id="account.Billing.AddressForm.save"
                  description="Save button in billing address modal"
                  defaultMessage="Save"
                />
              </SubmitButton>
            </Fragment>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default AddressForm
