import { Field, Form, Formik } from 'formik'
import React, { FC, Fragment } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import HorizontalField from '../../../formik/components/HorizontalField'
// import HorizontalSelect from '../../../formik/components/HorizontalSelect'
import SubmitButton from '../../../formik/components/SubmitButton'
// import { Language } from '../../../locale/enums'
import { useCurrentLanguage } from '../../../locale/hooks'
// import { languageToText } from '../../../locale/utils'
import Modal from '../../../modal/components/Modal'
import { useModal } from '../../../modal/hooks'
import { updateUser } from '../../../user/api'
import { useCurrentUser } from '../../../user/hooks'
import { useDetailsSchema } from '../../schema'
import { DetailsFormValues } from '../../types'

const messages = defineMessages({
  modalTitle: {
    id: 'account.Details.DetailsForm.modalTitle',
    description: 'User details form modal title',
    defaultMessage: 'Edit profile',
  },
  email: {
    id: 'account.Details.DetailsForm.email',
    description: 'Email field label in user details form',
    defaultMessage: 'Email',
  },
  emailHelpText: {
    id: 'account.Details.DetailsForm.emailHelpText',
    description: 'Help text under email field in user details form',
    defaultMessage:
      'If you would like to change your e-mail, please turn to <a>contact@orreca.com</a> and our administrators will help you to do this.',
  },
  password: {
    id: 'account.Details.DetailsForm.password',
    description: 'Password field label in user details form',
    defaultMessage: 'Password',
  },
  changePassword: {
    id: 'account.Details.DetailsForm.changePassword',
    description: 'Change password button in user details form',
    defaultMessage: 'Change password',
  },
  newPassword: {
    id: 'account.Details.DetailsForm.newPassword',
    description: 'New password field label in user details form',
    defaultMessage: 'New password',
  },
  confirmNewPassword: {
    id: 'account.Details.DetailsForm.confirmNewPassword',
    description: 'Confirm new password field label in user details form',
    defaultMessage: 'Confirm password',
  },
  username: {
    id: 'account.Details.DetailsForm.username',
    description: 'Username field label in user details form',
    defaultMessage: 'Username',
  },
  usernameHelpText: {
    id: 'account.Details.DetailsForm.usernameHelpText',
    description: 'Username field help text in user details form',
    defaultMessage:
      'You need your username to log in and it will be displayed in the Q&A area and in the discussion forums.',
  },
  language: {
    id: 'account.Details.DetailsForm.language',
    description: 'Language field label in user details form',
    defaultMessage: 'Language',
  },
  cancel: {
    id: 'account.Details.DetailsForm.cancel',
    description: 'Cancel button text in user details form',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'account.Details.DetailsForm.save',
    description: 'Save button text in user details form',
    defaultMessage: 'Save',
  },
})

const DetailsForm: FC = () => {
  const { close } = useModal()
  const { formatMessage } = useIntl()
  const validationSchema = useDetailsSchema()
  const [, setCurrentLanguage] = useCurrentLanguage()
  const [currentUser, setCurrentUser] = useCurrentUser()
  if (!currentUser) {
    return null
  }

  const initialValues: DetailsFormValues = {
    email: currentUser.email,
    username: currentUser.name,
    locale: currentUser.locale,
    changePassword: false,
    password: {
      current: '',
      new: '',
      confirm: '',
    },
  }

  return (
    <Formik
      isInitialValid
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, form) => {
        try {
          const { password, changePassword } = values
          const { data: updatedUser } = await updateUser(currentUser.id, {
            nickname: values.username,
            currentPassword: changePassword ? password.current : undefined,
            password: changePassword ? password.new : undefined,
            locale: values.locale,
          })

          setCurrentUser(updatedUser)
          setCurrentLanguage(updatedUser.locale)
          form.setSubmitting(false)
          close()
        } catch (error) {
          form.setSubmitting(false)
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form noValidate>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <div className="columns">
              <div className="column has-margin-top-1">
                <Field
                  name="email"
                  type="email"
                  disabled
                  label={formatMessage(messages.email)}
                  component={HorizontalField}
                  helpText={formatMessage(messages.emailHelpText, {
                    a: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
                  })}
                />
                <Field
                  hasLink
                  isNarrow
                  name="password.current"
                  type="password"
                  autoComplete="current-password"
                  placeholder="*******"
                  disabled={!values.changePassword}
                  label={formatMessage(messages.password)}
                  component={HorizontalField}
                >
                  {!values.changePassword && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      className="is-medium has-margin-x-4"
                      onClick={() => setFieldValue('changePassword', true)}
                    >
                      {formatMessage(messages.changePassword)}
                    </a>
                  )}
                </Field>
                {values.changePassword && (
                  <Fragment>
                    <Field
                      name="password.new"
                      type="password"
                      isNarrow
                      autoComplete="new-password"
                      label={formatMessage(messages.newPassword)}
                      component={HorizontalField}
                    />
                    <Field
                      name="password.confirm"
                      type="password"
                      isNarrow
                      autoComplete="new-password"
                      label={formatMessage(messages.confirmNewPassword)}
                      component={HorizontalField}
                    />
                  </Fragment>
                )}
                <Field
                  name="username"
                  type="text"
                  isNarrow
                  label={formatMessage(messages.username)}
                  component={HorizontalField}
                  helpText={formatMessage(messages.usernameHelpText)}
                />
                {/* <Field
                  name="locale"
                  label={formatMessage(messages.language)}
                  component={HorizontalSelect}
                  options={Object.values(Language).map((language) => ({
                    value: language,
                    text: formatMessage(languageToText(language)),
                  }))}
                /> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="is-aligned-right">
            <button
              type="button"
              className="button is-text is-medium has-text-weight-bold is-pulled-right"
              onClick={() => close()}
            >
              {formatMessage(messages.cancel)}
            </button>
            <SubmitButton className="is-pulled-right">{formatMessage(messages.save)}</SubmitButton>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default DetailsForm
