import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentLanguage } from '../../locale/hooks'
import Link from '../../router/components/Link'
import { useGetPodcasts } from '../hooks'
import { Podcast } from '../types'

const RelatedPodcasts: FC<Podcast> = (props) => {
  const [language] = useCurrentLanguage()
  const [request] = useGetPodcasts({
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: 3,
    lang: language,
    exclude: [props.id],
    categories: props.categories,
  })

  const { data: podcasts = [] } = request

  return (
    <div className="column is-4 sidebar has-margin-bottom-6">
      <div className="card content-block">
        <header className="card-header is-shadowless is-borderless has-padding-4 has-padding-bottom-3">
          <h2 className="title is-4">
            <FormattedMessage
              id="podcasts.RelatedPodcasts.title"
              description="Title of the related podcasts block on the podcast details page."
              defaultMessage="Related <span>Podcasts</span>"
              values={{
                span: (msg: string) => <span className="has-text-grey-light">{msg}</span>,
              }}
            />
          </h2>
        </header>

        <div className="card-content has-padding-0">
          <ul className="qa-followed-list has-padding-4 has-padding-bottom-3">
            {podcasts.map((currentPodcast) => (
              <li key={currentPodcast.id} className="has-margin-bottom-4">
                <Link
                  to={`/videos-and-podcasts/podcasts/${currentPodcast.id}/${currentPodcast.slug}`}
                  className="is-title"
                >
                  <span dangerouslySetInnerHTML={{ __html: currentPodcast.title.rendered }} />
                </Link>
                <div className="date">{currentPodcast.crb_podcast_duration}</div>
              </li>
            ))}
          </ul>
        </div>

        <footer className="card-footer">
          <Link
            to="/videos-and-podcasts/podcasts"
            search={{ categories: props.categories }}
            className="card-footer-item has-text-weight-bold"
          >
            <FormattedMessage
              id="podcasts.RelatedPodcasts.morePodcasts"
              description="The 'More Podcasts' button of the related podcasts block on the podcast details page."
              defaultMessage="More Podcasts"
            />
          </Link>
        </footer>
      </div>
    </div>
  )
}

export default RelatedPodcasts
