import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { parsePaginationHeaders } from '../../api/utils'
import Pagination from '../../bulma/components/Pagination'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetSearchResults } from '../hooks'
import { SearchQueryParams } from '../types'
import LoadingSearchPage from './LoadingSearchPage'
import SearchResultRow from './SearchResultRow'

const messages = defineMessages({
  title: {
    id: 'search.SearchResults.title',
    description: 'Page title of the search results page.',
    defaultMessage: 'Search results',
  },
})

const SearchResults: FC = () => {
  const { formatMessage } = useIntl()
  const [language] = useCurrentLanguage()
  const [query] = useQuery<SearchQueryParams>()
  const [request] = useGetSearchResults({
    search: query.search,
    page: Number(query.page) || 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: 12,
    lang: language,
  })
  const { data: searchResults = [], isLoading, isInitial } = request

  if (isInitial && isLoading) {
    return <LoadingSearchPage />
  }

  const { totalPages, total } = parsePaginationHeaders(request.headers || {})
  const paginationVisible = searchResults.length > 0 && totalPages > 1

  return (
    <section className="section has-padding-top-4 has-padding-top-5-tablet">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8-desktop">
            <h1 className="title is-3">{formatMessage(messages.title)}</h1>
            <p className="subtitle has-text-grey-light">
              <FormattedMessage
                id="search.SearchResults.resultsInfo"
                description="Information about the current search on the search page."
                defaultMessage="Found {total} results for phrase {search}"
                values={{
                  total,
                  search: <strong className="has-text-grey-light">"{query.search}"</strong>,
                }}
              />
            </p>
            {searchResults.map((searchResult) => (
              <SearchResultRow key={searchResult.id} {...searchResult} />
            ))}
          </div>
        </div>

        {paginationVisible && <Pagination pages={totalPages} />}
      </div>
    </section>
  )
}

export default SearchResults
