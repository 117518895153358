import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { cancelSubscription } from '../../../user/api'
import { useCurrentUser } from '../../../user/hooks'

const CancelPlanButton: FC = () => {
  const [isCancelling, setIsCancelling] = useState(false)
  const [currentUser, setCurrentUser] = useCurrentUser()
  if (!currentUser) {
    return null
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={async () => {
        try {
          if (!isCancelling) {
            setIsCancelling(true)
            const response = await cancelSubscription(currentUser.id)
            setIsCancelling(false)
            setCurrentUser(response.data)
          }
        } catch (error) {
          setIsCancelling(false)
        }
      }}
      className={`button is-outlined is-medium ${isCancelling ? 'is-loading' : ''}`}
    >
      <FormattedMessage
        id="account.Billing.Plan.CancelPlanButton.text"
        description="Cancel plan button in billing account section"
        defaultMessage="Cancel plan"
      />
    </a>
  )
}

export default CancelPlanButton
