import { castArray } from 'lodash-es'
import React, { FC, Fragment } from 'react'
import { parsePaginationHeaders } from '../../api/utils'
import Pagination from '../../bulma/components/Pagination/Pagination'
import { useSelectedCategories } from '../../categories/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetProducts } from '../hooks'
import { ProductsFilterQueryParams } from '../types'
import LoadingProductList from './LoadingProductList'
import ProductListItem from './ProductListItem'

const ProductList: FC = () => {
  const [language] = useCurrentLanguage()
  const [categories] = useSelectedCategories()
  const [{ page, formats, ...query }] = useQuery<ProductsFilterQueryParams>()
  const [request] = useGetProducts({
    ...query,
    page: Number(page) || 1,
    lang: language,
    formats: castArray(formats || []).map(Number),
    categories,
  })

  const { data: products = [], isLoading, isInitial } = request

  if (isInitial && isLoading) {
    return <LoadingProductList />
  }

  const { totalPages } = parsePaginationHeaders(request.headers || {})
  const showPagination = products.length > 0 && totalPages > 1

  return (
    <Fragment>
      <div className="columns is-multiline has-margin-y-3">
        {products.map((product) => (
          <div className="column is-4" key={product.id}>
            <ProductListItem {...product} />
          </div>
        ))}
      </div>
      {showPagination && <Pagination pages={totalPages} />}
    </Fragment>
  )
}

export default ProductList
