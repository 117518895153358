import { FormikContextType, connect } from 'formik'
import React, { FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import SubmitButton from '../../../formik/components/SubmitButton'
import { useModal } from '../../../modal/hooks'
import { SubscriptionFormValues } from '../../types'

interface Props {
  formik: FormikContextType<SubscriptionFormValues>
}

const Buttons: FC<Props> = ({ formik }) => {
  const { close } = useModal()
  const { step } = formik.values
  const isPaymentStep = step === 3

  return (
    <Fragment>
      <button
        type="button"
        className="button is-text is-medium has-text-weight-bold is-pulled-right"
        onClick={() => close()}
      >
        <FormattedMessage
          id="subscriptions.SubscriptionForm.Buttons.cancel"
          description="Cancel button in subscription modal"
          defaultMessage="Cancel"
        />
      </button>
      <SubmitButton
        type={isPaymentStep ? 'submit' : 'button'}
        onClick={() => !isPaymentStep && formik.setFieldValue('step', step + 1)}
        className="is-pulled-right"
      >
        {isPaymentStep ? (
          <FormattedMessage
            id="subscriptions.SubscriptionForm.Buttons.submit"
            description="Submit button in subscription modal"
            defaultMessage="Submit"
          />
        ) : (
          <FormattedMessage
            id="subscriptions.SubscriptionForm.Buttons.next"
            description="Next button in subscription modal"
            defaultMessage="Next"
          />
        )}
      </SubmitButton>
    </Fragment>
  )
}

export default connect<{}, SubscriptionFormValues>(Buttons)
