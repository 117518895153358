import React, { FC, Fragment, useState } from 'react'
import { FormattedDate, FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { parsePaginationHeaders } from '../../api/utils'
import { useCurrentLanguage } from '../../locale/hooks'
import Quickview from '../../quickview/components/Quickview'
import { useQuickview } from '../../quickview/hooks'
import Link from '../../router/components/Link'
import { useQuery } from '../../router/hooks'
import { useDebounce } from '../../utils/hooks'
import { useGetSearchResults } from '../hooks'
import { SearchQueryParams } from '../types'

const messages = defineMessages({
  title: {
    id: 'search.SearchPanel.title',
    description: 'Title of the search panel of the mobile search panel.',
    defaultMessage: 'Search results',
  },
  inputPlaceholder: {
    id: 'search.SearchPanel.inputPlaceholder',
    description: "Search input's placeholder text of the search panel of the mobile view.",
    defaultMessage: 'Search from Site',
  },
})

const SearchPanel: FC = () => {
  const { formatMessage } = useIntl()
  const [language] = useCurrentLanguage()
  const { close } = useQuickview()
  const [query, setQuery] = useQuery<SearchQueryParams>()
  const [request] = useGetSearchResults({
    search: query.search,
    page: 1,
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: 4,
    lang: language,
  })
  const [search, setSearch] = useState(query.search || '')

  useDebounce(
    () => {
      if (search.length > 2) {
        setQuery({
          search: search || undefined,
        })
      }
    },
    800,
    [search]
  )

  const { data: searchResults = [] } = request
  const { total } = parsePaginationHeaders(request.headers || {})

  return (
    <Fragment>
      <Quickview.Header title={formatMessage(messages.title)} />
      <Quickview.Body className="has-margin-4 is-top">
        <div className="field is-search has-margin-bottom-5">
          <div className="control has-icons-right">
            <input
              className="input is-rounded is-medium"
              type="text"
              placeholder={formatMessage(messages.inputPlaceholder)}
              onChange={(event) => setSearch(event.target.value)}
              value={search}
            />
            <span className="icon is-small is-right">
              <i className="icon icon-dark-search" />
            </span>
          </div>
        </div>
        <div className="quickview-block">
          <ul className="search-results">
            {searchResults.map((searchResult) => (
              <li key={searchResult.id}>
                <Link to={searchResult.url}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: searchResult.title,
                    }}
                  />
                  <span className="meta">
                    <FormattedMessage
                      id="search.SearchPanel.authorInfo"
                      description="Author information of a search result on the search panel of the mobile view."
                      defaultMessage="{date} by {author}"
                      values={{
                        date: <FormattedDate value={searchResult.date} />,
                        author: searchResult.author,
                      }}
                    />
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Quickview.Body>
      <Quickview.Footer>
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12 has-margin-top-4 has-padding-bottom-5">
              <Link
                className="button is-medium is-primary is-outlined is-fullwidth has-padding-y-4"
                to="/search"
                search={{ search: search }}
                onClick={close}
              >
                <FormattedMessage
                  id="search.SearchPanel.resultsInfo"
                  description="Information about the current search on the search panel of the mobile view."
                  defaultMessage="See all results {total}"
                  values={{
                    total: (
                      <span className="tag is-light is-medium has-margin-left-3 has-padding-1 has-padding-y-0">
                        {total}
                      </span>
                    ),
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
      </Quickview.Footer>
    </Fragment>
  )
}

export default SearchPanel
