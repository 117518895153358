import React, { FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import Quickview from '../../quickview/components/Quickview'
import { useQuickview } from '../../quickview/hooks'
import Link from '../../router/components/Link'
import { useCart } from '../hooks'
import CartItem from './CartItem'

const CartPanel: FC = () => {
  const { close } = useQuickview()
  const [cart] = useCart()

  return (
    <Fragment>
      <Quickview.Header title="Shopping Cart" />
      <Quickview.Body>
        <div className="cart-rows">
          {cart && cart.items.map((item) => <CartItem key={item.id} {...item} />)}
        </div>
        <div className="cart-totals">
          <div className="totals-row">
            <FormattedMessage
              id="cart.CartPanel.totalWithoutTax"
              description="Total without tax row label in cart panel"
              defaultMessage="Total without tax"
            />
            {cart && <span dangerouslySetInnerHTML={{ __html: cart.subtotalHtml }} />}
          </div>
          {cart && cart.needsShipping && (
            <div className="totals-row">
              <span>
                <FormattedMessage
                  id="cart.CartPanel.shipping"
                  description="Shipping label in cart panel"
                  defaultMessage="Shipping"
                />
              </span>
              <span dangerouslySetInnerHTML={{ __html: cart.shippingTotalHtml }} />
            </div>
          )}
          {cart &&
            cart.taxes.map((tax) => (
              <div key={tax.name} className="totals-row">
                <span>
                  <FormattedMessage
                    id="cart.CartPanel.tax"
                    description="Tax row label in cart panel"
                    defaultMessage="Tax ({name})"
                    values={{ name: tax.name }}
                  />
                </span>
                <span dangerouslySetInnerHTML={{ __html: tax.amountHtml }} />
              </div>
            ))}
          <div className="totals-row has-text-weight-bold">
            <FormattedMessage
              id="cart.CartPanel.total"
              description="Total row label in cart panel"
              defaultMessage="Total"
            />
            {cart && <span dangerouslySetInnerHTML={{ __html: cart.totalHtml }} />}
          </div>
        </div>
      </Quickview.Body>
      <Quickview.Footer>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a
          className="button is-outlined has-text-weight-bold is-pulled-left is-medium"
          onClick={close}
        >
          <FormattedMessage
            id="cart.CartPanel.continueShopping"
            description="Continue shopping button in cart panel"
            defaultMessage="Continue"
          />
        </a>
        {cart && cart.items.length > 0 && (
          <Link
            to="/shop/checkout"
            onClick={close}
            className="button is-link has-text-weight-bold is-pulled-right is-medium"
          >
            <FormattedMessage
              id="cart.CartPanel.checkout"
              description="Checkout button in cart panel"
              defaultMessage="Checkout"
            />
          </Link>
        )}
      </Quickview.Footer>
    </Fragment>
  )
}

export default CartPanel
