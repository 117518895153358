import React, { FC, Fragment } from 'react'
import { parsePaginationHeaders } from '../../api/utils'
import Pagination from '../../bulma/components/Pagination'
import Spinner from '../../bulma/components/Spinner'
import { useSelectedCategories } from '../../categories/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetTopics } from '../hooks'
import { TopicsFilterQueryParams } from '../types'
import TopicListItem from './TopicListItem'
import TopicsFilter from './TopicsFilter'

interface Props {
  isPreview?: boolean
}

const TopicList: FC<Props> = ({ isPreview }) => {
  const [query, setQuery] = useQuery<TopicsFilterQueryParams>()
  const [language] = useCurrentLanguage()
  const [categories] = useSelectedCategories()
  const page = Number(query.page) || 1
  const [request] = useGetTopics({
    page,
    perPage: isPreview ? 4 : 10,
    search: query.search,
    private: query.private ? true : undefined,
    lang: language,
    categories,
  })

  const { data: topics = [] } = request
  const { totalPages } = parsePaginationHeaders(request.headers || {})
  const showPagination = !isPreview && topics.length > 0 && totalPages > 1

  // Attempt to reconcile the error by redirecting to the first page
  if (request.isError && page !== 1) {
    setQuery({ page: '1' })
  }

  return (
    <Fragment>
      <div className="card content-block is-borderless">
        <TopicsFilter />
        <div className="card-content has-padding-x-0 has-padding-top-0 has-padding-bottom-0">
          <ul className="qa-list">
            {request.isLoading ? (
              <Spinner className="has-padding-4" />
            ) : (
              topics.map((topic) => <TopicListItem key={topic.id} {...topic} />)
            )}
          </ul>
        </div>
      </div>
      {showPagination && <Pagination pages={totalPages} />}
    </Fragment>
  )
}

export default TopicList
