import React, { FC } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { SearchResult } from '../types'

const SearchResultRow: FC<SearchResult> = (props) => (
  <div className="news-list-item box is-shadowless is-borderless is-transparent has-margin-y-0 has-margin-bottom-4 is-paddingless">
    <article className="media">
      <div className="media-content">
        <div className="content has-margin-left-1">
          <h2 className="title is-5 has-margin-y-0">
            <Link to={props.url}>
              <span
                dangerouslySetInnerHTML={{
                  __html: props.title,
                }}
              />
            </Link>
          </h2>
          <span className="meta">
            <FormattedMessage
              id="search.SearchResultRow.authorInfo"
              description="Author information of a search result on the search results page."
              defaultMessage="{date} by {author}"
              values={{
                date: <FormattedDate value={props.date} />,
                author: props.author,
              }}
            />
          </span>
          <p className="is-hidden-mobile">
            <span
              dangerouslySetInnerHTML={{
                __html: props.excerptHtml,
              }}
            />
          </p>
        </div>
      </div>
    </article>
  </div>
)

export default SearchResultRow
