import classNames from 'classnames'
import { FormikContextType, connect } from 'formik'
import React, { FC } from 'react'

interface OuterProps {
  type?: 'submit' | 'button'
  color?: string
  className?: string
  isDisabled?: boolean
  onClick?: () => void
}

interface Props extends OuterProps {
  formik: FormikContextType<{}>
}

const SubmitButton: FC<Props> = ({
  type = 'submit',
  color = 'primary',
  className,
  isDisabled,
  formik,
  onClick,
  children,
}) => (
  <div className="control">
    <button
      type={type}
      onClick={onClick}
      disabled={formik.isSubmitting || !formik.isValid || isDisabled}
      className={classNames([
        'button',
        'is-medium',
        `is-${color}`,
        formik.isSubmitting && 'is-loading',
        className,
      ])}
    >
      {children}
    </button>
  </div>
)

export default connect<OuterProps>(SubmitButton)
