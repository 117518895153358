import { FormikContextType, connect } from 'formik'
import React, { FC } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useCart } from '../../../cart/hooks'
import * as api from '../../../checkout/api'
import StripeForm from '../../../checkout/components/StripeForm'
import PayPalButton from '../../../paypal/components/PayPalButton'
import { SubscriptionFormValues } from '../../types'

const messages = defineMessages({
  purchaseFailed: {
    id: 'subscriptions.SubscriptionForm.PaymentStep.purchaseFailed',
    description: 'General error message for subscription purchase failure',
    defaultMessage: "Sorry! We couldn't process your payment. Please try again later.",
  },
})

interface Props {
  formik: FormikContextType<SubscriptionFormValues>
}

const PaymentStep: FC<Props> = ({ formik }) => {
  const [, { addItem, updateCart }] = useCart()
  const { formatMessage } = useIntl()

  return (
    <div className="columns is-centered has-margin-top-4">
      <div className="column is-10">
        <StripeForm />
        <p className="has-text-weight-bold has-text-centered has-margin-bottom-4 is-size-5">
          <FormattedMessage
            id="subscriptions.SubscriptionForm.PaymentStep.payWithPayPal"
            description="Pay with PayPal message in subscription signup form"
            defaultMessage="or pay once with PayPal"
          />
        </p>
        <PayPalButton
          onCancel={() => formik.setSubmitting(false)}
          createOrder={async () => {
            try {
              const { billingAddress, plan } = formik.values
              formik.setStatus(undefined)
              formik.setSubmitting(true)

              await addItem(plan)

              const { data } = await api.createOrder({ billingAddress })

              return data.orderId
            } catch (error) {
              formik.setStatus(formatMessage(messages.purchaseFailed))
              formik.setSubmitting(false)
              return ''
            }
          }}
          onApprove={async ({ orderID }) => {
            try {
              formik.setSubmitting(true)
              await api.captureOrder(orderID, { intent: 'subscribe' })
              await updateCart()
              formik.setSubmitting(false)
              formik.setFieldValue('step', formik.values.step + 1)
            } catch (error) {
              formik.setStatus(formatMessage(messages.purchaseFailed))
              formik.setSubmitting(false)
            }
          }}
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
            currency: 'EUR',
            intent: 'capture',
            commit: true,
          }}
        />
      </div>
    </div>
  )
}

export default connect<{}, SubscriptionFormValues>(PaymentStep)
