import { Field, FormikContextType, connect } from 'formik'
import React, { FC } from 'react'
import Switch from '../../formik/components/Switch'
import CardDetails from '../../user/components/CardDetails'
import { useCurrentUser } from '../../user/hooks'
import { CheckoutFormValues } from '../types'
import PaymentMethodForm from './PaymentMethodForm'

interface Props {
  formik: FormikContextType<CheckoutFormValues>
}

const PaymentMethod: FC<Props> = ({ formik }) => {
  const [currentUser] = useCurrentUser()
  const cardDetails = currentUser && currentUser.cardDetails
  const hasOtherPaymentMethod = !!cardDetails
  const isFormVisible = !hasOtherPaymentMethod || formik.values.useOtherPaymentMethod

  return (
    <div className="panel">
      <p className="panel-heading">Payment Method</p>
      {hasOtherPaymentMethod && (
        <div className="panel-block is-highlighted-block">
          <Field name="useOtherPaymentMethod" label="Use other payment method" component={Switch} />
        </div>
      )}
      <div className="panel-block">
        {isFormVisible ? <PaymentMethodForm /> : cardDetails && <CardDetails {...cardDetails} />}
      </div>
    </div>
  )
}
export default connect<{}, CheckoutFormValues>(PaymentMethod)
