import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

interface Props {
  isSubmitting: boolean
  onSubmit: () => void
}

const Buttons: FC<Props> = ({ onSubmit, isSubmitting }) => {
  const { goBack } = useHistory()

  return (
    <div className="panel-block panel-footer">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={goBack} className="button is-secondary is-medium is-outlined">
        <FormattedMessage
          id="events.Buttons.back"
          description="Back button in event page"
          defaultMessage="Back"
        />
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={isSubmitting ? undefined : onSubmit} className="button is-primary">
        <FormattedMessage
          id="events.Buttons.register"
          description="Register button in event page"
          defaultMessage="Register"
        />
      </a>
    </div>
  )
}

export default Buttons
