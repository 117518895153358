import React, { FC } from 'react'
import { Post } from '../types'
import { getFeaturedMedia } from '../utils'

const CoverImage: FC<Post> = (props) => {
  const media = getFeaturedMedia(props)
  if (!media) {
    return null
  }

  const sizes = media.media_details.sizes
  const image = sizes.orreca_front_large || sizes.full

  return <img src={image.source_url} className="cover" alt={media.alt_text} />
}

export default CoverImage
