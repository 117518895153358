import classNames from 'classnames'
import React, { FC } from 'react'

interface Props {
  className?: string
}

const Body: FC<Props> = ({ className, children }) => (
  <div className={classNames(['quickview-body', className])}>{children}</div>
)

export default Body
