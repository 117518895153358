import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import CategoryPicker from '../../categories/components/CategoryPicker'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'
import { useCurrentUser } from '../../user/hooks'
import { isSubscriber } from '../../user/utils'
import FollowedTopics from './FollowedTopics'
import PremiumPreview from './PremiumPreview'
import TopicList from './TopicList'

const messages = defineMessages({
  title: {
    id: 'topics.TopicsPage.title',
    description: 'Q&A page title',
    defaultMessage: 'Q&A',
  },
})

const TopicsPage: FC = () => {
  const [followedOnly, setFollowedOnly] = useState(false)
  const [currentUser] = useCurrentUser()
  const { formatMessage } = useIntl()
  const { open } = useModal()

  if (!isSubscriber(currentUser)) {
    return <PremiumPreview />
  }

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="container">
        <CategoryPicker />
        <div className="columns is-clearfix">
          <div className="column is-12 has-padding-bottom-0">
            <h1 className="title is-3 is-pulled-left has-margin-bottom-0 has-margin-left-4 is-hidden-mobile">
              <FormattedMessage
                id="topics.TopicsPage.heading"
                description="Q&A topics page heading"
                defaultMessage="Q&A Topics"
              />
            </h1>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="button is-primary is-pulled-right is-button-block"
              onClick={() => open(ModalType.CreateTopic)}
            >
              <FormattedMessage
                id="topics.TopicsPage.create"
                description="Create topic button text"
                defaultMessage="Start new topic"
              />
            </a>
          </div>
        </div>
        <div className="tabs is-toggle is-medium is-fullwidth has-padding-bottom-0 has-margin-bottom-4 is-hidden-tablet">
          <ul>
            <li className={!followedOnly ? 'is-active' : ''}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => setFollowedOnly(false)}>
                <FormattedMessage
                  id="topics.TopicsPage.qaTopicsOnly"
                  description="Q&A topics only filter button in mobile view"
                  defaultMessage="Q&A topics"
                />
              </a>
            </li>
            <li className={followedOnly ? 'is-active' : ''}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => setFollowedOnly(true)}>
                <FormattedMessage
                  id="topics.TopicsPage.followedTopicsOnly"
                  description="Followed topics only filter button in mobile view"
                  defaultMessage="Followed topics"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="columns">
          <div className={`column is-8 ${followedOnly ? 'is-hidden-mobile' : ''}`}>
            <TopicList />
          </div>
          <div className={`column is-4 sidebar ${!followedOnly ? 'is-hidden-mobile' : ''}`}>
            <FollowedTopics />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TopicsPage
