import { useContext } from 'react'
import { QuickviewContext } from './context'
import { QuickviewType } from './enums'
import { UseQuickview } from './types'

export const useQuickview = (): UseQuickview => {
  const { quickview, setQuickview } = useContext(QuickviewContext)

  const open = (aQuickview: QuickviewType): void => setQuickview(aQuickview)
  const close = (): void => setQuickview(undefined)

  return { quickview, open, close }
}
