import React, { FC, Fragment } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useCurrentLanguage } from '../../locale/hooks'
import { useGetPodcasts } from '../hooks'
import PreviousPodcastsListItem from './PreviousPodcastsListItem'

interface Props {
  authorId: number
  exclude?: number[]
}

const messages = defineMessages({
  otherPodcastsTitle: {
    id: 'podcasts.PreviousPodcastsList.otherPodcastsTitle',
    description: "'Host's other podcasts' block title on the podcast details page.",
    defaultMessage: "Host's other podcasts",
  },
})

const PreviousPodcastsList: FC<Props> = ({ authorId, exclude }) => {
  const { formatMessage } = useIntl()
  const [language] = useCurrentLanguage()
  const [request] = useGetPodcasts({
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: 2,
    _embed: true,
    lang: language,
    author: [authorId],
    exclude,
  })

  const { data: podcasts = [] } = request

  if (podcasts.length === 0) {
    return null
  }

  return (
    <Fragment>
      <h2 className="title is-4">{formatMessage(messages.otherPodcastsTitle)}</h2>
      <div className="columns has-margin-bottom-5">
        {podcasts.map((podcast, i) => (
          <PreviousPodcastsListItem key={podcast.id} position={i} {...podcast} />
        ))}
      </div>
    </Fragment>
  )
}

export default PreviousPodcastsList
