import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import CategoryPicker from '../../categories/components/CategoryPicker'
import AllVideosSwitch from './AllVideosSwitch'
import VideoList from './VideoList'

const messages = defineMessages({
  title: {
    id: 'videos.VideosPage.title',
    description: 'Page title for the videos page.',
    defaultMessage: 'Videos',
  },
})

const VideosPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="container">
        <CategoryPicker />
        <AllVideosSwitch />
        <VideoList perPage={20} />
      </div>
    </section>
  )
}

export default VideosPage
