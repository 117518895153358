import { noop } from 'lodash-es'
import { createContext } from 'react'
import { ModalType } from './enums'

interface Value {
  modal?: ModalType
  setModal: (modal?: ModalType) => void
}

export const ModalContext = createContext<Value>({ setModal: noop })
