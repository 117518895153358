import classNames from 'classnames'
import React, { FC } from 'react'
import './Spinner.css'

interface Props {
  className?: string
}

const Spinner: FC<Props> = ({ className }) => (
  <div className={classNames(['level spinner', className])}>
    <div className="level-item has-text-centered">
      <div className="loader" />
    </div>
  </div>
)

export default Spinner
