import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { deactivateUser } from '../../../user/api'
import { useCurrentUser, useLogout } from '../../../user/hooks'

const Deactivate: FC = () => {
  const logout = useLogout()
  const [isDeactivating, setIsDeactivating] = useState(false)
  const [currentUser] = useCurrentUser()
  if (!currentUser) {
    return null
  }

  return (
    <div className="panel">
      <p className="panel-heading is-borderless has-padding-y-1" />
      <div className="panel-block is-column has-padding-5">
        <h1 className="title is-4 has-margin-bottom-2">
          <FormattedMessage
            id="account.Deactivate.title"
            description="Account deactivation title in user preferences"
            defaultMessage="Deactivate account"
          />
        </h1>
        <p>
          <FormattedMessage
            id="account.Deactivate.description"
            description="Account deactivation description in user preferences"
            defaultMessage="We will delete all user related data and information but if you have made any purchased from the webshop, we have to store information for accounting purposes as this becomes from the law. We will store this information for 10 years."
          />
        </p>
        <h2 className="title is-5 has-margin-top-4 has-margin-bottom-3">
          <FormattedMessage
            id="account.Deactivate.delayQuestion"
            description="Account deactivation delay question in user preferences"
            defaultMessage="What happens after 10 years?"
          />
        </h2>
        <ul className="is-list">
          <li>
            <FormattedMessage
              id="account.Deactivate.delayAnswer"
              description="Account deactivation delay answer in user preferences"
              defaultMessage="Should be deleted from a purchase date, initial year is not counted in."
            />
          </li>
          <li>
            <FormattedMessage
              id="account.Deactivate.delayExample"
              description="Account deactivation delay example in user preferences"
              defaultMessage="For example: purchase made on 3nd of June 2019, 10 years will be counted from 1 of January 2020."
            />
          </li>
        </ul>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={async () => {
            try {
              if (!isDeactivating) {
                setIsDeactivating(true)
                await deactivateUser(currentUser.id)
                await logout()
                setIsDeactivating(false)
              }
            } catch (error) {
              setIsDeactivating(false)
            }
          }}
          className={`button is-danger is-outlined is-medium has-margin-bottom-2 has-margin-top-4 ${
            isDeactivating ? 'is-loading' : ''
          }`}
        >
          <FormattedMessage
            id="account.Deactivate.deactivate"
            description="Account deactivation button in user preferences"
            defaultMessage="Deactivate"
          />
        </a>
      </div>
    </div>
  )
}

export default Deactivate
