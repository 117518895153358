import React, { FC, Fragment } from 'react'
import Address from './Address'
import History from './History'
import PaymentMethod from './PaymentMethod'
import Plan from './Plan'

const Billing: FC = () => (
  <Fragment>
    <Plan />
    <PaymentMethod />
    <Address />
    <History />
  </Fragment>
)

export default Billing
