import { FormikContextType, connect } from 'formik'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import SubmitButton from '../../formik/components/SubmitButton'
import { CheckoutFormValues } from '../types'

interface Props {
  formik: FormikContextType<CheckoutFormValues>
}

const Actions: FC<Props> = ({ formik }) => {
  const history = useHistory()

  return (
    <div className="form-actions is-clearfix has-padding-bottom-5 has-padding-bottom-0-desktop">
      <SubmitButton
        className="is-pulled-right has-text-weight-bold"
        isDisabled={formik.values.paymentMethod.method === 'paypal'}
        color="link"
      >
        <FormattedMessage
          id="checkout.Actions.submit"
          description="Submit button in checkout form"
          defaultMessage="Confirm"
        />
      </SubmitButton>
      <button
        type="button"
        onClick={history.goBack}
        className="button is-medium is-outlined has-text-weight-bold is-pulled-left"
      >
        <FormattedMessage
          id="checkout.Actions.back"
          description="Back button in checkout form"
          defaultMessage="Back"
        />
      </button>
    </div>
  )
}

export default connect<{}, CheckoutFormValues>(Actions)
