import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import AppointmentForm from './AppointmentForm'

const messages = defineMessages({
  title: {
    id: 'appointments.AppointmentPage.title',
    description: 'Appointment page title',
    defaultMessage: 'Appointments',
  },
})

const AppointmentPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <section className="section has-padding-top-4 has-padding-top-5-tablet">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-10 is-offset-1 is-6-desktop is-offset-3-desktop has-margin-bottom-6-tablet">
            <AppointmentForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AppointmentPage
