import { useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { Event, GetEventsQueryParams } from './types'
import { getGroupedEvents } from './utils'

export const useGetEvents = (params: GetEventsQueryParams = {}): UseAxios<Event[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<Event[]>({ url: '/orreca/v1/events', params }, [mParams])
}

export const useGetEvent = (id: number): UseAxios<Event> =>
  useAxios<Event>({ url: `/orreca/v1/events/${id}` }, [id])

export const useGroupedEvents = (events: Event[]): Record<string, Event[]> =>
  useMemo(() => getGroupedEvents(events), [events])
