import AddressForm from '../account/components/Billing/AddressForm'
import PaymentMethodFormContainer from '../account/components/Billing/PaymentMethodFormContainer'
import DetailsForm from '../account/components/Details/DetailsForm'
import DisableTwoFactor from '../account/components/Security/DisableTwoFactor'
import TwoFactorForm from '../account/components/Security/TwoFactorForm'
import OrderDetails from '../account/components/ShoppingHistory/OrderDetails'
import CookieConsentForm from '../consents/components/CookieConsentForm'
import SignupForm from '../newsletter/components/SignupForm'
import PageModal from '../pages/components/PageModal'
import SubscriptionFormContainer from '../subscriptions/components/SubscriptionForm/Container'
import CreateTopicForm from '../topics/components/CreateTopicForm'
import ForgotPasswordForm from '../user/components/ForgotPasswordForm'
import ImproveProfileModal from '../user/components/ImproveProfileModal'
import LoginForm from '../user/components/LoginForm'
import RegistrationForm from '../user/components/RegistrationForm'
import RegistrationSuccess from '../user/components/RegistrationSuccess'
import ResetPasswordForm from '../user/components/ResetPasswordForm'
import { ModalType } from './enums'

const modals = {
  [ModalType.BillingAddress]: AddressForm,
  [ModalType.CookieConsent]: CookieConsentForm,
  [ModalType.CreateTopic]: CreateTopicForm,
  [ModalType.DisableTwoFactor]: DisableTwoFactor,
  [ModalType.EnableTwoFactor]: TwoFactorForm,
  [ModalType.ForgotPassword]: ForgotPasswordForm,
  [ModalType.ImproveProfile]: ImproveProfileModal,
  [ModalType.Login]: LoginForm,
  [ModalType.NewsletterSignup]: SignupForm,
  [ModalType.OrderDetails]: OrderDetails,
  [ModalType.Page]: PageModal,
  [ModalType.PaymentMethod]: PaymentMethodFormContainer,
  [ModalType.Register]: RegistrationForm,
  [ModalType.RegisterSuccess]: RegistrationSuccess,
  [ModalType.ResetPassword]: ResetPasswordForm,
  [ModalType.Subscribe]: SubscriptionFormContainer,
  [ModalType.UserDetails]: DetailsForm,
}

export const getComponentForModal = (
  modal?: ModalType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.FunctionComponent<any> | React.ComponentClass<any, any> | null =>
  modal ? modals[modal] || null : null
