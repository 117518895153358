import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingEvent: FC = () => (
  <section className="section has-margin-y-4 has-margin-y-5-tablet">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-7">
          <div className="has-margin-bottom-4">
            <h1 className="is-title is-5 has-text-weight-bold">
              <Skeleton />
            </h1>
            <Skeleton width="20%" />
          </div>
          <div className="has-margin-bottom-4">
            <Skeleton width="100%" />
            <Skeleton width="85%" />
            <Skeleton width="72%" />
            <Skeleton width="65%" />
            <Skeleton width="90%" />
            <Skeleton width="83%" />
            <Skeleton width="66%" />
          </div>
          <div className="has-margin-bottom-4">
            <Skeleton width="80%" />
            <Skeleton width="50%" />
          </div>
          <div className="has-margin-bottom-4">
            <Skeleton width="45%" />
            <Skeleton width="70%" />
          </div>
          <div className="has-margin-bottom-4">
            <Skeleton width="95%" />
            <Skeleton width="80%" />
            <Skeleton width="85%" />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default LoadingEvent
