import React, { FC, Fragment } from 'react'
import NewTag from './NewTag'
import PremiumTag from './PremiumTag'

interface Props {
  isNew: boolean
  isPremium: boolean
  size?: 'medium' | 'large'
}

const PostTags: FC<Props> = ({ isNew, isPremium, size = 'medium' }) => {
  if (!isNew && !isPremium) {
    return null
  }

  return (
    <Fragment>
      {isNew && <NewTag size={size} />}
      {isPremium && <PremiumTag size={size} />}
    </Fragment>
  )
}

export default PostTags
