import { useCallback, useContext } from 'react'
import { useQuery } from '../router/hooks'
import { ModalContext } from './context'
import { ModalType } from './enums'
import { UseModal, UsePageModal } from './types'

export const useModal = (): UseModal => {
  const { modal, setModal } = useContext(ModalContext)

  const openModal = (modal: ModalType): void => setModal(modal)
  const closeModal = (): void => setModal(undefined)

  const open = useCallback(openModal, [])
  const close = useCallback(closeModal, [setModal])

  return { modal, open, close }
}

export const usePageModal = (): UsePageModal => {
  const [, setQuery] = useQuery<{ pid: string }>()
  const modal = useModal()

  const open = (pageId: number): void => {
    setQuery({ pid: String(pageId) })
    modal.open(ModalType.Page)
  }

  const close = (): void => {
    setQuery({ pid: undefined })
    close()
  }

  return { ...modal, open, close }
}
