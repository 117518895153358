import React, { FC } from 'react'
import { QuickviewType } from '../../quickview/enums'
import { useQuickview } from '../../quickview/hooks'

const SearchMenuButton: FC = () => {
  const { open, close, quickview } = useQuickview()
  const isSearchOpen = quickview === QuickviewType.Search

  return (
    <span
      role="button"
      aria-label="menu"
      aria-expanded={isSearchOpen}
      onClick={isSearchOpen ? close : () => open(QuickviewType.Search)}
      className="navbar-burger navbar-search is-hidden-desktop has-margin-left-1"
    >
      <i className="icon icon-search-menu" />
    </span>
  )
}

export default SearchMenuButton
