import React, { FC } from 'react'
import { FormattedDate, FormattedMessage, defineMessages, useIntl } from 'react-intl'
import avatar from '../../assets/img/ico_profile.svg'
import FormattedCategory from '../../categories/components/FormattedCategory'
import { getAuthor, getFeaturedMedia } from '../../posts/utils'
import { Podcast } from '../types'

const messages = defineMessages({
  coverAlt: {
    id: 'podcasts.PodcastAuthorBlock.coverAlt',
    description: "Alt message of the podcast's cover image.",
    defaultMessage: "Podcast's cover image",
  },
})

const PodcastAuthorBlock: FC<Podcast> = (props) => {
  const { formatMessage } = useIntl()
  const author = getAuthor(props)
  const media = getFeaturedMedia(props)

  const mediaDetails = (media && media.media_details) || undefined
  const backgroundImage = mediaDetails ? mediaDetails.sizes['orreca_front_small'] : undefined

  return (
    <div className="podcast-head has-padding-4">
      <h1 className="title is-2 has-margin-bottom-3">
        <span dangerouslySetInnerHTML={{ __html: props.title.rendered }} />
      </h1>
      <div className="meta">
        <figure className="image is-48x48 is-pulled-left has-margin-right-">
          {author && (
            <img
              className="profile-pic is-rounded"
              src={author.avatar || avatar}
              alt={author.name}
            />
          )}
        </figure>
        <div className="text is-pulled-left">
          {author && (
            <p className="name has-margin-bottom-0">
              <FormattedMessage
                id="podcasts.PodcastAuthorBlock.hostedBy"
                description="Hosted by author information message on the podcast details page."
                defaultMessage="Hosted by {author}"
                values={{
                  author: <span className="has-text-weight-bold">{author.name}</span>,
                }}
              />
            </p>
          )}

          <span className="date has-margin-0 has-text-grey">
            <FormattedDate value={props.date} />
            {props.categories.map((category) => (
              <FormattedCategory as="a" id={category} key={category} />
            ))}
          </span>
        </div>
      </div>
      <img
        className="cover"
        src={backgroundImage ? backgroundImage.source_url : undefined}
        alt={formatMessage(messages.coverAlt)}
      />
    </div>
  )
}

export default PodcastAuthorBlock
