import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Spinner from '../../bulma/components/Spinner'
import { useCurrentLanguage } from '../../locale/hooks'
import { useGetInfiniteTopics } from '../hooks'
import FollowedTopic from './FollowedTopic'

const FollowedTopics: FC = () => {
  const [language] = useCurrentLanguage()
  const [request, loadMore] = useGetInfiniteTopics({
    perPage: 5,
    followed: true,
    lang: language,
  })

  const { items: topics = [] } = request

  return (
    <div className="card content-block">
      <header className="card-header is-shadowless is-borderless has-padding-top-4 has-padding-bottom-4 has-padding-bottom-3 has-padding-left-4">
        <h2 className="title is-4">
          <FormattedMessage
            id="topics.FollowedTopics.heading"
            description="Followed Q&A topics heading panel"
            defaultMessage="Followed topics"
          />
        </h2>
      </header>
      <div className="card-content has-padding-x-0 has-padding-top-0 has-padding-bottom-0">
        <ul className="qa-followed-list has-padding-top-4 has-padding-bottom-4 has-padding-bottom-3 has-padding-x-4">
          {topics.map((topic) => (
            <FollowedTopic key={topic.id} {...topic} />
          ))}
        </ul>
      </div>
      <footer className="card-footer">
        {request.hasMore && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className="card-footer-item has-text-weight-bold"
            onClick={() => !request.isLoading && loadMore()}
          >
            {request.isLoading ? (
              <Spinner />
            ) : (
              <FormattedMessage
                id="topics.FollowedTopics.seeMore"
                description="Link that displays more followed Q&A topics"
                defaultMessage="More followed topics"
              />
            )}
          </a>
        )}
      </footer>
    </div>
  )
}

export default FollowedTopics
