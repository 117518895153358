import { first } from 'lodash-es'
import React, { FC, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import avatar from '../../../assets/img/ico_profile.svg'
import Image from '../../../bulma/components/Image'
import { updateAvatar } from '../../api'
import { useCurrentUser } from '../../hooks'

const AvatarStep: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isRemoving, setIsRemoving] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [user, setUser] = useCurrentUser()
  if (!user) {
    return null
  }

  return (
    <div className="columns-has-padding-top-2 has-padding-bottom-3 is-centered">
      <div className="column is-12 has-margin-top-3 has-padding-bottom-3 is-centered">
        <div className="has-padding-x-4 has-padding-bottom-4">
          <p className="is-secondary has-text-grey-light">
            <FormattedMessage
              id="account.ImproveProfileModal.AvatarStep.description"
              description="Avatar description text in profile improvement modal"
              defaultMessage="Here you can upload your profile picture. With this picture you appear on Q&A and article discussions"
            />
          </p>
        </div>
        <div className="box is-img-uploader has-padding-y-5 is-shadow-less has-text-centered">
          <Image
            className={`is-96x96 ${user.avatar ? '' : 'is-placeholder'}`}
            src={user.avatar || avatar}
            alt={user.name}
            isRounded
          />
          {user.avatar && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className={`button is-medium is-text is-outlined has-text-weight-bold has-margin-top-4 ${
                isRemoving ? 'is-loading' : ''
              }`}
              onClick={async () => {
                try {
                  if (!isRemoving) {
                    setIsRemoving(true)
                    const response = await updateAvatar(user.id, undefined)
                    setUser(response.data)
                    setIsRemoving(false)
                  }
                } catch (error) {
                  setIsRemoving(false)
                }
              }}
            >
              <FormattedMessage
                id="account.ImproveProfileModal.AvatarStep.remove"
                description="Remove avatar button in profile improvement modal"
                defaultMessage="Remove"
              />
            </a>
          )}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={() => inputRef.current && inputRef.current.click()}
            className={`button is-medium is-link has-text-weight-bold has-margin-top-4 has-margin-x-2 ${
              isUploading ? 'is-loading' : ''
            }`}
          >
            <FormattedMessage
              id="account.ImproveProfileModal.AvatarStep.upload"
              description="Upload avatar button in profile improvement modal"
              defaultMessage="Upload"
            />
          </a>
          <input
            hidden
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={async (event) => {
              try {
                const file = first(event.target.files)
                if (!isUploading && file) {
                  setIsUploading(true)
                  const response = await updateAvatar(user.id, file)
                  setUser(response.data)
                  setIsUploading(false)
                }
              } catch (error) {
                setIsUploading(false)
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AvatarStep
