import { Appointment, CreateAppointmentFormValues } from './types'
import { AxiosPromise } from 'axios'
import axios from '../api/axios'

export const createAppointment = (
  appointment: CreateAppointmentFormValues
): AxiosPromise<Appointment> => {
  const formData = new FormData()
  formData.append('type', String(appointment.type))
  formData.append('description', appointment.description)
  formData.append('time', appointment.time)
  formData.append('specifyTime', appointment.specifyTime)
  formData.append('captcha', appointment.captcha)

  appointment.files.map((file, i) => formData.append(`file${i}`, file))

  return axios.post<Appointment>('/orreca/v1/appointments', formData)
}
