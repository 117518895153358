import React, { FC } from 'react'
import Slider from 'react-slick'
import { Image } from '../types'
import ProductImage from './ProductImage'

interface Props {
  images: Image[]
}

const ProductGallery: FC<Props> = ({ images }) => (
  <Slider adaptiveHeight dots>
    {images.map((image) => (
      <ProductImage key={image.id} image={image} size="orreca_shop_gallery" />
    ))}
  </Slider>
)

export default ProductGallery
