/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { messages as generalMessages } from '../formik/messages'
import * as user from '../user/schema'

export const usePaymentMethodSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      paymentMethod: yup.object().shape({
        cardDetails: yup.object().shape({
          holder: yup.string().required(requiredMessage),
          cvcValid: yup.boolean().oneOf([true]).required(requiredMessage),
          expirationDateValid: yup.boolean().oneOf([true]).required(requiredMessage),
          numberValid: yup.boolean().oneOf([true]).required(requiredMessage),
        }),
      }),
    })
  }, [formatMessage])
}

export const useAddressSchema = () => {
  const billingAddressSchema = user.useBillingAddressSchema()

  return useMemo(
    () =>
      yup.object().shape({
        billingAddress: billingAddressSchema,
      }),
    [billingAddressSchema]
  )
}

export const useDetailsSchema = () => {
  const { formatMessage } = useIntl()
  const passwordSchema = user.usePasswordSchema()
  const passwordMatchSchema = user.usePasswordMatchSchema('new')

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      username: yup.string().required(requiredMessage),
      changePassword: yup.boolean().required(requiredMessage),
      password: yup.object().when('changePassword', {
        is: true,
        then: yup.object().shape({
          current: yup.string().required(requiredMessage),
          new: passwordSchema,
          confirm: passwordMatchSchema,
        }),
        otherwise: yup.object().notRequired(),
      }),
    })
  }, [formatMessage, passwordMatchSchema, passwordSchema])
}

export const useTwoFactorSchema = () => {
  const totpSchema = user.useTotpSchema()
  return useMemo(() => yup.object().shape({ code: totpSchema }), [totpSchema])
}
