import React, { FC } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import Link from '../../router/components/Link'
import { Event } from '../types'

const EventListItem: FC<Event> = (props) => {
  const url = `/events/${props.id}/${props.slug}`

  return (
    <li className="is-events-item has-padding-y-3 has-padding-x-4">
      <div className="media">
        <div className="media-left">
          <span className="date">
            <FormattedDate day="numeric" value={props.startDate} />
          </span>
        </div>
        <div className="media-content">
          <h3 className="title has-margin-y-0 has-padding-0">
            <Link to={url}>{props.title}</Link>
          </h3>
          <div
            className="is-hidden-touch"
            dangerouslySetInnerHTML={{ __html: props.excerptHtml }}
          />
        </div>
        <div className="media-right">
          <Link to={url} className="button is-rounded">
            <span className="txt is-hidden-touch">
              <FormattedMessage
                id="events.EventListItem.moreInfo"
                description="More info button in event list"
                defaultMessage="More info"
              />
            </span>
          </Link>
        </div>
      </div>
    </li>
  )
}

export default EventListItem
