import React, { FC } from 'react'
import { Video } from '../types'
import VideoThumbnail from './VideoThumbnail'
import Link from '../../router/components/Link'

const VideoListItem: FC<Video> = (props) => (
  <div className="column is-4-tablet is-3-desktop">
    <Link
      to={`/videos-and-podcasts/videos/${props.id}/${props.slug}`}
      className="video-item card is-borderless has-margin-y-0 is-paddingless has-margin-bottom-0"
    >
      <VideoThumbnail {...props} />
    </Link>
  </div>
)

export default VideoListItem
