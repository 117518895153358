import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import FeaturedMedia from '../../posts/components/FeaturedMedia'
import PostTags from '../../posts/components/PostTags'
import { Post } from '../../posts/types'
import { getAuthor, getPostUrl, isNewContent } from '../../posts/utils'
import Link from '../../router/components/Link'

const messages = defineMessages({
  published: {
    defaultMessage: '{published} by {author}',
    description: 'Published date with author name in news list',
    id: 'news.NewsListItem.published',
  },
})

const NewsListItem: FC<Post> = (props) => {
  const { formatMessage, formatDate } = useIntl()
  const author = getAuthor(props)
  const postUrl = getPostUrl(props)
  const published = formatDate(props.date, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <div className="news-list-item box is-shadowless is-borderless is-transparent has-margin-y-0 has-padding-y-0 has-margin-bottom-4 has-padding-x-0">
      <article className="media">
        <div className="media-left">
          <Link to={postUrl}>
            <FeaturedMedia post={props} size="orreca_thumbnail" />
          </Link>
        </div>
        <div className="media-content">
          <div className="content has-margin-left-1">
            <h2 className="title is-5 has-margin-y-0">
              <Link to={postUrl}>{props.title.rendered}</Link>
              <PostTags isNew={isNewContent(props)} isPremium={props.crb_premium} />
            </h2>
            {author && published && (
              <span className="meta has-margin-bottom-0">
                {formatMessage(messages.published, {
                  published,
                  author: author.name,
                })}
              </span>
            )}
            <div
              className="is-hidden-mobile"
              dangerouslySetInnerHTML={{ __html: props.excerpt.rendered }}
            />
          </div>
        </div>
      </article>
    </div>
  )
}

export default NewsListItem
