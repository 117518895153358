import React, { FC } from 'react'
import FormattedDateTime from '../../dates/components/FormattedDateTime'

interface Props {
  from?: string
  to?: string
}

const Duration: FC<Props> = ({ from, to }) => (
  <span className="meta">
    {from && <FormattedDateTime value={from} />}
    {from && to && ' – '}
    {to && <FormattedDateTime value={to} />}
  </span>
)

export default Duration
