import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  size: 'medium' | 'large'
}

const NewTag: FC<Props> = ({ size }) => (
  <span className={`tag is-${size} is-uppercase is-new is-rounded has-margin-left-2`}>
    <FormattedMessage
      id="posts.NewTag.text"
      description="New tag for different content types"
      defaultMessage="New"
    />
  </span>
)

export default NewTag
