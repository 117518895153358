import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentLanguage } from '../../locale/hooks'
import Link from '../../router/components/Link'
import { useGetVideos } from '../hooks'
import { Video } from '../types'

const RelatedVideos: FC<Video> = (props) => {
  const [language] = useCurrentLanguage()
  const [request] = useGetVideos({
    categories: props.categories,
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: 3,
    lang: language,
    exclude: [props.id],
  })

  const { data: videos = [] } = request

  return (
    <div className="column is-4 sidebar has-margin-bottom-6">
      <div className="card content-block">
        <header className="card-header is-shadowless is-borderless has-padding-4 has-padding-bottom-3">
          <h2 className="title is-4">
            <FormattedMessage
              id="videos.RelatedVideos.title"
              description="Title of the related videos block on the video details page."
              defaultMessage="Related <span>Videos</span>"
              values={{
                span: (msg: string) => <span className="has-text-grey-light">{msg}</span>,
              }}
            />
          </h2>
        </header>
        <div className="card-content is-paddingless">
          <ul className="qa-followed-list has-padding-4">
            {videos.map((video) => (
              <li key={video.id} className="has-margin-bottom-4">
                <Link
                  to={`/videos-and-podcasts/videos/${video.id}/${video.slug}`}
                  className="is-title"
                >
                  <span dangerouslySetInnerHTML={{ __html: video.title.rendered }} />
                </Link>
                <div className="date">{video.crb_video_duration}</div>
              </li>
            ))}
          </ul>
        </div>
        <footer className="card-footer">
          <Link
            to="/videos-and-podcasts/videos"
            search={{ categories: props.categories }}
            className="card-footer-item has-text-weight-bold"
          >
            <FormattedMessage
              id="videos.RelatedVideos.moreVideos"
              description="The 'More Videos' button of the related videos block on the video details page."
              defaultMessage="More Videos"
            />
          </Link>
        </footer>
      </div>
    </div>
  )
}

export default RelatedVideos
