import AccountPage from './account/components/AccountPage'
import AppointmentPage from './appointments/components/AppointmentPage'
import CheckoutPage from './checkout/components/CheckoutPage'
import EventPage from './events/components/EventPage'
import EventsPage from './events/components/EventsPage'
import FrontPage from './front-page/components/FrontPage'
import ArticlePage from './news/components/ArticlePage'
import NewsPage from './news/components/NewsPage'
import OrderPage from './orders/components/OrderPage'
import PodcastDetailsPage from './podcasts/components/PodcastDetailsPage'
import PodcastsPage from './podcasts/components/PodcastsPage'
import ProductPage from './products/components/ProductPage'
import SearchPage from './search/components/SearchPage'
import ShopPage from './shop/components/ShopPage'
import TopicPage from './topics/components/TopicPage'
import TopicsPage from './topics/components/TopicsPage'
import VideosPodcasts from './videos-podcasts/components/VideosPodcasts'
import VideoDetailsPage from './videos/components/VideoDetailsPage'
import VideosPage from './videos/components/VideosPage'

export const routes = [
  { path: '/(en|de)', Component: FrontPage },
  { path: '/(en|de)/search', Component: SearchPage },
  { path: '/(en|de)/news', Component: NewsPage },
  { path: '/(en|de)/news/:id/:slug?', Component: ArticlePage },
  { path: '/(en|de)/events', Component: EventsPage },
  { path: '/(en|de)/events/:id/:slug?', Component: EventPage },
  { path: '/(en|de)/shop', Component: ShopPage },
  { path: '/(en|de)/shop/checkout', Component: CheckoutPage },
  { path: '/(en|de)/shop/orders/:key', Component: OrderPage },
  { path: '/(en|de)/shop/:id/:slug?', Component: ProductPage },
  { path: '/(en|de)/topics', Component: TopicsPage },
  { path: '/(en|de)/topics/:id/:slug?', Component: TopicPage },
  { path: '/(en|de)/account/:section?', Component: AccountPage },
  { path: '/(en|de)/appointments', Component: AppointmentPage },
  { path: '/(en|de)/videos-and-podcasts', Component: VideosPodcasts },
  { path: '/(en|de)/videos-and-podcasts/videos', Component: VideosPage },
  {
    path: '/(en|de)/videos-and-podcasts/videos/:id/:slug?',
    Component: VideoDetailsPage,
  },
  { path: '/(en|de)/videos-and-podcasts/podcasts', Component: PodcastsPage },
  {
    path: '/(en|de)/videos-and-podcasts/podcasts/:id/:slug?',
    Component: PodcastDetailsPage,
  },
]
