import * as t from './types'
import { parsePaginationHeaders } from './utils'

export const axiosReducer = <T>(
  state: t.AxiosState<T>,
  action: t.AxiosAction<T>
): t.AxiosState<T> => {
  switch (action.type) {
    case 'load':
      return { ...state, isLoading: true, isError: false }
    case 'success':
      const { data, headers } = action.payload
      return {
        ...state,
        data,
        headers,
        isError: false,
        isLoading: false,
        isInitial: false,
      }
    case 'error':
      return { ...state, isInitial: false, isLoading: false, isError: true }
    default:
      throw new Error('Invalid action type')
  }
}

export const infiniteScrollReducer = <T>(
  state: t.InfiniteScrollState<T>,
  action: t.InfiniteScrollAction<T>
): t.InfiniteScrollState<T> => {
  switch (action.type) {
    case 'load':
      return { ...state, page: state.page + 1, isLoading: true }
    case 'success':
      const { data, headers } = action.payload
      const { totalPages, total } = parsePaginationHeaders(headers)
      return {
        ...state,
        total,
        items: state.items ? state.items.concat(data) : data,
        hasMore: state.page < totalPages,
        isError: false,
        isLoading: false,
      }
    case 'error':
      return {
        ...state,
        hasMore: false,
        isLoading: false,
        isError: true,
      }
    default:
      throw new Error('Invalid action type')
  }
}
