import React, { FC } from 'react'

interface Props {
  url: string
}

const Website: FC<Props> = ({ url }) => (
  <p>
    <a href={url} target="_blank" rel="noopener noreferrer">
      {url}
    </a>
  </p>
)

export default Website
