import { castArray, xor } from 'lodash-es'
import React, { FC, Fragment, useRef, useState } from 'react'
import { useBoolean } from 'react-hanger/array/useBoolean'
import { FormattedMessage } from 'react-intl'
import { useCurrentLanguage } from '../../locale/hooks'
import { useGetFormats } from '../../products/hooks'
import { ProductsFilterQueryParams } from '../../products/types'
import { useQuery } from '../../router/hooks'
import { useClickOutside, useDebounce } from '../../utils/hooks'

const FormatFilter: FC = () => {
  const [query, setQuery] = useQuery<ProductsFilterQueryParams>()
  const initialValue = castArray(query.formats || []).map(Number)
  const [value, setValue] = useState<number[]>(initialValue)
  const [language] = useCurrentLanguage()
  const [request] = useGetFormats({ lang: language })
  const { data: formats = [] } = request

  const [isOpen, actions] = useBoolean(false)
  const ref = useRef(null)
  useClickOutside(ref, actions.setFalse)

  const selectedFormats = formats.filter((attribute) => value.includes(attribute.id))

  useDebounce(() => setQuery({ formats: value.map(String) }), 350, [value])

  const isFormatSelected = (id: number): boolean =>
    !!selectedFormats.find((aFormat) => aFormat.id === id)

  return (
    <div
      ref={ref}
      className={`dropdown is-fullwidth shop-filter has-margin-right-3 ${
        isOpen ? 'is-active' : ''
      }`}
    >
      <div className="dropdown-trigger">
        <button
          onClick={actions.toggle}
          className="button is-rounded is-medium is-fullwidth"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span className="dropdown-value">
            <span
              className={`has-margin-right-2 ${
                selectedFormats.length === 0 ? 'has-text-grey' : ''
              }`}
            >
              <FormattedMessage
                id="shop.FormatFilter.text"
                description="Format text before selected formats in search bar"
                defaultMessage="Format"
              />
            </span>
            <span className="has-text-weight-bold">
              {selectedFormats.map(({ name }, i) => [
                i > 0 && ', ',
                <Fragment key={name}>{name.toLowerCase()}</Fragment>,
              ])}
            </span>
          </span>
          <span className="icon is-small has-margin-left-0">
            <i className="icon icon-carret" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {formats.map(({ id, name }) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={id}
              onClick={() => setValue(xor(value, [id]))}
              className={`dropdown-item ${isFormatSelected(id) ? 'has-text-weight-bold' : ''}`}
            >
              {name}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FormatFilter
