import React, { FC, Fragment } from 'react'
import { useCurrentLanguage } from '../../locale/hooks'
import { useGetPosts } from '../../posts/hooks'
import LoadingPosts from './LoadingPosts'
import Post from './Post'

const LatestPosts: FC = () => {
  const [language] = useCurrentLanguage()
  // eslint-disable-next-line @typescript-eslint/camelcase
  const [request] = useGetPosts({ lang: language, per_page: 4, _embed: true })
  const { data: posts = [], isLoading } = request

  if (isLoading) {
    return <LoadingPosts />
  }

  return (
    <Fragment>
      {posts.map((post, i) => {
        const isFirst = i === 0
        const containerClass = isFirst ? 'is-6-tablet is-8-desktop' : 'is-4'
        const postSize = isFirst ? 'large' : 'medium'

        return (
          <div key={post.id} className={`column ${containerClass}`}>
            <Post key={post.id} {...post} size={postSize} />
          </div>
        )
      })}
    </Fragment>
  )
}

export default LatestPosts
