import React, { FC, Fragment } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import Quickview from '../../quickview/components/Quickview'
import CategoryPicker from './CategoryPicker'

const messages = defineMessages({
  title: {
    id: 'categories.CategoriesPanel.title',
    description: 'Category selector panel title in mobile view',
    defaultMessage: 'Categories',
  },
})

const CategoriesPanel: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <Quickview.Header title={formatMessage(messages.title)} />
      <Quickview.Body>
        <div className="quickview-block">
          <CategoryPicker isMobile />
        </div>
      </Quickview.Body>
    </Fragment>
  )
}
export default CategoriesPanel
