import * as t from './types'
import { AxiosPromise } from 'axios'
import axios from '../api/axios'

export const stripeCheckout = (data: t.StripeCheckoutRequest): AxiosPromise<t.CheckoutResponse> =>
  axios.post<t.CheckoutResponse>('/orreca/v1/checkout/stripe', data)

export const freeCheckout = (data: t.FreeCheckoutRequest): AxiosPromise<t.CheckoutResponse> =>
  axios.post<t.CheckoutResponse>('/orreca/v1/checkout/free', data)

export const createOrder = (
  data: t.PayPalCreateOrderRequest
): AxiosPromise<t.PayPalCreateOrderResponse> =>
  axios.post<t.PayPalCreateOrderResponse>('/orreca/v1/checkout/paypal/orders', data)

export const captureOrder = (
  orderId: string,
  options?: t.PayPalCaptureOrderRequest
): AxiosPromise<t.CheckoutResponse> =>
  axios.post<t.CheckoutResponse>(`/orreca/v1/checkout/paypal/orders/${orderId}/capture`, options)
