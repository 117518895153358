import { useMemo } from 'react'
import { useAxios, useInfiniteScroll } from '../api/hooks'
import { UseAxios, UseInfiniteScroll } from '../api/types'
import { GetTopicQueryParams, GetTopicsQueryParams, Topic } from './types'

export const useGetTopics = (params: GetTopicsQueryParams = {}): UseAxios<Topic[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios({ url: '/orreca/v1/topics', params }, [mParams])
}

export const useGetInfiniteTopics = (params: GetTopicsQueryParams = {}): UseInfiniteScroll<Topic> =>
  useInfiniteScroll({ url: '/orreca/v1/topics', params })

export const useGetTopic = (id: number, params: GetTopicQueryParams = {}): UseAxios<Topic> =>
  useAxios({ url: `/orreca/v1/topics/${id}`, params }, [id])
