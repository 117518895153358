import { AppointmentType, GetAppointmentTypesQueryParams } from './types'
import { UseAxios } from '../api/types'
import { useAxios } from '../api/hooks'
import { useMemo } from 'react'

export const useGetAppointmentTypes = (
  params: GetAppointmentTypesQueryParams = {}
): UseAxios<AppointmentType[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])

  return useAxios<AppointmentType[]>({ url: `/wp/v2/appointment-types`, params }, [mParams])
}
