import React, { FC } from 'react'
import LoadingPost from './LoadingPost'

const LoadingProducts: FC = () => (
  <div className="columns">
    <div className="column is-3">
      <LoadingPost />
    </div>
    <div className="column is-3">
      <LoadingPost />
    </div>
    <div className="column is-3">
      <LoadingPost />
    </div>
    <div className="column is-3">
      <LoadingPost />
    </div>
  </div>
)

export default LoadingProducts
