import React, { FC } from 'react'

interface Props {
  steps: string[]
  activeStep: number
}

const Steps: FC<Props> = (props) => (
  <ul className="steps has-padding-top-4 has-padding-bottom-5">
    {props.steps.map((step, i) => (
      <li key={i} className={`step-item ${i === props.activeStep ? 'is-active' : ''}`}>
        <div className="step-marker">
          <div className="step-details">
            <p className="step-title">{step}</p>
          </div>
        </div>
      </li>
    ))}
  </ul>
)

export default Steps
