import { Form, Formik } from 'formik'
import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactStripeElements, injectStripe } from 'react-stripe-elements'
import { useCart } from '../../cart/hooks'
import Status from '../../formik/components/Status'
import Redirect from '../../router/components/Redirect'
import { useCurrentUser } from '../../user/hooks'
import { freeCheckout, stripeCheckout } from '../api'
import { useInitialValues } from '../hooks'
import { useCheckoutSchema } from '../schema'
import { getCreateSourceOptions } from '../utils'
import Actions from './Actions'
import BillingAddress from './BillingAddress'
import PaymentMethod from './PaymentMethod'
import ShippingAddress from './ShippingAddress'

const messages = defineMessages({
  error: {
    id: 'checkout.CheckoutForm.error',
    description: 'Generic error message for checkout form',
    defaultMessage: 'Something went wrong! Please try again later.',
  },
})

type Props = ReactStripeElements.InjectedStripeProps

const CheckoutForm: FC<Props> = ({ stripe }) => {
  const [currentUser] = useCurrentUser()
  const [cart, { updateCart }] = useCart()
  const [orderKey, setOrderKey] = useState<string | undefined>()
  const { formatMessage } = useIntl()
  const initialValues = useInitialValues()
  const validationSchema = useCheckoutSchema()

  if (!cart) {
    return null
  }

  if (orderKey) {
    return <Redirect to={`/shop/orders/${orderKey}?success=true`} />
  }

  if (cart.items.length === 0) {
    return <Redirect to="/shop" />
  }

  // Allow submission if free purchase or saved card, and has saved addresses
  const isInitialValid =
    (initialValues.paymentMethod.method === 'free' || !initialValues.useOtherPaymentMethod) &&
    !initialValues.useOtherBillingAddress &&
    !initialValues.useOtherShippingAddress

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      isInitialValid={isInitialValid}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          const billingAddress = values.useOtherBillingAddress
            ? values.billingAddress
            : currentUser && currentUser.billingAddress

          if (!billingAddress) {
            throw new Error('Billing address not found')
          }

          let shippingAddress
          if (cart.needsShipping) {
            shippingAddress = values.useOtherShippingAddress
              ? values.shippingAddress
              : currentUser && currentUser.shippingAddress

            if (!shippingAddress) {
              throw new Error('Shipping address not found')
            }
          }

          actions.setStatus(undefined)

          switch (values.paymentMethod.method) {
            case 'stripe':
              if (!stripe) {
                throw new Error('Stripe not initialized')
              }

              let paymentToken

              if (values.useOtherPaymentMethod) {
                const options = getCreateSourceOptions(
                  values.paymentMethod.cardDetails.holder,
                  billingAddress
                )

                const { error, source } = await stripe.createSource(options)
                if (error || !source) {
                  throw new Error('Failed to create Stripe source')
                }

                paymentToken = source.id
              }

              const stripeResponse = await stripeCheckout({
                paymentToken,
                billingAddress,
                shippingAddress,
              })

              setOrderKey(stripeResponse.data.key)
              break
            case 'free':
              const freeResponse = await freeCheckout({
                billingAddress,
                shippingAddress,
              })

              setOrderKey(freeResponse.data.key)
              break
          }

          updateCart()
        } catch (error) {
          actions.setStatus(formatMessage(messages.error))
          actions.setSubmitting(false)
        }
      }}
    >
      {() => (
        <Form>
          {cart.needsShipping && <ShippingAddress />}
          <BillingAddress />
          <Status />
          {cart.needsPayment && <PaymentMethod />}
          <Actions />
        </Form>
      )}
    </Formik>
  )
}

export default injectStripe<{}>(CheckoutForm)
