import React, { FC, Fragment } from 'react'
import FormatFilter from './FormatFilter'
import NameFilter from './NameFilter'
import OrderFilter from './OrderFilter'

const ProductFilter: FC = () => (
  <Fragment>
    <div className="column is-2 has-margin-right-3 is-hidden-touch">
      <NameFilter />
    </div>
    <div className="column is-3 has-margin-right-3 is-hidden-touch">
      <FormatFilter />
    </div>
    <div className="column is-3 has-margin-right-3 is-hidden-touch">
      <OrderFilter />
    </div>
  </Fragment>
)

export default ProductFilter
