import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  url?: string
}

const PreviewButton: FC<Props> = ({ url }) => {
  if (!url) {
    return null
  }

  return (
    <a
      href={url}
      className="button is-medium is-secondary has-text-weight-bold has-margin-left-2"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage
        id="products.PreviewButton.text"
        description="See preview button in product detail view"
        defaultMessage="See preview"
      />
    </a>
  )
}

export default PreviewButton
