import { sum } from 'lodash-es'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { QuickviewType } from '../../quickview/enums'
import { useQuickview } from '../../quickview/hooks'
import { useCart } from '../hooks'

const CartStatus: FC = () => {
  const [cart] = useCart()
  const { open } = useQuickview()

  const hasItems = cart && cart.items.length > 0

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="button cart-button is-medium is-pulled-right"
      onClick={() => open(QuickviewType.Cart)}
    >
      <span className="icon has-margin-right-3">
        <i className="icon icon-cart" />
      </span>
      <span className="has-text-weight-bold is-hidden-mobile">
        <FormattedMessage
          id="cart.CartStatus.text"
          description="Shopping cart in highlighted search bar"
          defaultMessage="Shopping Cart"
        />
      </span>
      <span className={`badge has-margin-left-3 ${hasItems ? 'is-set' : ''}`}>
        {cart ? sum(cart.items.map((item) => item.quantity)) : 0}
      </span>
    </a>
  )
}

export default CartStatus
