import { getNames } from 'i18n-iso-countries'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean } from 'react-hanger/array/useBoolean'
import { useCurrentLanguage } from '../locale/hooks'
import { useQuery } from '../router/hooks'
import * as t from './types'

export const usePagination = (): [number, (page: number) => void] => {
  const [query, setQuery] = useQuery<{ page: string }>()
  const [currentPage, setCurrentPage] = useState(Number(query.page) || 1)

  return [
    currentPage,
    (page: number) => {
      setCurrentPage(page)
      setQuery({ page: String(page) })
    },
  ]
}

export const useKeypress = (key: string): boolean => {
  const [keyPressed, actions] = useBoolean(false)

  useEffect(() => {
    const handleKeyup = (event: KeyboardEvent): void => {
      if (event.key === key) {
        actions.setFalse()
      }
    }

    const handleKeydown = (event: KeyboardEvent): void => {
      if (event.key === key) {
        actions.setTrue()
      }
    }

    window.addEventListener('keydown', handleKeydown)
    window.addEventListener('keyup', handleKeyup)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
      window.removeEventListener('keyup', handleKeyup)
    }
  }, [key, actions])

  return keyPressed
}

export const useClickOutside = (
  ref: React.RefObject<HTMLElement | null>,
  onClickAway: (event: t.PossibleEvent) => void
): void => {
  const savedCallback = useRef(onClickAway)
  const events: t.HandledEvents = ['mousedown', 'touchstart']

  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])

  useEffect(() => {
    const handler = (event: t.PossibleEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        savedCallback.current(event)
      }
    }

    events.forEach((event) => {
      document.addEventListener(event, handler)
    })

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handler)
      })
    }
  }, [events, ref])
}

const cachedScripts: string[] = []

export const useScript = (src: string): [boolean, boolean] => {
  const [state, setState] = useState({ loaded: false, error: false })

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      return setState({ loaded: true, error: false })
    }

    cachedScripts.push(src)

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    script.async = true

    const onScriptLoad = (): void => setState({ loaded: true, error: false })
    const onScriptError = (): void => {
      const index = cachedScripts.indexOf(src)
      if (index >= 0) {
        cachedScripts.splice(index, 1)
      }

      script.remove()
      setState({ loaded: true, error: true })
    }

    script.addEventListener('load', onScriptLoad)
    script.addEventListener('error', onScriptError)

    document.body.appendChild(script)

    return () => {
      script.removeEventListener('load', onScriptLoad)
      script.removeEventListener('error', onScriptError)
    }
  }, [src])

  return [state.loaded, state.error]
}

export const useCountries = (): t.Country[] => {
  const [currentLanguage] = useCurrentLanguage()

  return useMemo(() => {
    const countries = getNames(currentLanguage)
    return Object.keys(countries).map((code) => ({
      code,
      name: countries[code],
    }))
  }, [currentLanguage])
}

export const useRefMounted = (): React.MutableRefObject<boolean> => {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return mounted
}

export const useDebounce = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: () => any,
  ms = 0,
  deps: React.DependencyList = []
): void => {
  useEffect(() => {
    const handle = setTimeout(fn.bind(null, deps), ms)

    return () => {
      clearTimeout(handle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebouncedFn = <Result = any, Args extends any[] = any[]>(
  fn: (...args: Args) => Promise<Result>,
  ms: number
): t.UseDebouncedFn<Result, Args> => {
  const mounted = useRefMounted()
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [state, setState] = useState<t.UseDebouncedFnState<Result>>({
    isLoading: false,
  })

  const callback = useCallback(
    async (...args: Args) => {
      const newTimeoutId = setTimeout(async () => {
        try {
          if (mounted.current) {
            setState({ isLoading: true })
            const result = await fn(...args)
            setState({ isLoading: false, result })
          }
        } catch (error) {
          mounted.current && setState({ isLoading: false, error })
        }
      }, ms)

      if (mounted.current) {
        timeoutId && clearTimeout(timeoutId)
        setTimeoutId(newTimeoutId)
      }
    },
    [fn, mounted, ms, timeoutId]
  )

  return [state, callback]
}

export const useScrollTo = <T extends HTMLElement>(): t.UseScrollTo<T> => {
  const ref = useRef<T>(null)

  const scroll = useCallback(() => {
    ref.current &&
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  }, [ref])

  return [ref, scroll]
}

export const useWindowSize = (initialWidth = Infinity, initialHeight = Infinity): t.WindowSize => {
  const frame = useRef(0)
  const isClient = typeof window === 'object'

  const [size, setSize] = useState<t.WindowSize>({
    width: isClient ? window.innerWidth : initialWidth,
    height: isClient ? window.innerHeight : initialHeight,
  })

  useEffect(() => {
    if (!isClient) {
      return
    }

    const handler = (): void => {
      cancelAnimationFrame(frame.current)
      frame.current = requestAnimationFrame(() => {
        setSize({ width: window.innerWidth, height: window.innerHeight })
      })
    }

    window.addEventListener('resize', handler)

    return () => {
      cancelAnimationFrame(frame.current)
      window.removeEventListener('resize', handler)
    }
  }, [isClient])

  return size
}

export const useEvent = <E extends Event>(type: string, listener: (event: E) => void): void => {
  useEffect(() => {
    window.addEventListener(type, listener as EventListener)

    return () => {
      window.removeEventListener(type, listener as EventListener)
    }
  }, [type, listener])
}
