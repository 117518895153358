import { useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { UseGetVideosQueryParams, Video } from './types'

export const useGetVideo = (id: number): UseAxios<Video> =>
  useAxios<Video>({ url: `/orreca/v1/videos/${id}`, params: { _embed: true } }, [id])

export const useGetVideos = (params: UseGetVideosQueryParams): UseAxios<Video[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<Video[]>({ url: `/orreca/v1/videos`, params }, [mParams])
}
