import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { parsePaginationHeaders } from '../../api/utils'
import { useSelectedCategories } from '../../categories/hooks'
import { Language } from '../../locale/enums'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetVideos } from '../hooks'

const AllVideosSwitch: FC = () => {
  const [query, setQuery] = useQuery<{ allLanguages: string }>()
  const [categories] = useSelectedCategories()
  const [language] = useCurrentLanguage()
  const [request] = useGetVideos({
    categories,
    lang: language === Language.English ? Language.German : Language.English,
  })

  const { headers } = request
  const { total } = parsePaginationHeaders(headers || {})

  if (total < 1) {
    return null
  }

  return (
    <div className="box is-bordered has-padding-4 has-margin-bottom-2">
      <input
        id="allVideosSwitch"
        type="checkbox"
        className="switch is-rounded is-medium"
        checked={query.allLanguages === 'true'}
        onChange={() =>
          setQuery({
            allLanguages: query.allLanguages === 'true' ? undefined : 'true',
          })
        }
      />
      <label htmlFor="allVideosSwitch">
        <FormattedMessage
          id="videos.AllVideosSwitch.foreignVideosCount"
          description="Information message of the foreign videos count on the videos page. For the German translation the translation text should reference the English language"
          defaultMessage="There are {count} videos in German. Show German content."
          values={{ count: total }}
        />
      </label>
    </div>
  )
}

export default AllVideosSwitch
