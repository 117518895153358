import { useContext } from 'react'
import { logout } from '../auth/api'
import { useJwt } from '../auth/hooks'
import { UseState } from '../utils/types'
import { UserContext } from './context'
import * as t from './types'

export const useCurrentUser = (): UseState<t.User | undefined> => {
  const { user, setUser } = useContext(UserContext)
  return [user, setUser]
}

export const useLogout = (): t.UseLogout => {
  const [, setJwt] = useJwt()
  const [, setUser] = useCurrentUser()

  return async () => {
    try {
      await logout()
    } catch (error) {}

    setJwt(undefined)
    setUser(undefined)
  }
}
