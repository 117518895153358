import { useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { Language } from '../locale/enums'
import { GetPagesQueryParams, Page } from './types'

export const useGetPages = (params: GetPagesQueryParams): UseAxios<Page[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<Page[]>({ url: '/orreca/v1/pages', params }, [mParams])
}

export const useGetPage = (id: number): UseAxios<Page> =>
  useAxios<Page>({ url: `/orreca/v1/pages/${id}` }, [id])

export const useGetPrivacyPolicy = (language: Language): UseAxios<Page> =>
  useAxios<Page>({ url: `/orreca/v1/pages/privacy-policy`, params: { lang: language } }, [language])

export const useGetTermsOfService = (language: Language): UseAxios<Page> =>
  useAxios<Page>({ url: `/orreca/v1/pages/terms-of-service`, params: { lang: language } }, [
    language,
  ])
