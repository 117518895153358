import $axios from 'axios'
import { differenceInMinutes, isBefore } from 'date-fns'
import { refreshToken } from '../auth/api'
import { getJwt, removeJwt, storeJwt } from '../auth/utils'
import { getLanguageFromCookie } from '../locale/utils'
import { isDevelopment } from '../utils'

export const CancelToken = $axios.CancelToken
export const isCancel = $axios.isCancel

let isRefreshing = false

const isDev = isDevelopment()

const axios = $axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: isDev ? 0 : 30000,
  withCredentials: isDev,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axios.interceptors.request.use((config) => {
  if (!config.params || !config.params.lang) {
    const language = getLanguageFromCookie()
    if (language) {
      config.headers.Language = language
    }
  }

  return config
})

axios.interceptors.request.use(async (config) => {
  const jwt = getJwt()
  if (!jwt) {
    return config
  }

  const expires = new Date(jwt.token_expires)
  const now = new Date()

  // Remove JWT if it has expired
  if (isBefore(expires, now)) {
    removeJwt()
    return config
  }

  let token = jwt.token

  // Refresh token if we're less than 30 minutes from it expiring
  if (!isRefreshing && differenceInMinutes(expires, now) <= 30) {
    try {
      isRefreshing = true

      // Get new token from the server
      const { data } = await refreshToken(jwt)

      // Store JWT in localStorage
      storeJwt({ ...data, user: jwt.user })

      isRefreshing = false

      // Use new token in the next request
      token = data.token
    } catch (error) {
      isRefreshing = false
      removeJwt()
      return config
    }
  }

  if (config.url !== '/aam/v1/refresh-jwt') {
    config.headers.Authentication = `Bearer ${token}`
  }

  return config
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && 401 === error.response.status && getJwt()) {
      removeJwt()
    }

    return Promise.reject(error)
  }
)

export default axios
