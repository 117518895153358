import React, { FC } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { ModalType } from '../../../modal/enums'
import { useModal } from '../../../modal/hooks'
import { downloadInvoice } from '../../../orders/api'
import { Order as OrderType } from '../../../orders/types'
import { useQuery } from '../../../router/hooks'

const Order: FC<OrderType> = (props) => {
  const [, setQuery] = useQuery<{ orderKey: string }>()
  const { open } = useModal()

  return (
    <div className="panel-block is-order-row">
      <div className="order-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="description"
          onClick={() => {
            setQuery({ orderKey: props.orderKey })
            open(ModalType.OrderDetails)
          }}
        >
          <FormattedMessage
            id="account.ShoppingHistory.Order.orderLink"
            description="Order link in shopping history"
            defaultMessage="Order #{id}"
            values={{ id: props.id }}
          />
        </a>
        <div className="has-margin-x-5">
          <FormattedDate value={props.createdDate} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => downloadInvoice(props.orderKey)}>
          <FormattedMessage
            id="account.ShoppingHistory.Order.invoiceLink"
            description="Invoice link in shopping history"
            defaultMessage="Invoice"
          />
        </a>
      </div>
      <span className="price" dangerouslySetInnerHTML={{ __html: props.totalHtml }} />
    </div>
  )
}

export default Order
