import { useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import * as t from './types'

export const useGetProduct = (id: number): UseAxios<t.Product> =>
  useAxios<t.Product>({ url: `/orreca/v1/products/${id}` }, [id])

export const useGetProducts = (params: t.GetProductsQueryParams): UseAxios<t.Product[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<t.Product[]>({ url: `/orreca/v1/products/`, params }, [mParams])
}

export const useGetFormats = (params: t.GetFormatsQueryParams): UseAxios<t.Format[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<t.Format[]>({ url: '/orreca/v1/product-formats', params }, [mParams])
}
