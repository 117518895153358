import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { messages as generalMessages } from '../formik/messages'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSignupSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      email: yup
        .string()
        .email(formatMessage(generalMessages.invalidEmail))
        .required(requiredMessage),
      lists: yup.array().of(yup.number()).required(requiredMessage),
      agreeToTerms: yup.boolean().oneOf([true]).required(requiredMessage),
    })
  }, [formatMessage])
}
