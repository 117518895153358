import React, { FC } from 'react'
import { List as ListType } from '../types'

type Props = ListType & {
  isSelected?: boolean
  isDisabled?: boolean
  onClick?: (id: number) => void
}

const ListSwitch: FC<Props> = (props) => {
  const id = `list${props.id}`

  return (
    <li className="field">
      <input
        id={id}
        type="checkbox"
        className="switch is-rtl is-rounded is-medium"
        checked={props.isSelected}
        disabled={props.isDisabled}
        readOnly
      />
      <label htmlFor={id} onClick={() => props.onClick && props.onClick(props.id)}>
        {props.name}
      </label>
    </li>
  )
}

export default ListSwitch
