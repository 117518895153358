import { FC, useEffect, useState } from 'react'
import { useCurrentLanguage } from '../../locale/hooks'
import { useCurrentUser } from '../../user/hooks'
import { useScript } from '../../utils/hooks'

const FreshChat: FC = () => {
  const [user] = useCurrentUser()
  const [language] = useCurrentLanguage()
  const [isScriptLoaded] = useScript('https://wchat.freshchat.com/js/widget.js')
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false)

  const email = user ? user.email : undefined

  useEffect(() => {
    if (window.fcWidget) {
      window.fcWidget.user.setLocale(language)
    }
  }, [language])

  useEffect(() => {
    if (window.fcWidget && email) {
      window.fcWidget.user.setProperties({ email })
    }
  }, [email])

  if (!isScriptLoaded || !window.fcWidget) {
    return null
  }

  window.fcWidget.on('widget:loaded', () => {
    setIsWidgetLoaded(true)
  })

  if (!isWidgetLoaded) {
    window.fcWidget.init({
      token: process.env.REACT_APP_FRESHCHAT_TOKEN,
      host: process.env.REACT_APP_FRESHCHAT_HOST,
      externalId: email,
      locale: language,
    })
  }

  return null
}

export default FreshChat
