import { compact } from 'lodash-es'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Venue as VenueType } from '../types'

const Venue: FC<VenueType> = ({ address, zip, city, country, title }) => (
  <div className="column is-12 is-field">
    <label>
      <FormattedMessage
        id="events.Venue.label"
        description="Location label in event page"
        defaultMessage="Location"
      />
    </label>
    <div className="value has-padding-top-2">
      {title} – {compact([address, zip, city, country]).join(', ')}
    </div>
  </div>
)

export default Venue
