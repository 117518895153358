import { Field, Form, Formik } from 'formik'
import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useCategories } from '../../categories/hooks'
import FileField from '../../formik/components/FileField'
import Radios from '../../formik/components/Radios'
import SelectField from '../../formik/components/SelectField'
import Status from '../../formik/components/Status'
import SubmitButton from '../../formik/components/SubmitButton'
import TextArea from '../../formik/components/TextArea'
import TextField from '../../formik/components/TextField'
import Modal from '../../modal/components/Modal'
import { useModal } from '../../modal/hooks'
import Redirect from '../../router/components/Redirect'
import { createTopic } from '../api'
import { useCreateTopicSchema } from '../schema'
import { Topic } from '../types'
import { getTopicUrl } from '../utils'

const messages = defineMessages({
  modalTitle: {
    id: 'topics.CreateTopicForm.modalTitle',
    description: 'New topic form modal title',
    defaultMessage: 'New topic',
  },
  titleLabel: {
    id: 'topics.CreateTopicForm.titleLabel',
    description: 'Label of the title field in new topic form',
    defaultMessage: 'Title',
  },
  categoriesLabel: {
    id: 'topics.CreateTopicForm.categoriesLabel',
    description: 'Label of the categories field in new topic form',
    defaultMessage: 'Categories',
  },
  modeLabel: {
    id: 'topics.CreateTopicForm.modeLabel',
    description: 'Label of the topic mode field in new topic form',
    defaultMessage: 'Topic mode',
  },
  modePublic: {
    id: 'topics.CreateTopicForm.modePublic',
    description: 'Public mode option in new topic form',
    defaultMessage: 'Questions to community',
  },
  modePrivate: {
    id: 'topics.CreateTopicForm.modePrivate',
    description: 'Private mode option in new topic form',
    defaultMessage: 'Questions to RUCON',
  },
  contentLabel: {
    id: 'topics.CreateTopicForm.contentLabel',
    description: 'Label of the content field in new topic form',
    defaultMessage: 'Content',
  },
  attachmentsLabel: {
    id: 'topics.CreateTopicForm.attachmentsLabel',
    description: 'Label of the attachments field in new topic form',
    defaultMessage: 'Attachments',
  },
  generalError: {
    id: 'topics.CreateTopicForm.generalError',
    description: 'General error message in new topic form',
    defaultMessage: "Sorry! We couldn't save your Q&A topic. Please try again later.",
  },
  cancel: {
    id: 'topics.CreateTopicForm.cancel',
    description: 'Cancel button in new topic form',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'topics.CreateTopicForm.submit',
    description: 'Submit button in new topic form',
    defaultMessage: 'Submit topic',
  },
})

const NewTopicForm: FC = () => {
  const { close } = useModal()
  const { formatMessage } = useIntl()
  const [categories] = useCategories()
  const [topic, setTopic] = useState<Topic>()
  const validationSchema = useCreateTopicSchema()

  if (topic) {
    close()
    return <Redirect to={getTopicUrl(topic)} push />
  }

  return (
    <Formik
      initialValues={{
        title: '',
        categories: [],
        content: '',
        isPrivate: undefined,
        files: [],
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          actions.setStatus(undefined)
          actions.setSubmitting(true)
          const { data } = await createTopic(values)
          setTopic(data)
          actions.setSubmitting(false)
        } catch (error) {
          actions.setSubmitting(false)
          actions.setStatus(formatMessage(messages.generalError))
        }
      }}
    >
      {() => (
        <Form>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <Status />
            <div className="columns is-multiline has-padding-top-2 has-padding-bottom-4 has-padding-x-3">
              <div className="column is-12 has-padding-bottom-1">
                <Field
                  name="title"
                  label={formatMessage(messages.titleLabel)}
                  component={TextField}
                />
              </div>
              <div className="column is-12 has-padding-bottom-4">
                <Field
                  isMultiple
                  name="categories"
                  label={formatMessage(messages.categoriesLabel)}
                  component={SelectField}
                  options={categories.map((category) => ({
                    value: category.id,
                    label: category.name,
                  }))}
                />
              </div>
              <div className="column is-12 has-padding-bottom-1">
                <Field
                  name="isPrivate"
                  label={formatMessage(messages.modeLabel)}
                  component={Radios}
                  options={[
                    { value: false, label: formatMessage(messages.modePublic) },
                    { value: true, label: formatMessage(messages.modePrivate) },
                  ]}
                />
              </div>
              <div className="column is-12 has-padding-bottom-1">
                <Field
                  name="content"
                  label={formatMessage(messages.contentLabel)}
                  component={TextArea}
                />
              </div>
              <div className="column is-12 has-padding-bottom-1">
                <Field
                  name="files"
                  accept="image/*, text/*, .pdf, .xlsx, .xls, .doc, .docx, .csv"
                  label={formatMessage(messages.attachmentsLabel)}
                  component={FileField}
                  multiple
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="is-aligned-right">
            <button
              type="button"
              className="button is-text is-medium has-text-weight-bold is-pulled-right"
            >
              {formatMessage(messages.cancel)}
            </button>
            <SubmitButton className="is-pulled-right">
              {formatMessage(messages.submit)}
            </SubmitButton>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default NewTopicForm
