import React, { FC, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import Analytics from './analytics/components/Analytics'
import { useConsents } from './consents/hooks'
import Footer from './footer/components/Footer'
import FreshChat from './freshchat/components/FreshChat'
import Header from './header/components/Header'
import ModalContainer from './modal/components/ModalContainer'
import QuickviewContainer from './quickview/components/QuickviewContainer'
import Redirect from './router/components/Redirect'
import { routes } from './routes'
import LogoutTimer from './user/components/LogoutTimer'

const App: FC = () => {
  const [consents] = useConsents()
  const canTrackUser = consents && consents.statistics

  return (
    <Fragment>
      <Header />
      <div className="bd-main">
        <Switch>
          {routes.map(({ Component, ...route }) => (
            <Route key={route.path} {...route} exact>
              <Component />
            </Route>
          ))}
          <Redirect to="/" />
        </Switch>
      </div>
      <Footer />
      <QuickviewContainer />
      <ModalContainer />
      <LogoutTimer />
      <FreshChat />
      {canTrackUser && <Analytics />}
    </Fragment>
  )
}

export default App
