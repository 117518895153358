import React, { FC } from 'react'
import FormattedDateTime from '../../dates/components/FormattedDateTime'
import Categories from '../../posts/components/Categories'
import SocialTabs from '../../posts/components/SocialTabs'
import Link from '../../router/components/Link'
import { useCurrentUser } from '../../user/hooks'
import { Topic } from '../types'
import { getTopicUrl, isNewTopic, isPrivateTopic } from '../utils'
import TopicTags from './TopicTags'

const TopicListItem: FC<Topic> = (props) => {
  const [currentUser] = useCurrentUser()
  const isPrivate = isPrivateTopic(props)
  const hasLiked = !!(currentUser && currentUser.likes.includes(props.id))

  return (
    <li className={`has-padding-4 ${isPrivate ? 'is-highlighted' : ''}`}>
      <div className="columns">
        <div className="column">
          <Link to={getTopicUrl(props)} className="is-title">
            {props.title}
            <TopicTags isNew={isNewTopic(props)} isPrivate={isPrivate} />
          </Link>
        </div>
        <div className="column is-narrow has-padding-right-4">
          <div className="date">
            <FormattedDateTime value={props.modified} />
          </div>
        </div>
      </div>
      <Categories categories={props.categories} className="has-margin-top-3 has-margin-bottom-3" />
      <SocialTabs
        isCompact
        canLike={!!currentUser}
        postId={props.id}
        likes={props.likes}
        hasLiked={hasLiked}
        comments={props.comments}
      />
    </li>
  )
}

export default TopicListItem
