import React, { FC } from 'react'
import CartStatus from '../../cart/components/CartStatus'
import FilterBurger from './FilterBurger'
import ProductFilter from './ProductFilter'

interface Props {
  hideFilter?: boolean
}

const SearchBar: FC<Props> = ({ hideFilter = false }) => (
  <div className="shop-bar has-padding-y-3 is-clearfix">
    <div className="container">
      <div className="columns is-gapless">
        {!hideFilter && <ProductFilter />}
        <div className="column">
          {!hideFilter && <FilterBurger />}
          <CartStatus />
        </div>
      </div>
    </div>
  </div>
)

export default SearchBar
