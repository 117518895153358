import classNames from 'classnames'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import Comments from '../../comments/components/Comments'
import SubscribeBlock from '../../newsletter/components/SubscribeBlock'
import CoverImage from '../../posts/components/CoverImage'
import Gallery from '../../posts/components/Gallery'
import PostMeta from '../../posts/components/PostMeta'
import PremiumBlock from '../../posts/components/PremiumBlock'
import SignupBlock from '../../posts/components/SignupBlock'
import SocialTabs from '../../posts/components/SocialTabs'
import { useGetPost, useGetPostPreview } from '../../posts/hooks'
import { getMetaDescription, getPostUrl } from '../../posts/utils'
import Redirect from '../../router/components/Redirect'
import { useQuery } from '../../router/hooks'
import { useCurrentUser } from '../../user/hooks'
import { isSubscriber } from '../../user/utils'
import { useScrollTo } from '../../utils/hooks'
import LoadingArticle from './LoadingArticle'

const messages = defineMessages({
  premiumBlockTitle: {
    id: 'posts.ArticlePage.premiumBlockTitle',
    description: 'Premium block title in post detailed view',
    defaultMessage: 'This is a premium article.',
  },
  premiumBlockDescription: {
    id: 'posts.ArticlePage.premiumBlockDescription',
    description: 'Premium block description in post detailed view',
    defaultMessage:
      'To access this one and other paid content you need to sign up and subscribe for a plan.',
  },
  premiumBlockButtonText: {
    id: 'posts.ArticlePage.premiumBlockButtonText',
    description: 'Premium block subscribe button text in post detailed view',
    defaultMessage: 'Subscribe now',
  },
  signupBlockTitle: {
    id: 'posts.ArticlePage.signupBlockTitle',
    description: 'Signup block title in post detailed view',
    defaultMessage: 'This is a premium article.',
  },
  signupBlockDescription: {
    id: 'posts.ArticlePage.signupBlockDescription',
    description: 'Signup block description in post detailed view',
    defaultMessage: 'To access this one and other paid content you need to subscribe for a plan.',
  },
})

const ArticlePage: FC = () => {
  const { formatMessage } = useIntl()
  const [currentUser] = useCurrentUser()
  const [query] = useQuery<{ preview: string }>()
  const params = useParams<{ id: string; slug: string }>()
  const hasSubscription = isSubscriber(currentUser)
  const [commentsRef, scrollToComments] = useScrollTo<HTMLDivElement>()
  // Previews use a different endpoint, therefore we need to use another hook
  const useGetPostHook = query.preview ? useGetPostPreview : useGetPost
  // Subscriber status is a dependency, as we need to refresh the post in case subscription status changes
  // This is because the API returns partial premium content for non-subscribers
  const [request] = useGetPostHook(
    +params.id,
    // eslint-disable-next-line @typescript-eslint/camelcase
    { _embed: true, skip_cache: hasSubscription ? 1 : undefined },
    [hasSubscription]
  )
  const { data: post, isLoading } = request

  if (isLoading) {
    return <LoadingArticle />
  }

  if (!post) {
    return <Redirect to="/news" />
  }

  if (post.id === +params.id && params.slug !== post.slug) {
    return <Redirect to={getPostUrl(post)} />
  }

  const restrictContent = post.crb_premium && !hasSubscription
  const showGallery = !restrictContent
  const showPremiumBlock = restrictContent && currentUser
  const showSignupBlock = restrictContent && !currentUser
  const canComment = !restrictContent && post.comment_status === 'open'

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{post.title.rendered}</title>
        <meta name="description" content={getMetaDescription(post)} />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column is-8">
            <div className="news-head has-padding-4">
              <h1 className="title is-2 has-margin-bottom-3">{post.title.rendered}</h1>
              <PostMeta {...post} />
              <CoverImage {...post} />
            </div>
            <SocialTabs
              postId={post.id}
              likes={post.likes}
              comments={post.comments}
              canLike={!!(currentUser && !restrictContent)}
              hasLiked={!!(currentUser && currentUser.likes.includes(post.id))}
              onCommentsClick={scrollToComments}
            />
            <div
              className={classNames(['article-content', restrictContent && 'is-premium'])}
              dangerouslySetInnerHTML={{ __html: post.content.rendered }}
            />
            {showPremiumBlock && (
              <PremiumBlock
                title={formatMessage(messages.premiumBlockTitle)}
                subtitle={formatMessage(messages.premiumBlockDescription)}
                buttonText={formatMessage(messages.premiumBlockButtonText)}
                icon="crown"
              />
            )}
            {showSignupBlock && (
              <SignupBlock
                title={formatMessage(messages.signupBlockTitle)}
                description={formatMessage(messages.signupBlockDescription)}
                icon="crown"
              />
            )}
            {showGallery && <Gallery images={post.gallery} />}
            <Comments containerRef={commentsRef} canComment={canComment} postId={post.id} />
          </div>
          <SubscribeBlock />
        </div>
      </div>
    </section>
  )
}

export default ArticlePage
