import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingEvents: FC = () => (
  <div className="column is-6-tablet is-4-desktop has-margin-bottom-6-tablet">
    <Skeleton height={525} />
  </div>
)

export default LoadingEvents
