import { Form, Formik } from 'formik'
import { compact } from 'lodash-es'
import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactStripeElements, injectStripe } from 'react-stripe-elements'
import Steps from '../../../bulma/components/Steps'
import { useCart } from '../../../cart/hooks'
import { stripeCheckout } from '../../../checkout/api'
import { getCreateSourceOptions } from '../../../checkout/utils'
import Status from '../../../formik/components/Status'
import Modal from '../../../modal/components/Modal'
import { getUser } from '../../../user/api'
import { useCurrentUser } from '../../../user/hooks'
import { useInitialValues } from '../../hooks'
import { useSubscriptionSchema } from '../../schema'
import BillingStep from './BillingStep'
import Buttons from './Buttons'
import PaymentStep from './PaymentStep'
import PlanStep from './PlanStep'
import SuccessStep from './SuccessStep'

const messages = defineMessages({
  modalTitle: {
    id: 'subscriptions.SubscriptionForm.modalTitle',
    description: 'Subscription modal title',
    defaultMessage: 'Subscribe for a plan',
  },
  planStep: {
    id: 'subscriptions.SubscriptionForm.planStep',
    description: 'Choose a plan step text in subscription modal',
    defaultMessage: 'Choose a plan',
  },
  billingStep: {
    id: 'subscriptions.SubscriptionForm.billingStep',
    description: 'Billing details step text in subscription modal',
    defaultMessage: 'Billing details',
  },
  paymentStep: {
    id: 'subscriptions.SubscriptionForm.paymentStep',
    description: 'Payment method step text in subscription modal',
    defaultMessage: 'Payment method',
  },
  purchaseFailed: {
    id: 'subscriptions.SubscriptionForm.purchaseFailed',
    description: 'General error message for subscription purchase failure',
    defaultMessage: "Sorry! We couldn't process your payment. Please try again later.",
  },
})

const steps = [
  { name: messages.planStep, component: PlanStep },
  { name: messages.billingStep, component: BillingStep },
  { name: messages.paymentStep, component: PaymentStep },
  { name: undefined, component: SuccessStep },
]

const stepMessages = compact(steps.map((step) => step.name))

type Props = ReactStripeElements.InjectedStripeProps

const SubscriptionForm: FC<Props> = ({ stripe }) => {
  const [currentUser, setCurrentUser] = useCurrentUser()
  const [, { addItem, updateCart }] = useCart()
  const { formatMessage } = useIntl()
  const validationSchema = useSubscriptionSchema()
  const initialValues = useInitialValues()

  if (!currentUser) {
    return null
  }

  return (
    <Formik
      isInitialValid={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, form) => {
        try {
          if (!stripe) {
            throw new Error('Stripe not initialized')
          }

          form.setStatus(undefined)

          const cardHolder = values.paymentMethod.cardDetails.holder
          const billingAddress = values.billingAddress

          const options = getCreateSourceOptions(cardHolder, billingAddress)

          await addItem(values.plan)

          const { error, source } = await stripe.createSource(options)
          if (error || !source) {
            throw new Error('Failed to create Stripe source')
          }

          await stripeCheckout({
            paymentToken: source.id,
            billingAddress,
            intent: 'subscribe',
          })

          const { data: user } = await getUser(currentUser.id)
          setCurrentUser(user)

          await updateCart()

          form.setFieldValue('step', values.step + 1)
          form.setSubmitting(false)
        } catch (error) {
          form.setStatus(formatMessage(messages.purchaseFailed))
          form.setSubmitting(false)
        }
      }}
    >
      {({ values: { step } }) => {
        const stepNames = stepMessages.map((msg) => formatMessage(msg))
        const CurrentStep = steps[step - 1].component
        const isSuccessStep = step === 4

        return (
          <Form noValidate>
            <Modal.Header title={formatMessage(messages.modalTitle)} />
            <Modal.Body>
              {!isSuccessStep && <Steps steps={stepNames} activeStep={step - 1} />}
              <Status />
              <CurrentStep />
            </Modal.Body>
            <Modal.Footer className={`is-aligned-right ${isSuccessStep ? 'is-borderless' : ''}`}>
              {!isSuccessStep && <Buttons />}
            </Modal.Footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default injectStripe<{}>(SubscriptionForm)
