import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  value: number
  onChange: (quantity: number) => void
  isDisabled?: boolean
  hasLabel?: boolean
  min?: number
  max?: number
}

const QuantityPicker: FC<Props> = ({
  value,
  onChange,
  min = 0,
  max,
  hasLabel = false,
  isDisabled,
}) => {
  const canDecrease = !isDisabled && (min === undefined || value > min)
  const canIncrease = !isDisabled && (max === undefined || value < max)

  return (
    <div className="qty">
      {hasLabel && (
        <label>
          <FormattedMessage
            id="product.QuantityPicker.label"
            description="Label of product quantity picker"
            defaultMessage="Quantity"
          />
        </label>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`decrease ${!canDecrease ? 'is-disabled' : ''}`}
        onClick={() => canDecrease && onChange(value - 1)}
      >
        <FormattedMessage
          id="product.QuantityPicker.decrease"
          description="Decrease button in product quantity picker"
          defaultMessage="Decrease"
        />
      </a>
      <input type="number" value={value} readOnly />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`increase ${!canIncrease ? 'is-disabled' : ''}`}
        onClick={() => canIncrease && onChange(value + 1)}
      >
        <FormattedMessage
          id="product.QuantityPicker.increase"
          description="Increase button in product quantity picker"
          defaultMessage="Increase"
        />
      </a>
    </div>
  )
}

export default QuantityPicker
