import React, { FC, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { useCart } from '../../cart/hooks'
import Redirect from '../../router/components/Redirect'
import SearchBar from '../../shop/components/SearchBar'
import { useScript } from '../../utils/hooks'
import CheckoutDetails from './CheckoutDetails'
import CheckoutForm from './CheckoutForm'

const messages = defineMessages({
  title: {
    defaultMessage: 'Checkout',
    description: 'Page title for checkout page',
    id: 'checkout.CheckoutPage.title',
  },
})

const CheckoutPage: FC = () => {
  const { formatMessage } = useIntl()
  const [cart] = useCart()
  const [isLoaded] = useScript('https://js.stripe.com/v3')
  if (!cart || !isLoaded) {
    return null
  }

  if (cart.items.length === 0) {
    return <Redirect to="/shop" />
  }

  return (
    <Fragment>
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <SearchBar hideFilter />
      <div className="section has-padding-top-4 has-padding-top-5-tablet">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-6 is-offset-3 has-margin-bottom-6-tablet">
              <CheckoutDetails cart={cart} />
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                <Elements>
                  <CheckoutForm />
                </Elements>
              </StripeProvider>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CheckoutPage
