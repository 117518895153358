import React, { FC, Fragment } from 'react'
import LoadingEventListItem from './LoadingEventListItem'

const LoadingEventList: FC = () => (
  <Fragment>
    <LoadingEventListItem />
    <LoadingEventListItem />
    <LoadingEventListItem />
  </Fragment>
)

export default LoadingEventList
