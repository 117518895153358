import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { messages as generalMessages } from '../formik/messages'

export const useConsentSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      basic: yup.boolean().oneOf([true]),
      comfort: yup.boolean().when('statistics', {
        is: true,
        then: yup.boolean().oneOf([true]),
      }),
      statistics: yup.boolean().required(requiredMessage),
    })
  }, [formatMessage])
}
