import { stringify } from 'query-string'
import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { SearchQueryParams } from '../../search/types'
import { useDebounce } from '../../utils/hooks'

const messages = defineMessages({
  inputPlaceholder: {
    id: 'header.SearchField.inputPlaceholder',
    description: "Search input's placeholder text in the page header.",
    defaultMessage: 'Search for topics or keywords',
  },
  inputLabel: {
    id: 'header.SearchField.inputLabel',
    description: "Search input's label in the page header.",
    defaultMessage: 'Search',
  },
})

const SearchField: FC = () => {
  const { formatMessage } = useIntl()
  const [query, setQuery] = useQuery<SearchQueryParams>()
  const [search, setSearch] = useState(query.search || '')
  const history = useHistory()
  const [language] = useCurrentLanguage()
  const match = useRouteMatch({ path: '/(en|de)/search' })

  useDebounce(
    () => {
      if (search.length > 2) {
        if (!match) {
          history.replace({
            pathname: `/${language}/search`,
            search: stringify({ search: search }),
          })
        } else {
          setQuery({
            page: undefined,
            search: search || undefined,
          })
        }
      }
    },
    500,
    [search]
  )

  return (
    <div className="field has-margin-bottom-0">
      <p className="control has-icons-right">
        <input
          className="input is-medium search-input"
          type="text"
          aria-label={formatMessage(messages.inputLabel)}
          placeholder={formatMessage(messages.inputPlaceholder)}
          onChange={(event) => setSearch(event.target.value)}
          value={search}
        />
        <span className="icon is-medium is-right">
          <i className="icon icon-search" />
        </span>
      </p>
    </div>
  )
}

export default SearchField
