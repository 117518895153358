import React, { FC, Fragment } from 'react'
import LoadingNewsListItem from './LoadingNewsListItem'

const LoadingNewsList: FC = () => (
  <Fragment>
    <LoadingNewsListItem />
    <LoadingNewsListItem />
    <LoadingNewsListItem />
    <LoadingNewsListItem />
  </Fragment>
)

export default LoadingNewsList
