import React, { FC, Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingEventListItem: FC = () => (
  <Fragment>
    <h2 className="title is-5 has-margin-top-5 has-margin-bottom-3">
      <Skeleton width="20%" />
    </h2>
    <div>
      <Skeleton />
      <h3 className="title has-margin-y-0 has-padding-0">
        <Skeleton width="75%" />
      </h3>
      <div className="is-hidden-touch">
        <Skeleton width="45%" />
      </div>
    </div>
  </Fragment>
)

export default LoadingEventListItem
