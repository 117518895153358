import React, { FC, Fragment } from 'react'
import SearchBar from '../../shop/components/SearchBar'
import Product from './Product'

const ProductPage: FC = () => (
  <Fragment>
    <SearchBar hideFilter />
    <Product />
  </Fragment>
)

export default ProductPage
