import React, { FC } from 'react'
import { useQuickview } from '../../quickview/hooks'
import { QuickviewType } from '../../quickview/enums'
import { FormattedMessage } from 'react-intl'

const FilterBurger: FC = () => {
  const { open } = useQuickview()

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={() => open(QuickviewType.ShopFilter)}
      className="button cart-button is-medium is-pulled-left has-margin-left-3 is-hidden-desktop"
    >
      <i className="icon icon-light-hamburger-menu has-margin-right-3" />
      <span>
        <FormattedMessage
          id="shop.FilterBurger.button"
          description="Button that opens product filter in mobile view"
          defaultMessage="Shop filter"
        />
      </span>
    </a>
  )
}

export default FilterBurger
