import React, { FC, Fragment } from 'react'
import { parsePaginationHeaders } from '../../api/utils'
import Pagination from '../../bulma/components/Pagination/Pagination'
import { useSelectedCategories } from '../../categories/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import { useGetPosts } from '../../posts/hooks'
import { PostsFilterQueryParams } from '../../posts/types'
import { useQuery } from '../../router/hooks'
import LoadingNewsList from './LoadingNewsList'
import NewsListItem from './NewsListItem'

const NewsList: FC = () => {
  const [language] = useCurrentLanguage()
  const [categories] = useSelectedCategories()
  const [query, setQuery] = useQuery<PostsFilterQueryParams>()
  const page = Number(query.page) || 1
  const [request] = useGetPosts({
    ...query,
    page,
    lang: language,
    categories,
    _embed: true,
  })

  const { data: posts = [], isLoading, isInitial } = request

  if (isInitial && isLoading) {
    return <LoadingNewsList />
  }

  const { totalPages } = parsePaginationHeaders(request.headers || {})
  const showPagination = posts.length > 0 && totalPages > 1

  // Attempt to reconcile the error by redirecting to the first page
  if (request.isError && page !== 1) {
    setQuery({ page: '1' })
  }

  return (
    <Fragment>
      {posts.map((post) => (
        <NewsListItem key={post.id} {...post} />
      ))}
      {showPagination && <Pagination pages={totalPages} />}
    </Fragment>
  )
}

export default NewsList
