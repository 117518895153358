import React, { FC, Fragment } from 'react'
import { parsePaginationHeaders } from '../../api/utils'
import Pagination from '../../bulma/components/Pagination'
import { useSelectedCategories } from '../../categories/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetVideos } from '../hooks'
import { VideosFilterQueryParams } from '../types'
import LoadingVideoList from './LoadingVideoList'
import VideoListItem from './VideoListItem'

interface Props {
  perPage: number
  hidePagination?: boolean
}

const VideoList: FC<Props> = ({ perPage, hidePagination }) => {
  const [language] = useCurrentLanguage()
  const [categories] = useSelectedCategories()
  const [query] = useQuery<VideosFilterQueryParams>()
  const [videosRequest] = useGetVideos({
    categories,
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: perPage,
    _embed: true,
    page: Number(query.page) || 1,
    lang: query.allLanguages === 'true' ? 'all' : language,
  })

  const { data: videos = [], isLoading, isInitial } = videosRequest

  if (isInitial && isLoading) {
    return <LoadingVideoList />
  }

  const { totalPages } = parsePaginationHeaders(videosRequest.headers || {})
  const paginationVisible = !hidePagination && videos.length > 0 && totalPages > 1

  return (
    <Fragment>
      <div className="columns is-multiline has-margin-top-3">
        {videos.map((video) => (
          <VideoListItem key={video.id} {...video} />
        ))}
      </div>
      {paginationVisible && <Pagination pages={totalPages} />}
    </Fragment>
  )
}

export default VideoList
