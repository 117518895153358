import { Field, FieldProps, Form, Formik } from 'formik'
import { get } from 'lodash-es'
import QRCode from 'qrcode.react'
import React, { FC, Fragment, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import ErrorMessage from '../../../formik/components/ErrorMessage'
import SubmitButton from '../../../formik/components/SubmitButton'
import Modal from '../../../modal/components/Modal'
import { useModal } from '../../../modal/hooks'
import { enableTwoFactor } from '../../../user/api'
import { useCurrentUser } from '../../../user/hooks'
import { randomString } from '../../../utils'
import { useTwoFactorSchema } from '../../schema'

const messages = defineMessages({
  modalTitle: {
    id: 'account.Security.TwoFactorForm.modalTitle',
    description: 'Two factor form modal title',
    defaultMessage: 'Enable two-factor authentication',
  },
  codePlaceholder: {
    id: 'account.Security.TwoFactorForm.codePlaceholder',
    description: 'Authentication code placeholder in two factor form modal',
    defaultMessage: 'Enter authentication code',
  },
  codeError: {
    id: 'account.Security.TwoFactorForm.codeError',
    description: 'Invalid code error message in two factor form modal',
    defaultMessage: 'The entered authentication code is invalid.',
  },
  generalError: {
    id: 'account.Security.TwoFactorForm.generalError',
    description: 'General error message in two factor form modal',
    defaultMessage: "Sorry! We couldn't enable two factor authentication! Please try again later.",
  },
})

const TwoFactorForm: FC = () => {
  const { close } = useModal()
  const { formatMessage } = useIntl()
  const [user, setUser] = useCurrentUser()
  const validationSchema = useTwoFactorSchema()
  const secret = useMemo(() => randomString(16), [])
  if (!user) {
    return null
  }

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
      onSubmit={async ({ code: totp }, form) => {
        try {
          const response = await enableTwoFactor(user.id, { secret, totp })
          setUser(response.data)
          form.setSubmitting(false)
          close()
        } catch (error) {
          const errorCode = get(error, 'response.data.code')
          const invalidCode = errorCode === '2fa_verify_failure'
          const msg = invalidCode ? messages.codeError : messages.generalError
          form.setFieldError('code', formatMessage(msg))
          form.setSubmitting(false)
        }
      }}
    >
      {() => (
        <Form>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <p className="has-margin-bottom-4">
              <FormattedMessage
                id="account.Security.TwoFactorForm.instructions"
                description="Instructions text in two factor form modal"
                defaultMessage="Scan the QR code using Google Authenticator. It will display a 6 digit code which you need to enter below."
              />
            </p>
            <div className="level">
              <div className="level-item">
                <QRCode
                  size={185}
                  value={`otpauth://totp/ORRECA:${user.email}?secret=${secret}&issuer=ORRECA`}
                />
              </div>
            </div>
            <Field name="code">
              {(props: FieldProps) => {
                const hasError = props.form.errors[props.field.name]

                return (
                  <Fragment>
                    <div className="field is-grouped">
                      <p className="control is-expanded">
                        <input
                          {...props.field}
                          type="text"
                          maxLength={6}
                          className={`input ${hasError ? 'is-danger' : ''}`}
                          placeholder={formatMessage(messages.codePlaceholder)}
                        />
                      </p>
                      <SubmitButton color="link" className="has-text-weight-bold">
                        <FormattedMessage
                          id="account.Security.TwoFactorForm.submit"
                          description="Submit button text in two factor form modal"
                          defaultMessage="Submit"
                        />
                      </SubmitButton>
                    </div>
                    <ErrorMessage name={props.field.name} />
                  </Fragment>
                )
              }}
            </Field>
          </Modal.Body>
          <Modal.Footer className="is-borderless" />
        </Form>
      )}
    </Formik>
  )
}

export default TwoFactorForm
