import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLogout } from '../hooks'

const LogoutButton: FC = () => {
  const logout = useLogout()

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="dropdown-item has-text-weight-bold" onClick={logout}>
      <FormattedMessage
        id="user.LogoutButton.text"
        defaultMessage="Log out"
        description="Log out button text"
      />
    </a>
  )
}

export default LogoutButton
