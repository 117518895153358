import React, { FC, useState } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import Spinner from '../../../bulma/components/Spinner'
import { downloadInvoice } from '../../../orders/api'
import { useGetPaginatedOrders } from '../../../orders/hooks'
import { useCurrentUser } from '../../../user/hooks'

const History: FC = () => {
  const [currentUser] = useCurrentUser()
  const [page, setPage] = useState(0)
  const [request] = useGetPaginatedOrders({
    userId: currentUser ? currentUser.id : 0,
    type: 'subscription',
  })

  const { items = [], total, isLoading } = request
  // Subscription filtered response returns all orders at once
  // Therefore we need to page the response in the front-end
  const orders = items.slice(0, (page + 1) * 5)
  const itemsLeft = total - orders.length
  const hasMore = itemsLeft > 0

  return (
    <div className="panel">
      <p className="panel-heading">
        <FormattedMessage
          id="account.Billing.History.heading"
          description="Billing history heading in user preferences"
          defaultMessage="Billing history"
        />
      </p>
      {orders.map((order) => (
        <div key={order.id} className="panel-block is-order-row">
          <div className="order-item">
            <span className="description">
              <FormattedDate value={order.createdDate} year="numeric" month="long" day="2-digit" />
            </span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}{' '}
            <a onClick={() => downloadInvoice(order.orderKey)}>
              <FormattedMessage
                id="account.Billing.History.invoice"
                description="Invoice download link in billing history"
                defaultMessage="Invoice"
              />
            </a>
          </div>
          <span className="price" dangerouslySetInnerHTML={{ __html: order.totalHtml }} />
        </div>
      ))}
      {(hasMore || isLoading) && (
        <div className="panel-block is-order-row has-padding-y-0">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => setPage(page + 1)} className="card-footer-item has-text-weight-bold">
            {isLoading ? (
              <Spinner />
            ) : (
              <FormattedMessage
                id="account.Billing.History.loadMore"
                description="Load more orders button in billing history"
                defaultMessage="More invoices {count}"
                values={{
                  count: (
                    <span className="group-count tag is-light has-text-weight-bold has-margin-left-3 has-padding-y-0 has-padding-x-3">
                      {itemsLeft}
                    </span>
                  ),
                }}
              />
            )}
          </a>
        </div>
      )}
    </div>
  )
}

export default History
