import { useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { useCurrentUser } from '../user/hooks'
import { GetPlansQueryParams, Plan, SubscriptionFormValues } from './types'

export const useGetPlans = (params: GetPlansQueryParams): UseAxios<Plan[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<Plan[]>({ url: '/orreca/v1/plans', params }, [mParams])
}

export const useInitialValues = (): SubscriptionFormValues => {
  const [currentUser] = useCurrentUser()

  const billingAddress =
    currentUser && currentUser.subscription && currentUser.subscription.billingAddress

  return {
    step: 1,
    plan: 0,
    billingAddress: {
      firstName: (billingAddress && billingAddress.firstName) || '',
      lastName: (billingAddress && billingAddress.lastName) || '',
      company: (billingAddress && billingAddress.company) || '',
      vatNo: (billingAddress && billingAddress.vatNo) || '',
      address1: (billingAddress && billingAddress.address1) || '',
      city: (billingAddress && billingAddress.city) || '',
      state: (billingAddress && billingAddress.state) || '',
      postcode: (billingAddress && billingAddress.postcode) || '',
      country: (billingAddress && billingAddress.country) || '',
      email: (billingAddress && billingAddress.email) || '',
      phone: (billingAddress && billingAddress.phone) || '',
    },
    paymentMethod: {
      cardDetails: {
        holder: '',
        cvcValid: false,
        expirationDateValid: false,
        numberValid: false,
      },
    },
  }
}
