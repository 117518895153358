import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  en: {
    id: 'locale.language.en',
    description: 'The English language',
    defaultMessage: 'English',
  },
  de: {
    id: 'locale.language.de',
    description: 'The German language',
    defaultMessage: 'German',
  },
})
