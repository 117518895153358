import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  isNew: boolean
  isPrivate: boolean
}

const TopicTags: FC<Props> = ({ isNew, isPrivate }) => (
  <span className="has-margin-left-2">
    {isNew && (
      <span className="tag is-medium is-uppercase is-new is-rounded has-margin-right-2 is-link">
        <FormattedMessage
          id="topics.TopicTags.new"
          description="New tag in topic list"
          defaultMessage="New"
        />
      </span>
    )}
    {isPrivate && (
      <span className="tag is-private is-medium is-rounded has-margin-right-2">
        <i className="icon-private" />
        <FormattedMessage
          id="topics.TopicTags.private"
          description="Private tag in topic list"
          defaultMessage="Private"
        />
      </span>
    )}
  </span>
)

export default TopicTags
