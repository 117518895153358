import { sumBy, toInteger } from 'lodash-es'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { messages as generalMessages } from '../formik/messages'
import { TimePreference } from './enums'

const maxFilesSizeMb = process.env.REACT_APP_FILES_UPLOAD_SIZE_LIMIT_MB
const maxFilesSizeKb = toInteger(maxFilesSizeMb) * 1024 * 1024

const messages = {
  fileSizeExceeded: {
    id: 'appointments.schema.fileSizeExceeded',
    description: 'Error message for when the user has added files larger than expected',
    defaultMessage: 'Total size of the attachments can not be over 10Mb',
  },
  invalidFile: {
    id: 'appointments.schema.invalidFile',
    description:
      'Error message for when an uploaded file is missing some required values for some reason.',
    defaultMessage: 'Invalid file uploaded',
  },
  invalidTime: {
    id: 'appointments.schema.invalidTime',
    description:
      'Error message for when the selected time preference is not from the allowed values.',
    defaultMessage: 'Invalid time preference selected',
  },
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAppointmentSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      type: yup.number().min(1, requiredMessage).required(requiredMessage),
      description: yup.string().required(requiredMessage),
      time: yup
        .string()
        .required(requiredMessage)
        .oneOf(Object.values(TimePreference), formatMessage(messages.invalidTime)),
      specifyTime: yup.string().when('time', {
        is: TimePreference.Custom,
        then: yup.string().required(requiredMessage),
        otherwise: yup.string().notRequired(),
      }),
      files: yup
        .array<File>()
        .test(
          'hasNotExceededFileSize',
          formatMessage(messages.fileSizeExceeded),
          (files) => sumBy(files, (file) => file.size) <= maxFilesSizeKb
        ),
      captcha: yup.string().required(requiredMessage),
    })
  }, [formatMessage])
}
