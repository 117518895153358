import React, { FC } from 'react'

interface Props {
  priceHtml: string
}

const Price: FC<Props> = ({ priceHtml }) => (
  <div className="price" dangerouslySetInnerHTML={{ __html: priceHtml }} />
)

export default Price
