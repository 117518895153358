import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import logo from '../../assets/img/logo_rucon.jpg'

const Details: FC = () => (
  <div className="content has-text-centered">
    <div className="container">
      <div className="columns">
        <div className="column is-2 has-padding-top-3 has-padding-bottom-4">
          <img src={logo} alt="Rucon Gruppe" />
        </div>
        <div className="column is-5 has-padding-top-3 has-text-center-mobile has-text-left-tablet footer-contacts">
          <h4 className="title is-5">
            <FormattedMessage
              id="footer.Details.company"
              description="Company name in site footer"
              defaultMessage="Rucon Gruppe"
            />
          </h4>
          <p>
            <FormattedMessage
              id="footer.Details.address"
              description="Company address in site footer"
              defaultMessage="Neumeyerstrasse 48, 90411, Nürnberg, Germany"
            />
          </p>
          <p>
            <FormattedMessage
              id="footer.Details.phone"
              description="Company phone in site footer"
              defaultMessage="Phone: +49 911/47 75 28-0"
            />
          </p>
          <p>
            <FormattedMessage
              id="footer.Details.email"
              description="Company email in site footer"
              defaultMessage="E-Mail: mail@rucon-gruppe.de"
            />
          </p>
        </div>
        <div className="column is-5 has-padding-y-0">
          <nav className="nav">
            <div className="nav-center social-icons">
              <a
                href="https://www.facebook.com/Orreca-410427433058779/"
                className="nav-item"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="icon icon-facebook">Facebook</span>
              </a>
              <a
                href="https://www.linkedin.com/showcase/orreca"
                className="nav-item"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="icon icon-linkedin">LinkedIn</span>
              </a>

              <a
                href="https://twitter.com/Orreca1"
                className="nav-item"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="icon icon-twitter">Twitter</span>
              </a>
              {/* <a href="#" className="nav-item" target="_blank" rel="noreferrer noopener">
                <span className="icon icon-instagram">Instagram</span>
              </a> */}
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
)

export default Details
