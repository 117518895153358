import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingProductListItem: FC = () => (
  <div className="shop-item card is-shadowless is-borderless is-transparent has-margin-y-0 has-padding-y-0 has-padding-x-0 has-margin-bottom-4">
    <div className="card-image">
      <div className="is-hidden-mobile">
        <Skeleton height={325} />
      </div>
      <div className="is-hidden-tablet">
        <Skeleton height={280} />
      </div>
    </div>
    <div className="card-content has-padding-x-0 has-padding-bottom-0">
      <div className="content">
        <h2 className="title is-5 has-margin-y-0 has-text-weight-bold">
          <Skeleton />
        </h2>
        <div className="has-margin-y-2">
          <Skeleton width="25%" />
        </div>
        <p className="description has-margin-bottom-2">
          <Skeleton width="85%" />
          <Skeleton width="70%" />
          <Skeleton width="75%" />
        </p>
      </div>
    </div>
  </div>
)

export default LoadingProductListItem
