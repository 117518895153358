import { JWT, JWTEvent } from './types'

const LOCAL_STORAGE_JWT_KEY = 'orreca_jwt'

export const storeJwt = (jwt?: JWT): void => {
  localStorage.setItem(LOCAL_STORAGE_JWT_KEY, JSON.stringify(jwt))
  dispatchJwtEvent(jwt)
}

export const getJwt = (): JWT | undefined => {
  const jwt = localStorage.getItem(LOCAL_STORAGE_JWT_KEY)
  return jwt ? JSON.parse(jwt) : undefined
}

export const removeJwt = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_JWT_KEY)
  dispatchJwtEvent(undefined)
}

const dispatchJwtEvent = (jwt?: JWT): void => {
  const event: JWTEvent = new CustomEvent('jwtStorage', { detail: jwt })
  window.dispatchEvent(event)
}
