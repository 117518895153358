import { noop } from 'lodash-es'
import React, { FC, useEffect, useState } from 'react'
import { useJwt } from '../../auth/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import { getUser } from '../api'
import { UserContext } from '../context'
import { User } from '../types'

const UserProvider: FC = ({ children }) => {
  const [jwt, setJwt] = useJwt()
  const [user, setUser] = useState<User | undefined>(jwt && jwt.user)
  const [language, setLanguage] = useCurrentLanguage()

  const jwtUserId = jwt && jwt.user.id

  useEffect(() => {
    jwtUserId &&
      getUser(jwtUserId, { lang: language })
        .then(({ data }) => setUser(data))
        .catch(noop)
  }, [jwtUserId, language, setLanguage])

  useEffect(() => {
    if (!jwtUserId && user) {
      setUser(undefined)
    }
  }, [jwtUserId, user])

  useEffect(() => {
    user && setJwt((prevJwt) => (prevJwt ? { ...prevJwt, user } : undefined))
  }, [setJwt, user])

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

export default UserProvider
