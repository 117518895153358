import React, { FC, Fragment } from 'react'
import { FormattedDate } from 'react-intl'
import { Event as EventType } from '../../events/types'
import Event from './Event'

interface Props {
  date: string
  events: EventType[]
}

const EventGroup: FC<Props> = ({ date, events }) => (
  <Fragment>
    <h3 className="title is-5 has-margin-bottom-0 has-padding-left-4 has-padding-top-4 has-padding-bottom-3 events-separator has-text-weight-bold">
      <FormattedDate value={date} year="numeric" month="long" />
    </h3>
    {events.map((event) => (
      <Event key={event.id} {...event} />
    ))}
  </Fragment>
)

export default EventGroup
