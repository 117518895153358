import { Field, FormikContextType, connect } from 'formik'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Switch from '../../formik/components/Switch'
import { useCurrentUser } from '../../user/hooks'
import { CheckoutFormValues } from '../types'
import ShippingAddressDetails from './ShippingAddressDetails'
import ShippingAddressForm from './ShippingAddressForm'

interface Props {
  formik: FormikContextType<CheckoutFormValues>
}

const ShippingAddress: FC<Props> = ({ formik }) => {
  const [currentUser] = useCurrentUser()

  const hasSavedAddress = !!(currentUser && currentUser.shippingAddress)
  const isFormVisible = !hasSavedAddress || formik.values.useOtherShippingAddress

  return (
    <div className="panel">
      <p className="panel-heading">
        <FormattedMessage
          id="checkout.ShippingAddress.heading"
          description="Shipping address heading in checkout page"
          defaultMessage="Shipping Address"
        />
      </p>
      {hasSavedAddress && (
        <div className="panel-block is-highlighted-block">
          <Field
            name="useOtherShippingAddress"
            label="Ship to different address"
            component={Switch}
          />
        </div>
      )}
      <div className="panel-block">
        {isFormVisible || !currentUser ? (
          <ShippingAddressForm />
        ) : (
          currentUser &&
          currentUser.shippingAddress && <ShippingAddressDetails {...currentUser.shippingAddress} />
        )}
      </div>
    </div>
  )
}

export default connect<{}, CheckoutFormValues>(ShippingAddress)
