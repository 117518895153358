import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  price: string
}

const Price: FC<Props> = ({ price }) => {
  if (!price) {
    return null
  }

  return (
    <div className="column is-3 is-field">
      <label>
        <FormattedMessage
          id="events.Price.label"
          description="Price label in event page"
          defaultMessage="Price"
        />
      </label>
      <div className="value has-padding-top-2" dangerouslySetInnerHTML={{ __html: price }} />
    </div>
  )
}

export default Price
