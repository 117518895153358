import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { like, unlike } from '../api'

interface Props {
  postId: number
  likes: number
  canLike: boolean
  hasLiked: boolean
  comments: number
  isCompact?: boolean
  onCommentsClick?: () => void
}

const SocialTabs: FC<Props> = ({ isCompact = false, ...props }) => {
  const [likes, setLikes] = useState(props.likes)
  const [hasLiked, setHasLiked] = useState(props.hasLiked)
  const toggleLike = hasLiked ? unlike : like
  const [isUpdating, setIsUpdating] = useState(false)

  const handleToggleLike = async (): Promise<void> => {
    const initialHasLiked = hasLiked
    const initialLikes = likes

    try {
      if (!isUpdating) {
        setIsUpdating(true)
        setHasLiked(!hasLiked)
        setLikes(hasLiked ? likes - 1 : likes + 1)
        await toggleLike(props.postId)
        setIsUpdating(false)
      }
    } catch (error) {
      setHasLiked(initialHasLiked)
      setLikes(initialLikes)
      setIsUpdating(false)
    }
  }

  return (
    <div className="social-actions tabs">
      <ul>
        <li className={hasLiked ? 'is-active' : undefined}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={() => props.canLike && handleToggleLike()}
            className={`has-padding-3 ${
              isCompact ? 'has-padding-left-0 has-padding-bottom-0 ' : ''
            }`}
          >
            <span className="icon">
              <i className="icon-likes" aria-hidden="true" />
            </span>
            <span className={isCompact ? '' : 'has-text-weight-bold'}>
              <FormattedMessage
                id="posts.SocialTabs.likes"
                description="Likes count in post detailed view"
                defaultMessage="{count} likes"
                values={{ count: likes }}
              />
            </span>
          </a>
        </li>
        <li>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={props.onCommentsClick}
            className={`has-padding-3 ${isCompact ? 'has-padding-bottom-0 ' : ''}`}
          >
            <span className="icon">
              <i className="icon-comments" aria-hidden="true" />
            </span>
            <span className={isCompact ? '' : 'has-text-weight-bold'}>
              <FormattedMessage
                id="posts.SocialTabs.comments"
                description="Comments count in post detailed view"
                defaultMessage="{count} comments"
                values={{ count: props.comments }}
              />
            </span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SocialTabs
