import { FormikContextType, connect } from 'formik'
import React, { FC } from 'react'
import Notification from '../../bulma/components/Notification'
import { Color } from '../../bulma/types'

interface OuterProps {
  color?: Color
}

interface Props extends OuterProps {
  formik: FormikContextType<{}>
}

const Status: FC<Props> = ({ color = 'danger', formik }) => {
  if (!formik.status) {
    return null
  }

  return <Notification type={color}>{formik.status}</Notification>
}

export default connect<OuterProps>(Status)
