import React, { FC } from 'react'
import Categories from '../../../user/components/Categories'

const Content: FC = () => (
  <div className="panel">
    <p className="panel-heading is-borderless has-padding-y-1" />
    <div className="panel-block is-column">
      <Categories />
    </div>
  </div>
)

export default Content
