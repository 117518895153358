import { AllPlatforms } from './types'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const is = (options: object) => (str: string): boolean => options[str] || false

const isOption = (...fn: Function[]) => (str: string | boolean) => fn.some((option) => option(str))

const isMobile = is({ mobile: true })
const isTablet = is({ tablet: true })
const isTouch = is({ touch: true })
const isDesktop = is({ desktop: true })
const isWidescreen = is({ widescreen: true })

const isTabletOnly = is({ 'tablet-only': true })
const isDesktopOnly = is({ 'desktop-only': true })

const isAllPlatforms = isOption(
  isMobile,
  isTablet,
  isDesktop,
  isTouch,
  isWidescreen,
  isTabletOnly,
  isDesktopOnly
)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getHideModifiers = (platform?: boolean | AllPlatforms | AllPlatforms[]) => {
  if (typeof platform === 'boolean') {
    return platform ? { 'is-hidden': true } : {}
  }

  if (typeof platform === 'string') {
    return isAllPlatforms(platform) ? { [`is-hidden-${platform}`]: true } : {}
  }

  if (Array.isArray(platform)) {
    return platform.reduce(
      (acc, aPlatform) =>
        isAllPlatforms(aPlatform) ? { ...acc, [`is-hidden-${aPlatform}`]: true } : acc,
      {}
    )
  }

  return {}
}
