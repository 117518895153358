import { differenceInMinutes } from 'date-fns'
import React, { FC, useRef } from 'react'
import IdleTimer from 'react-idle-timer'
import { useCurrentUser, useLogout } from '../hooks'

const LogoutTimer: FC = () => {
  const logout = useLogout()
  const timerRef = useRef<IdleTimer>(null)
  const [currentUser] = useCurrentUser()

  return (
    <IdleTimer
      ref={timerRef}
      timeout={1000}
      onActive={() => {
        if (!timerRef.current || !currentUser) {
          return
        }

        const lastActive = timerRef.current.getLastActiveTime()
        if (differenceInMinutes(new Date(), lastActive) >= 60) {
          return logout()
        }
      }}
    />
  )
}
export default LogoutTimer
