import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  day: {
    id: 'general.day',
    description: 'A single day',
    defaultMessage: 'Day',
  },
  daily: {
    id: 'general.daily',
    description: 'Something that repeats every day',
    defaultMessage: 'Daily',
  },
  week: {
    id: 'general.week',
    description: 'A single week',
    defaultMessage: 'Week',
  },
  weekly: {
    id: 'general.weekly',
    description: 'Something that repeats every week',
    defaultMessage: 'Weekly',
  },
  month: {
    id: 'general.month',
    description: 'A single month',
    defaultMessage: 'Month',
  },
  monthly: {
    id: 'general.monthly',
    description: 'Something that repeats every month',
    defaultMessage: 'Monthly',
  },
  year: {
    id: 'general.year',
    description: 'A single year',
    defaultMessage: 'Year',
  },
  annual: {
    id: 'general.annual',
    description: 'Something that repeats every year',
    defaultMessage: 'Annual',
  },
})
