import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Cart } from '../../cart/types'

interface Props {
  cart: Cart
}

const CheckoutDetails: FC<Props> = ({ cart }) => (
  <div className="panel">
    <p className="panel-heading">
      <FormattedMessage
        id="checkout.CheckoutDetails.heading"
        description="Checkout details heading text"
        defaultMessage="Checkout Details"
      />
    </p>
    {cart.items.map((item) => (
      <div key={item.id} className="panel-block is-value-pair line-item">
        <span dangerouslySetInnerHTML={{ __html: item.product.name }} />
        <span dangerouslySetInnerHTML={{ __html: item.lineTotalHtml }} />
      </div>
    ))}
    <div className="panel-block" />
    <div className="panel-block is-value-pair">
      <span>
        <FormattedMessage
          id="checkout.CheckoutDetails.subTotal"
          description="Checkout details order subtotal"
          defaultMessage="Total without tax"
        />
      </span>
      <span dangerouslySetInnerHTML={{ __html: cart.subtotalHtml }} />
    </div>
    {cart.needsShipping && (
      <div className="panel-block is-value-pair">
        <span>
          <FormattedMessage
            id="checkout.CheckoutDetails.shipping"
            description="Checkout details shipping row"
            defaultMessage="Shipping"
          />
        </span>
        <span dangerouslySetInnerHTML={{ __html: cart.shippingTotalHtml }} />
      </div>
    )}
    {cart.taxes.map((tax) => (
      <div key={tax.name} className="panel-block is-value-pair">
        <span>
          <FormattedMessage
            id="checkout.CheckoutDetails.tax"
            description="Checkout details order tax row"
            defaultMessage="Tax ({name})"
            values={{ name: tax.name }}
          />
        </span>
        <span dangerouslySetInnerHTML={{ __html: tax.amountHtml }} />
      </div>
    ))}
    <div className="panel-block is-value-pair has-text-weight-bold">
      <span>
        <FormattedMessage
          id="checkout.CheckoutDetails.total"
          description="Checkout details order total"
          defaultMessage="Total"
        />
      </span>
      <span dangerouslySetInnerHTML={{ __html: cart.totalHtml }} />
    </div>
  </div>
)

export default CheckoutDetails
