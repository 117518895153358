import { MessageDescriptor } from 'react-intl'
import { SubscriptionPeriod } from '../user/types'
import { messages } from '../utils/messages'

export const periodTextRecord: Record<SubscriptionPeriod, MessageDescriptor> = {
  day: messages.daily,
  week: messages.weekly,
  month: messages.monthly,
  year: messages.annual,
}
