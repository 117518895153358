import { getName } from 'i18n-iso-countries'
import { join } from 'lodash-es'
import React, { FC } from 'react'
import { useCurrentLanguage } from '../../locale/hooks'
import { ShippingAddress } from '../../user/types'

const ShippingAddressDetails: FC<ShippingAddress> = (props) => {
  const [language] = useCurrentLanguage()

  const { firstName, lastName } = props
  const name = join([firstName, lastName], ' ')

  const { address1, city, postcode } = props
  const addressText = join([address1, city, postcode], ', ')

  const { state, country } = props
  const location = join([state, getName(country, language)], ', ')

  return (
    <ul className="is-value-list">
      <li className="is-highlighted-value">
        {props.company ? (
          <div>
            <span className="has-text-weight-bold has-margin-right-2">{props.company}</span>({name})
          </div>
        ) : (
          <div className="has-text-weight-bold has-margin-right-2">{name}</div>
        )}
      </li>
      <li>
        <div>{addressText}</div>
        <div>{location}</div>
        <p />
      </li>
    </ul>
  )
}

export default ShippingAddressDetails
