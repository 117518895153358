import { Field, FieldProps } from 'formik'
import { floor, lowerCase, max } from 'lodash-es'
import React, { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Spinner from '../../../bulma/components/Spinner'
import { useCurrentLanguage } from '../../../locale/hooks'
import { messages as generalMessages } from '../../../utils/messages'
import { useGetPlans } from '../../hooks'
import { periodTextRecord } from '../../utils'

const PlanStep: FC = () => {
  const { formatMessage } = useIntl()
  const [language] = useCurrentLanguage()
  const [request] = useGetPlans({ lang: language })
  const { data: plans = [], isLoading } = request

  if (isLoading) {
    return <Spinner />
  }

  const yearlyPrices = plans.map((aPlan) => aPlan.yearlyPrice)
  const highestYearlyPrice = max(yearlyPrices) || 0

  return (
    <div className="columns has-padding-top-2 has-padding-bottom-4 has-padding-x-3 is-centered">
      <div className="column is-12 has-margin-top-3 has-padding-bottom-3 is-centered">
        <div className="columns">
          {plans.map((plan) => (
            <div className="column" key={plan.id}>
              <Field name="plan">
                {(props: FieldProps) => {
                  const yearlyPrice = plan.yearlyPrice
                  const savings = floor(100 - (yearlyPrice / highestYearlyPrice) * 100)

                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      onClick={() => props.form.setFieldValue(props.field.name, plan.id)}
                      className={`is-pricing-plan ${
                        props.field.value === plan.id ? 'is-active' : ''
                      }`}
                    >
                      <span className="name">{formatMessage(periodTextRecord[plan.period])}</span>
                      <span className="price">
                        {plan.price}
                        <span
                          className="unit"
                          dangerouslySetInnerHTML={{
                            __html: plan.currency,
                          }}
                        />
                      </span>
                      <span className="period">
                        /{lowerCase(formatMessage(generalMessages[plan.period]))}
                      </span>
                      {highestYearlyPrice > yearlyPrice ? (
                        <span className="badge">
                          <FormattedMessage
                            id="subscriptions.SubscriptionForm.PlanStep.savings"
                            description="Savings percentage when picking subscription plan"
                            defaultMessage="Save -{savings}%"
                            values={{ savings }}
                          />
                        </span>
                      ) : (
                        <span className="badge is-invisible">&nbsp;</span>
                      )}
                    </a>
                  )
                }}
              </Field>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PlanStep
