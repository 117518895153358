import React, { FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '../../router/hooks'
import SearchBar from '../../shop/components/SearchBar'
import { useGetOrder } from '../hooks'
import OrderDetails from './OrderDetails'
import SuccessMessage from './SuccessMessage'

const OrderPage: FC = () => {
  const { key } = useParams<{ key: string }>()
  const [{ success }] = useQuery<{ success: string }>()
  const [request] = useGetOrder(key)
  const { data: order } = request

  return (
    <Fragment>
      <SearchBar hideFilter />
      <section className="section has-padding-top-4 has-padding-top-5-tablet">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-10 is-6-desktop is-offset-1 is-offset-3-desktop has-margin-bottom-6-tablet">
              {order && success && <SuccessMessage {...order} />}
              {order && <OrderDetails {...order} />}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default OrderPage
