import { FieldProps } from 'formik'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import { ValueType } from 'react-select/src/types'
import ErrorMessage from './ErrorMessage'

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  label: string
  options: Option[]
  isMultiple: boolean
}

const gray = 'hsl(0,0%,80%)'
const lightBlue = '0 0 0 .125em rgba(12,140,211,.25)'

const SelectField: FC<Props> = ({ field, form, isMultiple, ...props }) => (
  <div className="field">
    <label htmlFor={field.name} className="label is-medium">
      {props.label}
    </label>
    <div className="control">
      <div className="is-fullwidth">
        <Select
          isMulti={isMultiple}
          options={props.options}
          value={props.options.find(({ value }) => value === field.value)}
          placeholder={
            <FormattedMessage
              id="form.SelectField.placeholder"
              description="Placeholder text in select field"
              defaultMessage="Select..."
            />
          }
          styles={{
            control: (base, state) => ({
              ...base,
              borderColor: gray,
              boxShadow: state.isFocused ? lightBlue : 'none',
              '&:hover': { borderColor: gray },
            }),
          }}
          onChange={(selectedOptions: ValueType<Option>) => {
            form.setFieldTouched(field.name)

            if (!selectedOptions) {
              form.setFieldValue(field.name, isMultiple ? [] : '')
              return
            }

            const value = Array.isArray(selectedOptions)
              ? (selectedOptions as Option[]).map((option) => option.value)
              : (selectedOptions as Option).value

            form.setFieldValue(field.name, value)
          }}
        />
      </div>
    </div>
    <ErrorMessage name={field.name} />
  </div>
)

export default SelectField
