import React, { FC } from 'react'
import { Category as CategoryType } from '../../../categories/types'

type Props = CategoryType & {
  isSelected: boolean
  isDisabled: boolean
  onClick: (id: number) => void
}

const Category: FC<Props> = (props) => {
  const key = `category${props.id}`

  return (
    <li className="field">
      <input
        id={key}
        type="checkbox"
        name={key}
        className="switch is-rtl is-rounded is-medium"
        checked={props.isSelected}
        disabled={props.isDisabled}
        readOnly
      />
      <label htmlFor={key} onClick={() => props.onClick(props.id)}>
        {props.name}
      </label>
    </li>
  )
}

export default Category
