import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import SubscribeBlock from '../../newsletter/components/SubscribeBlock'

const LoadingArticle: FC = () => (
  <section className="section has-padding-top-4 has-padding-top-5-tablet">
    <div className="container">
      <div className="columns">
        <div className="column is-8">
          <Skeleton height={315} />
          <div className="has-margin-top-5">
            <Skeleton width="100%" />
            <Skeleton width="75%" />
            <Skeleton width="85%" />
            <Skeleton width="65%" />
          </div>
        </div>
        <SubscribeBlock />
      </div>
    </div>
  </section>
)

export default LoadingArticle
