import { castArray, xor } from 'lodash-es'
import React, { FC, Fragment, useState } from 'react'
import { useCurrentLanguage } from '../../../locale/hooks'
import { useGetFormats } from '../../../products/hooks'
import { ProductsFilterQueryParams } from '../../../products/types'
import { useQuery } from '../../../router/hooks'
import { useDebounce } from '../../../utils/hooks'
import { FormattedMessage } from 'react-intl'

const FormatFilter: FC = () => {
  const [query, setQuery] = useQuery<ProductsFilterQueryParams>()
  const initialValue = castArray(query.formats || []).map(Number)
  const [value, setValue] = useState<number[]>(initialValue)
  const [language] = useCurrentLanguage()
  const [request] = useGetFormats({ lang: language })
  const { data: formats = [] } = request

  useDebounce(() => setQuery({ formats: value.map(String) }), 350, [value])

  return (
    <Fragment>
      <p className="subtitle">
        <FormattedMessage
          id="shop.FilterPanel.FormatFilter.label"
          description="Product format label in mobile product filter"
          defaultMessage="Format:"
        />
      </p>
      <ul className="filter-list">
        {formats.map(({ id, name }) => (
          <li key={id}>
            <div className="field">
              <input
                id={`format${id}`}
                type="checkbox"
                checked={value.includes(id)}
                className="is-checkradio has-background-color is-info"
              />
              <label onClick={() => setValue(xor(value, [id]))} htmlFor={`format${id}`}>
                {name}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </Fragment>
  )
}

export default FormatFilter
