import Cookies from 'js-cookie'
import { Consents } from './types'

const COOKIE_CONSENTS_KEY = 'orreca_cookie_consents'

export const storeConsents = (consents: Consents): void => {
  Cookies.set(COOKIE_CONSENTS_KEY, JSON.stringify(consents), { expires: 365 * 10 })
}

export const getConsents = (): Consents | undefined => {
  const consents = Cookies.get(COOKIE_CONSENTS_KEY)
  return consents ? JSON.parse(consents) : undefined
}
