import React, { FC, useState } from 'react'
import { useBoolean } from 'react-hanger/array/useBoolean'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore no up to date typings for this library atm
import Carousel, { Modal, ModalGateway } from 'react-images'
import { FormattedMessage } from 'react-intl'
import { GalleryImage } from '../types'
import GalleryThumbnails from './GalleryThumbnails'

interface Props {
  images: GalleryImage[]
}

const Gallery: FC<Props> = ({ images }) => {
  const [isModalOpen, modalActions] = useBoolean(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  if (!images.length) {
    return null
  }

  return (
    <div className="card content-block has-margin-top-5">
      <header className="card-header is-shadowless is-borderless has-padding-top-4 has-padding-bottom-4 has-padding-bottom-3 has-padding-left-4">
        <h2 className="title is-4">
          <FormattedMessage
            id="posts.Gallery.title"
            description="Gallery block title in post detailed view"
            defaultMessage="Gallery"
          />
        </h2>
      </header>
      <div className="card-content has-padding-x-1 has-padding-top-0 has-padding-bottom-4">
        <div className="gallery-grid columns is-gapless">
          <GalleryThumbnails
            images={images}
            onClick={(i) => {
              setCurrentIndex(i)
              modalActions.toggle()
            }}
          />
        </div>
      </div>
      <footer className="card-footer">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="card-footer-item has-text-weight-bold" onClick={modalActions.toggle}>
          <FormattedMessage
            id="posts.Gallery.seeAll"
            description="See all photos text in gallery block footer"
            defaultMessage="See all photos"
          />
          <span className="group-count tag is-light has-text-weight-bold has-margin-left-3 has-padding-y-1 has-padding-x-3">
            {images.length}
          </span>
        </a>
      </footer>
      <ModalGateway>
        {isModalOpen && (
          <Modal onClose={modalActions.toggle}>
            <Carousel
              currentIndex={currentIndex}
              views={images.map((image) => ({
                source: image.sizes.orreca_front_large.src,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  )
}

export default Gallery
