import React, { FC } from 'react'
import Link from '../../router/components/Link'
import { Podcast } from '../types'
import PodcastThumbnail from './PodcastThumbnail'

type Props = Podcast & {
  position: number
}

const PreviousPodcastsListItem: FC<Props> = ({ position, ...podcast }) => (
  <div className="column is-6">
    <Link
      to={`/videos-and-podcasts/podcasts/${podcast.id}/${podcast.slug}`}
      className="podcast-item-related card is-borderless has-margin-y-0 is-paddingless has-margin-bottom-4"
    >
      <PodcastThumbnail {...podcast} position={position} />
    </Link>
  </div>
)

export default PreviousPodcastsListItem
