import Cookies from 'js-cookie'
import { MessageDescriptor } from 'react-intl'
import { Language } from './enums'
import { messages } from './messages'

const COOKIE_LANGUAGE_KEY = 'orreca_lang'

export const getLanguageFromPathname = (pathname: string): Language | null => {
  const parts = pathname.split('/')
  if (parts.length < 2) {
    return null
  }

  const language = parts[1] as Language

  if (!Object.values(Language).includes(language)) {
    return null
  }

  return language as Language
}

export const getLanguageFromCookie = (): Language | undefined =>
  Cookies.get(COOKIE_LANGUAGE_KEY) as Language | undefined

export const storeLanguageInCookie = (language: Language): void => {
  Cookies.set(COOKIE_LANGUAGE_KEY, language, { expires: 365 * 10 })
}

export const languageToText = (language: Language): MessageDescriptor => messages[language]
