import React, { FC } from 'react'
import { FormattedDate } from 'react-intl'
import avatar from '../../assets/img/ico_profile.svg'
import { Topic } from '../types'
import { getAuthor } from '../utils'

const TopicMeta: FC<Topic> = (props) => {
  const author = getAuthor(props)

  return (
    <div className="meta is-clearfix has-padding-3">
      {author && (
        <figure className="image is-pulled-left has-margin-right-3">
          <img alt={author.name} src={author.avatar || avatar} className="profile-pic is-rounded" />
        </figure>
      )}
      <div className="text is-pulled-left has-padding-top-2 has-padding-x-3">
        <p className="name has-text-weight-bold has-margin-bottom-0">{author && author.name}</p>
        <span className="date has-margin-0 has-text-grey">
          <FormattedDate value={props.created} />
        </span>
      </div>
    </div>
  )
}

export default TopicMeta
