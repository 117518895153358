import React, { FC, useRef } from 'react'
import Spinner from '../../../bulma/components/Spinner'
import { useModal } from '../../../modal/hooks'
import Details from '../../../orders/components/OrderDetails'
import { useGetOrder } from '../../../orders/hooks'
import { useQuery } from '../../../router/hooks'
import { useClickOutside } from '../../../utils/hooks'

const OrderDetails: FC = () => {
  const { close } = useModal()
  const [query, setQuery] = useQuery<{ orderKey: string }>()
  const [request] = useGetOrder(query.orderKey)
  const { data: order, isLoading } = request
  const ref = useRef(null)

  useClickOutside(ref, close)

  if (isLoading) {
    return <Spinner />
  }

  if (!order) {
    setQuery({ orderKey: undefined })
    close()
    return null
  }

  return (
    <div ref={ref}>
      <Details {...order} />
    </div>
  )
}

export default OrderDetails
