import React, { FC } from 'react'
import { useModal } from '../../../modal/hooks'
import { FormattedMessage } from 'react-intl'

const SucessStep: FC = () => {
  const { close } = useModal()

  return (
    <div className="columns is-centered">
      <div className="msg-success column has-text-centered is-two-thirds has-margin-top-1">
        <div className="picto-success has-margin-top-5 has-margin-bottom-4" />
        <h1 className="title is-4">
          <FormattedMessage
            id="subscriptions.SubscriptionForm.SuccessStep.title"
            description="Success message title after subscription purchase"
            defaultMessage="Thank You!"
          />
        </h1>
        <h2 className="subtitle is-5">
          <FormattedMessage
            id="subscriptions.SubscriptionForm.SuccessStep.subtitle"
            description="Success message subtitle after subscription purchase"
            defaultMessage="You are now sucessfully subscribed."
          />
        </h2>
        <button type="button" onClick={close} className="button is-outlined is-medium is-info">
          <FormattedMessage
            id="subscriptions.SubscriptionForm.SuccessStep.continue"
            description="Continue button after subscription purchase"
            defaultMessage="Continue"
          />
        </button>
      </div>
    </div>
  )
}

export default SucessStep
