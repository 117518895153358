import { parse } from 'query-string'
import React, { FC } from 'react'
import { Link as BaseLink, LinkProps, useLocation } from 'react-router-dom'
import { useCurrentLanguage } from '../../locale/hooks'
import { buildUrl } from '../utils'

interface Props extends LinkProps {
  search?: object
  replaceSearch?: object
}

const Link: FC<Props> = ({ to, search, replaceSearch, ...props }) => {
  const [currentLanguage] = useCurrentLanguage()
  const { search: currentSearch, pathname } = useLocation()
  const prefixedPath = `/${currentLanguage}${to ? to : pathname}`
  const path = buildUrl(prefixedPath, search, replaceSearch, parse(currentSearch))

  return <BaseLink to={path} {...props} />
}

export default Link
