import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'
import { useQuickview } from '../../quickview/hooks'

const LoggedOutButtons: FC = () => {
  const { open: openModal } = useModal()
  const { close: closeQuickview } = useQuickview()

  return (
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-12 has-margin-top-4 has-padding-bottom-3">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="button is-medium is-primary is-outlined is-fullwidth"
            onClick={() => {
              openModal(ModalType.Login)
              closeQuickview()
            }}
          >
            <FormattedMessage
              id="header.LoggedOutButtons.login"
              description="Log in button in mobile menu"
              defaultMessage="Log in"
            />
          </a>
        </div>
        <div className="column is-12 has-margin-bottom-4 has-padding-top-3">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="button is-medium is-primary is-fullwidth"
            onClick={() => {
              openModal(ModalType.Register)
              closeQuickview()
            }}
          >
            <FormattedMessage
              id="header.LoggedOutButtons.register"
              description="Register button in mobile menu"
              defaultMessage="Register"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LoggedOutButtons
