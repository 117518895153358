import React, { FC } from 'react'
import { useCurrentUser } from '../../../user/hooks'
import Avatar from './Avatar'
import Profile from './Profile'

const Details: FC = () => {
  const [user] = useCurrentUser()
  if (!user) {
    return null
  }

  return (
    <div className="panel">
      <p className="panel-heading is-borderless has-padding-y-1" />
      <Profile />
      <Avatar />
    </div>
  )
}

export default Details
