import React, { FC, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import CategoryPicker from '../../categories/components/CategoryPicker'
import ProductList from '../../products/components/ProductList'
import SearchBar from './SearchBar'

const messages = defineMessages({
  title: {
    defaultMessage: 'Shop',
    description: 'Page title for shop page',
    id: 'shop.ShopPage.title',
  },
})

const ShopPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <SearchBar />
      <section className="section has-padding-top-4 has-padding-top-5-tablet">
        <div className="container">
          <CategoryPicker />
          <ProductList />
        </div>
      </section>
    </Fragment>
  )
}

export default ShopPage
