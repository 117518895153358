import React, { FC } from 'react'
import { useQuickview } from '../../hooks'

interface Props {
  title?: string
}

const Header: FC<Props> = ({ title }) => {
  const { close } = useQuickview()

  return (
    <header className="quickview-header has-padding-4">
      {title && <p className="title">{title}</p>}
      <span className="delete" onClick={close} />
    </header>
  )
}

export default Header
