import { FieldProps } from 'formik'
import React, { FC, Fragment } from 'react'
import ErrorMessage from './ErrorMessage'

interface Option {
  value: string
  label: string
}

interface Props extends FieldProps {
  label: string
  options: Option[]
}

const Radios: FC<Props> = ({ field, form, ...props }) => (
  <div className="field">
    <label htmlFor={field.name} className="label is-medium">
      {props.label}
    </label>
    <div className="control">
      <div className="field">
        {props.options.map((option, i) => (
          <Fragment key={option.value}>
            <input
              className="is-checkradio"
              id={field.name + i}
              type="radio"
              onChange={() => form.setFieldValue(field.name, option.value)}
              checked={field.value === option.value}
            />
            <label htmlFor={field.name + i}>{option.label}</label>
          </Fragment>
        ))}
      </div>
    </div>
    <ErrorMessage name={field.name} />
  </div>
)

export default Radios
