import { useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { Podcast, UseGetPodcastsQueryParams } from './types'

export const useGetPodcast = (id: number): UseAxios<Podcast> =>
  useAxios<Podcast>({ url: `/orreca/v1/podcasts/${id}`, params: { _embed: true } }, [id])

export const useGetPodcasts = (params: UseGetPodcastsQueryParams): UseAxios<Podcast[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<Podcast[]>({ url: `/orreca/v1/podcasts`, params }, [mParams])
}
