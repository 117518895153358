/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import * as yup from 'yup'
import { messages as generalMessages } from '../formik/messages'

const messages = defineMessages({
  invalidTotp: {
    id: 'user.schema.invalidTotp',
    description: 'Invalid Google Authenticator code message',
    defaultMessage: 'Authentication code must be 6 digits long',
  },
  passwordsMustMatch: {
    id: 'user.schema.passwordsMustMatch',
    description: 'Passwords must match message in registration and reset password forms',
    defaultMessage: 'Passwords must match',
  },
  passwordTooShort: {
    id: 'user.schema.passwordTooShort',
    description: 'Password too short message in registration and reset password forms',
    defaultMessage: 'Password must be at least 8 letters long',
  },
  passwordDigitMissing: {
    id: 'user.schema.passwordDigitMissing',
    description:
      'Password must contain one number message in registration and reset password forms',
    defaultMessage: 'Password must contain at least one number',
  },
  passwordUppercaseLetter: {
    id: 'user.schema.passwordUppercaseLetter',
    description:
      'Password must contain uppercase letter message in registration and reset password forms',
    defaultMessage: 'Password must contain at least one uppercase letter',
  },
  passwordLowercaseLetter: {
    id: 'user.schema.passwordLowercaseLetter',
    description:
      'Password must contain lowercase letter message in registration and reset password forms',
    defaultMessage: 'Password must contain at least one lowercase letter',
  },
})

export const useLoginSchema = () => {
  const { formatMessage } = useIntl()
  const totpSchema = useTotpSchema()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      email: yup
        .string()
        .email(formatMessage(generalMessages.invalidEmail))
        .required(requiredMessage),
      password: yup.string().required(requiredMessage),
      step: yup.number().min(1).max(2).required(requiredMessage),
      totp: yup.string().when('step', {
        is: 2,
        then: totpSchema,
        otherwise: yup.string().notRequired(),
      }),
    })
  }, [formatMessage, totpSchema])
}

export const useTotpSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.string().length(6, formatMessage(messages.invalidTotp)).required(requiredMessage)
  }, [formatMessage])
}

export const usePasswordSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(
    () =>
      yup
        .string()
        .required(formatMessage(generalMessages.required))
        .min(8, formatMessage(messages.passwordTooShort))
        .matches(/[\d]+/, formatMessage(messages.passwordDigitMissing))
        .matches(/[A-Z]+/, formatMessage(messages.passwordUppercaseLetter))
        .matches(/[a-z]+/, formatMessage(messages.passwordLowercaseLetter)),
    [formatMessage]
  )
}

export const usePasswordMatchSchema = (field = 'password') => {
  const { formatMessage } = useIntl()

  return useMemo(
    () =>
      yup
        .string()
        .required(formatMessage(generalMessages.required))
        .oneOf([yup.ref(field)], formatMessage(messages.passwordsMustMatch)),
    [field, formatMessage]
  )
}

export const useRegistrationSchema = () => {
  const passwordSchema = usePasswordSchema()
  const passwordMatchSchema = usePasswordMatchSchema()
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      email: yup
        .string()
        .email(formatMessage(generalMessages.invalidEmail))
        .required(requiredMessage),
      password: passwordSchema,
      passwordConfirm: passwordMatchSchema,
      agreeToTerms: yup.boolean().oneOf([true]).required(requiredMessage),
    })
  }, [formatMessage, passwordMatchSchema, passwordSchema])
}

export const useForgotPasswordSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(formatMessage(generalMessages.invalidEmail))
          .required(formatMessage(generalMessages.required)),
      }),
    [formatMessage]
  )
}

export const useResetPasswordSchema = () => {
  const { formatMessage } = useIntl()
  const passwordSchema = usePasswordSchema()
  const passwordMatchSchema = usePasswordMatchSchema()

  return useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(formatMessage(generalMessages.invalidEmail))
          .required(formatMessage(generalMessages.required)),
        password: passwordSchema,
        passwordConfirm: passwordMatchSchema,
      }),
    [formatMessage, passwordMatchSchema, passwordSchema]
  )
}

export const useBillingAddressSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)

    return yup.object().shape({
      firstName: yup.string().required(requiredMessage),
      lastName: yup.string().required(requiredMessage),
      company: yup.string().notRequired(),
      vatNo: yup.string().notRequired(),
      address1: yup.string().required(requiredMessage),
      city: yup.string().required(requiredMessage),
      state: yup.string().required(requiredMessage),
      postcode: yup.string().required(requiredMessage),
      country: yup.string().required(requiredMessage),
      email: yup
        .string()
        .email(formatMessage(generalMessages.invalidEmail))
        .required(requiredMessage),
      phone: yup.string().notRequired(),
    })
  }, [formatMessage])
}
