import { getName } from 'i18n-iso-countries'
import { join } from 'lodash-es'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentLanguage } from '../../locale/hooks'
import { BillingAddress as AddressType } from '../../user/types'

const BillingAddress: FC<AddressType> = (props) => {
  const [language] = useCurrentLanguage()

  const { firstName, lastName } = props
  const name = join([firstName, lastName], ' ')

  const { address1, city, postcode } = props
  const addressText = join([address1, city, postcode], ', ')

  const { state, country } = props
  const location = join([state, getName(country, language)], ', ')

  return (
    <ul className="is-value-list">
      <li className="is-highlighted-value">
        {props.company ? (
          <div>
            <span className="has-text-weight-bold has-margin-right-2">{props.company}</span>({name})
          </div>
        ) : (
          <div className="has-text-weight-bold has-margin-right-2">{name}</div>
        )}
      </li>
      <li>
        <div>{addressText}</div>
        <div>{location}</div>
        {props.vatNo && (
          <div>
            <FormattedMessage
              id="user.BillingAddress.vatNo"
              description="VAT number text in billing address"
              defaultMessage="VAT no: {vatNo}"
              values={{ vatNo: props.vatNo }}
            />
          </div>
        )}
        {props.phone && (
          <div>
            <FormattedMessage
              id="user.BillingAddress.phone"
              description="Phone number text in billing address"
              defaultMessage="Phone: {number}"
              values={{ number: props.phone }}
            />
          </div>
        )}
        <p />
      </li>
    </ul>
  )
}

export default BillingAddress
