import React, { FC, Fragment } from 'react'
import { parsePaginationHeaders } from '../../api/utils'
import Pagination from '../../bulma/components/Pagination'
import { useSelectedCategories } from '../../categories/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetEvents, useGroupedEvents } from '../hooks'
import { EventsFilterQueryParams } from '../types'
import EventListBlock from './EventListBlock'
import LoadingEventList from './LoadingEventList'

const EventList: FC = () => {
  const [categories] = useSelectedCategories()
  const [language] = useCurrentLanguage()
  const [query] = useQuery<EventsFilterQueryParams>()
  const [request] = useGetEvents({
    startDate: query.from,
    endDate: query.to,
    lang: language,
    page: Number(query.page) || 1,
    categories,
  })

  const { data: events = [], isLoading, isInitial } = request
  const groupedEvents = useGroupedEvents(events)

  if (isInitial && isLoading) {
    return <LoadingEventList />
  }

  const { totalPages } = parsePaginationHeaders(request.headers || {})
  const showPagination = events.length > 0 && totalPages > 1

  return (
    <Fragment>
      {Object.keys(groupedEvents).map((groupDate, i) => (
        <EventListBlock
          key={groupDate}
          date={groupDate}
          events={groupedEvents[groupDate]}
          isFirst={i === 0}
        />
      ))}
      {showPagination && <Pagination pages={totalPages} />}
    </Fragment>
  )
}

export default EventList
