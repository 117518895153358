import { noop } from 'lodash-es'
import { createContext } from 'react'
import { SetState } from '../utils/types'
import { JWT } from './types'

interface Value {
  jwt?: JWT
  setJwt: SetState<JWT | undefined>
}

export const JwtContext = createContext<Value>({ setJwt: noop })
