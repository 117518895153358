import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { QuickviewType } from '../../quickview/enums'
import { useQuickview } from '../../quickview/hooks'
import { useSelectedCategories } from '../hooks'

const CategoryPanelButton: FC = () => {
  const [selectedCategories] = useSelectedCategories()
  const { open } = useQuickview()

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="toggle-filter button is-medium is-outlined is-fullwidth has-margin-bottom-4 is-hidden-tablet"
      aria-label="menu"
      aria-expanded="false"
      onClick={() => open(QuickviewType.Categories)}
    >
      <FormattedMessage
        id="categories.CategoryPanelButton.text"
        description="Category side panel button in mobile view"
        defaultMessage="Categories"
      />
      <span className="badge">{selectedCategories.length}</span>
    </a>
  )
}

export default CategoryPanelButton
