import React, { FC, Fragment } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import Quickview from '../../../quickview/components/Quickview'
import FormatFilter from './FormatFilter'
import NameFilter from './NameFilter'
import OrderFilter from './OrderFilter'

const messages = defineMessages({
  title: {
    id: 'shop.FilterPanel.title',
    description: 'Mobile product filter panel title',
    defaultMessage: 'Shop filter',
  },
})

const FilterPanel: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <Quickview.Header title={formatMessage(messages.title)} />
      <Quickview.Body className="has-margin-4 is-top">
        <NameFilter />
        <div className="quickview-block shop-filters">
          <OrderFilter />
          <FormatFilter />
        </div>
      </Quickview.Body>
    </Fragment>
  )
}

export default FilterPanel
