import { Language } from './enums'
import { Months } from './types'

export const WEEKDAYS_LONG = {
  [Language.English]: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  [Language.German]: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
}

export const WEEKDAYS_SHORT = {
  [Language.English]: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  [Language.German]: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
}

export const MONTHS: Months = {
  [Language.English]: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  [Language.German]: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
}

export const FIRST_DAY = {
  [Language.English]: 0,
  [Language.German]: 1, // Use Monday as first day of the week
}
