import { parseISO, startOfMonth } from 'date-fns'
import { truncate } from 'lodash-es'
import { htmlToText } from '../utils'
import { Event } from './types'

export const getGroupedEvents = (events: Event[]): Record<string, Event[]> =>
  events.reduce<Record<string, Event[]>>((carry, event) => {
    const startDate = parseISO(event.startDate)
    const startOfMonthISO = startOfMonth(startDate).toISOString()

    if (Array.isArray(carry[startOfMonthISO])) {
      carry[startOfMonthISO].push(event)
    } else {
      carry[startOfMonthISO] = [event]
    }

    return carry
  }, {})

export const getEventUrl = (event: Event): string => `/events/${event.id}/${event.slug}`

export const getMetaDescription = (event: Event): string =>
  truncate(htmlToText(event.excerptHtml || event.contentHtml), { length: 160 })
