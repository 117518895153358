import { differenceInMonths } from 'date-fns'
import { truncate } from 'lodash-es'
import { Podcast } from '../podcasts/types'
import { htmlToText } from '../utils'
import { Video } from '../videos/types'
import { Author, EmbeddedFields, FeaturedMedia, Post } from './types'

const getEmbeddedField = <K extends keyof EmbeddedFields>(
  key: K,
  post: Post | Video | Podcast
): EmbeddedFields[K] => (post._embedded && post._embedded[key] ? post._embedded[key] : undefined)

export const getAuthor = (post: Post | Video | Podcast): Author | undefined => {
  const author = getEmbeddedField('author', post)
  return Array.isArray(author) && author.length > 0 ? author[0] : undefined
}

export const getFeaturedMedia = (post: Post | Video | Podcast): FeaturedMedia | undefined => {
  const featuredMedia = getEmbeddedField('wp:featuredmedia', post)
  return Array.isArray(featuredMedia) && featuredMedia.length > 0 ? featuredMedia[0] : undefined
}

export const isNewContent = (post: Post | Video | Podcast): boolean =>
  differenceInMonths(new Date(), new Date(post.date)) < 1

export const getPostUrl = (post: Post): string => `/news/${post.id}/${post.slug}`

export const getMetaDescription = (post: Post | Video | Podcast): string =>
  truncate(htmlToText(post.excerpt.rendered || post.content.rendered), {
    length: 160,
  })
