import { stringify } from 'query-string'

export const buildUrl = (
  to: string,
  search?: object,
  replaceSearch?: object,
  currentSearch?: object
): string => {
  const params = replaceSearch ? replaceSearch : { ...currentSearch, ...search }
  return `${to}?${stringify(params)}`
}
