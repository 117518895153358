import React, { FC, useEffect, useState } from 'react'
import { useCurrentLanguage } from '../../locale/hooks'
import { useCurrentUser } from '../../user/hooks'
import { getCart } from '../api'
import { CartContext } from '../context'
import { Cart } from '../types'

const CartProvider: FC = ({ children }) => {
  const [user] = useCurrentUser()
  const [language] = useCurrentLanguage()
  const [cart, setCart] = useState<Cart>()
  const userId = user ? user.id : undefined

  useEffect(() => {
    getCart(language).then(({ data }) => setCart(data))
  }, [userId, language])

  return <CartContext.Provider value={{ cart, setCart }}>{children}</CartContext.Provider>
}

export default CartProvider
