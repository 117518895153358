import React, { FC, Fragment } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import Modal from '../../modal/components/Modal'

const messages = defineMessages({
  title: {
    id: 'user.RegistrationSuccess.title',
    description: 'Successful registration modal title',
    defaultMessage: 'Register',
  },
  message: {
    id: 'user.RegistrationSuccess.message',
    description: 'Successful registration modal message',
    defaultMessage: 'Thank you!',
  },
  description: {
    id: 'user.RegistrationSuccess.description',
    description: 'Successful registration modal description',
    defaultMessage:
      'To verify your registration, please check your E-Mail inbox and verify your E-Mail address.',
  },
})

const RegistrationSuccess: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <Modal.Header title={formatMessage(messages.title)} />
      <Modal.Body>
        <div className="columns is-centered">
          <div className="msg-success column has-text-centered is-two-thirds has-margin-top-1">
            <div className="picto-success has-margin-top-5 has-margin-bottom-4" />
            <h1 className="title is-4">{formatMessage(messages.title)}</h1>
            <h2 className="subtitle is-5">{formatMessage(messages.description)}</h2>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="is-borderless" />
    </Fragment>
  )
}

export default RegistrationSuccess
