import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Comments from '../../comments/components/Comments'
import SocialTabs from '../../posts/components/SocialTabs'
import { useCurrentUser } from '../../user/hooks'
import { useScrollTo } from '../../utils/hooks'
import { followTopic, unfollowTopic } from '../api'
import { Topic as TopicType } from '../types'
import Attachments from './Attachments'
import TopicMeta from './TopicMeta'

const Topic: FC<TopicType> = (props) => {
  const [currentUser] = useCurrentUser()
  const [isFollowed, setIsFollowed] = useState(props.isFollowed)
  const [isUpdating, setIsUpdating] = useState(false)
  const [commentsRef, scrollToComments] = useScrollTo<HTMLDivElement>()
  const toggleFollow = isFollowed ? unfollowTopic : followTopic
  const hasLiked = !!(currentUser && currentUser.likes.includes(props.id))

  return (
    <section className="section has-padding-top-4 has-padding-top-5-tablet">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-7">
            <div className="panel is-event">
              <div className="panel-heading">
                <div className="columns">
                  <div className="column is-10">
                    <h1 className="is-title is-5 has-text-weight-bold is-pulled-left">
                      {props.title}
                    </h1>
                  </div>
                  <div className="column is-2">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className={`button is-pulled-right is-medium is-outlined ${
                        isUpdating ? 'is-loading' : ''
                      }`}
                      onClick={async () => {
                        try {
                          setIsUpdating(true)
                          await toggleFollow(props.id)
                          setIsFollowed(!isFollowed)
                          setIsUpdating(false)
                        } catch (error) {
                          setIsUpdating(false)
                        }
                      }}
                    >
                      <span className="icon">
                        <i className="icon-eye" />
                      </span>
                      <span>
                        {isFollowed ? (
                          <FormattedMessage
                            id="topics.Topic.unfollow"
                            description="Unfollow button text in topic page"
                            defaultMessage="Unfollow"
                          />
                        ) : (
                          <FormattedMessage
                            id="topics.Topic.follow"
                            description="Follow button text in topic page"
                            defaultMessage="Follow"
                          />
                        )}
                      </span>
                    </a>
                  </div>
                </div>
                <SocialTabs
                  postId={props.id}
                  likes={props.likes}
                  canLike={!!currentUser}
                  hasLiked={hasLiked}
                  comments={props.comments}
                  onCommentsClick={scrollToComments}
                />
              </div>
              <div className="panel-content">
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
                <Attachments attachments={props.attachments} />
                <TopicMeta {...props} />
              </div>
            </div>
            <Comments postId={props.id} canComment={props.canComment} containerRef={commentsRef} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Topic
