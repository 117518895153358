import React, { FC } from 'react'
import { QuickviewType } from '../../quickview/enums'
import { useQuickview } from '../../quickview/hooks'

const MobileMenuButton: FC = () => {
  const { open } = useQuickview()

  return (
    <span
      role="button"
      className="navbar-burger navbar-slideout is-hidden-desktop has-margin-right-1"
      aria-label="menu"
      aria-expanded="false"
      onClick={() => open(QuickviewType.Menu)}
    >
      <i className="icon icon-hamburger-menu" />
    </span>
  )
}

export default MobileMenuButton
