import React, { FC } from 'react'
import LoadingVideoListItem from './LoadingVideoListItem'

const LoadingVideoList: FC = () => (
  <div className="columns is-multiline has-margin-top-3">
    <LoadingVideoListItem />
    <LoadingVideoListItem />
    <LoadingVideoListItem />
    <LoadingVideoListItem />
  </div>
)

export default LoadingVideoList
