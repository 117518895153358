import { noop } from 'lodash-es'
import React, { FC, useEffect, useState } from 'react'
import { useCurrentLanguage } from '../../locale/hooks'
import { useCurrentUser } from '../../user/hooks'
import { getCategories } from '../api'
import { CategoryContext } from '../context'
import { Category } from '../types'

const CategoryProvider: FC = ({ children }) => {
  const [user] = useCurrentUser()
  const [language] = useCurrentLanguage()
  const [categories, setCategories] = useState<Category[]>([])
  const [selectedCategories, setSelectedCategories] = useState<number[]>([])

  useEffect(() => {
    getCategories(language)
      .then(({ data }) => setCategories(data))
      .catch(noop)
  }, [language])

  useEffect(() => {
    user && setSelectedCategories(user.categories)
  }, [user])

  return (
    <CategoryContext.Provider
      value={{
        categories,
        selectedCategories,
        setCategories,
        setSelectedCategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}

export default CategoryProvider
