import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { LineItem } from '../types'

interface Props {
  item: LineItem
}

const OrderItem: FC<Props> = ({ item }) => (
  <div className="panel-block is-order-row">
    <div className="order-item">
      <span className="description">{item.name}</span>
      {item.downloads.map((url) => (
        <a key={url} href={url} className="download" target="_blank" rel="noopener noreferrer">
          <FormattedMessage
            id="orders.OrderItem.download"
            description="Download link in order page"
            defaultMessage="Download"
          />
        </a>
      ))}
    </div>
    <span className="price" dangerouslySetInnerHTML={{ __html: item.totalHtml }} />
  </div>
)

export default OrderItem
