import React, { FC } from 'react'
import { FormattedRelativeTime } from 'react-intl'
import { useTimeDifference } from '../../dates/hooks'
import Categories from '../../posts/components/Categories'
import Link from '../../router/components/Link'
import { Topic } from '../types'
import { getTopicUrl } from '../utils'

const FollowedTopic: FC<Topic> = (props) => {
  const diff = useTimeDifference(props.modified)

  return (
    <li className="has-margin-bottom-4">
      <Link to={getTopicUrl(props)} className="is-title">
        {props.title}
      </Link>
      <Categories categories={props.categories} className="has-margin-top-2 has-margin-bottom-3" />
      {diff && (
        <div className="date">
          <FormattedRelativeTime unit={diff.unit} value={diff.value} />
        </div>
      )}
    </li>
  )
}

export default FollowedTopic
