import React, { ElementType, FC } from 'react'
import { useCategories } from '../hooks'

interface Props {
  id: number
  as?: ElementType
  className?: string
}

const FormattedCategory: FC<Props> = ({ id, as: Component = 'span', className }) => {
  const [categories] = useCategories()

  const category = categories.find((aCategory) => aCategory.id === id)
  if (!category) {
    return null
  }

  return <Component className={className}>{category.name}</Component>
}

export default FormattedCategory
