import { noop } from 'lodash-es'
import { createContext } from 'react'
import { Cart } from './types'

interface Value {
  cart?: Cart
  setCart: (items: Cart) => void
}

export const CartContext = createContext<Value>({ setCart: noop })
