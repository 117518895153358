import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingPodcastListItem: FC = () => (
  <div className="column is-6-tablet is-4-desktop">
    <Skeleton height={206} />
  </div>
)

export default LoadingPodcastListItem
