import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingProduct: FC = () => (
  <section className="section has-padding-top-4 has-padding-top-5-tablet">
    <div className="container">
      <div className="columns is-multiline">
        <div className="column product-description is-6 has-margin-bottom-6-tablet">
          <h1 className="title is-3 has-margin-bottom-3">
            <Skeleton height={40} width="85%" />
          </h1>
          <div className="has-margin-bottom-4">
            <Skeleton height={25} width="35%" />
          </div>
          <div className="has-margin-bottom-4">
            <Skeleton height={30} width="20%" />
          </div>
          <p>
            <Skeleton width="85%" />
            <Skeleton width="75%" />
            <Skeleton width="80%" />
            <Skeleton width="70%" />
            <Skeleton width="60%" />
            <Skeleton width="65%" />
          </p>
        </div>
        <div className="column product-images is-6">
          <div className="is-hidden-mobile">
            <Skeleton height={490} />
          </div>
          <div className="is-hidden-tablet">
            <Skeleton height={200} />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default LoadingProduct
