import { xor } from 'lodash-es'
import React, { FC, Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCategories } from '../../../categories/hooks'
import { updateCategories } from '../../api'
import { useCurrentUser } from '../../hooks'
import Category from './Category'

const Categories: FC = () => {
  const [currentUser, setCurrentUser] = useCurrentUser()
  const [categories] = useCategories()
  const [isUpdating, setIsUpdating] = useState(false)

  if (!currentUser) {
    return null
  }

  return (
    <Fragment>
      <div className="has-padding-4">
        <h1 className="title is-5 has-margin-bottom-3">
          <FormattedMessage
            id="user.Categories.title"
            description="Title in user's selected categories"
            defaultMessage="Choose topics you are interested in:"
          />
        </h1>
        <p className="is-secondary">
          <FormattedMessage
            id="user.Categories.description"
            description="Description text in user's selected categories"
            defaultMessage="Here you can indicate which topics you are interested in."
          />
        </p>
      </div>
      <ul className="switch-list has-padding-x-3">
        {categories.map((category) => (
          <Category
            {...category}
            key={category.id}
            isSelected={currentUser.categories.includes(category.id)}
            isDisabled={isUpdating}
            onClick={async (id) => {
              try {
                if (!isUpdating) {
                  setIsUpdating(true)
                  const userId = currentUser.id
                  const categories = xor(currentUser.categories, [id])
                  const { data } = await updateCategories(userId, categories)
                  setCurrentUser(data)
                  setIsUpdating(false)
                }
              } catch (error) {
                setIsUpdating(false)
              }
            }}
          />
        ))}
      </ul>
    </Fragment>
  )
}

export default Categories
