import React, { FC, Fragment } from 'react'
import { parsePaginationHeaders } from '../../api/utils'
import Pagination from '../../bulma/components/Pagination'
import { useSelectedCategories } from '../../categories/hooks'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { useGetPodcasts } from '../hooks'
import { PodcastsFilterQueryParams } from '../types'
import LoadingPodcastsList from './LoadingPodcastsList'
import PodcastListItem from './PodcastListItem'

interface Props {
  perPage: number
  hidePagination?: boolean
}

const PodcastList: FC<Props> = ({ perPage, hidePagination }) => {
  const [language] = useCurrentLanguage()
  const [categories] = useSelectedCategories()
  const [query] = useQuery<PodcastsFilterQueryParams>()
  const [request] = useGetPodcasts({
    categories,
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: perPage,
    _embed: true,
    page: Number(query.page) || 1,
    lang: query.allLanguages === 'true' ? 'all' : language,
  })

  const { data: podcasts = [], isLoading, isInitial } = request

  if (isInitial && isLoading) {
    return <LoadingPodcastsList />
  }

  const { totalPages } = parsePaginationHeaders(request.headers || {})
  const paginationVisible = !hidePagination && podcasts.length > 0 && totalPages > 1

  return (
    <Fragment>
      <div className="columns is-multiline has-margin-top-3">
        {podcasts.map((podcast, i) => (
          <PodcastListItem key={podcast.id} position={i} {...podcast} />
        ))}
      </div>
      {paginationVisible && <Pagination pages={totalPages} />}
    </Fragment>
  )
}

export default PodcastList
