import { Field, Form, Formik } from 'formik'
import { get } from 'lodash-es'
import React, { FC } from 'react'
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl'
import { authenticate } from '../../auth/api'
import { useJwt } from '../../auth/hooks'
import Status from '../../formik/components/Status'
import SubmitButton from '../../formik/components/SubmitButton'
import TextField from '../../formik/components/TextField'
import Modal from '../../modal/components/Modal'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'
import { useQuery } from '../../router/hooks'
import { activateUser } from '../api'
import { useCurrentUser } from '../hooks'
import { useLoginSchema } from '../schema'

const messages = defineMessages({
  modalTitle: {
    id: 'user.LoginForm.modalTitle',
    description: 'Login modal title',
    defaultMessage: 'Log in',
  },
  email: {
    id: 'user.LoginForm.email',
    description: 'Email field in login form',
    defaultMessage: 'Email',
  },
  forgotPassword: {
    id: 'user.LoginForm.forgotPassword',
    description: 'Forgot password link under login form',
    defaultMessage: 'Forgot password?',
  },
  password: {
    id: 'user.LoginForm.password',
    description: 'Password field in login form',
    defaultMessage: 'Password',
  },
  totp: {
    id: 'user.LoginForm.totp',
    description: 'Google Authenticator code field in login form',
    defaultMessage: 'Google Authenticator code',
  },
  submit: {
    id: 'user.LoginForm.submit',
    description: 'Submit button in login form',
    defaultMessage: 'Log in',
  },
  loginFailed: {
    id: 'user.LoginForm.loginFailed',
    description: 'Failed to login message in login form',
    defaultMessage: 'Invalid email or password.',
  },
})

interface TextFieldProps {
  name: string
  label: MessageDescriptor
  type: string
  maxLength?: number
  autoComplete?: string
}

const textFields: Record<number, TextFieldProps[]> = {
  1: [
    {
      name: 'email',
      type: 'email',
      label: messages.email,
      autoComplete: 'username',
    },
    {
      name: 'password',
      type: 'password',
      label: messages.password,
      autoComplete: 'current-password',
    },
  ],
  2: [
    {
      name: 'totp',
      type: 'text',
      label: messages.totp,
      autoComplete: 'one-time-code',
      maxLength: 6,
    },
  ],
}

const LoginForm: FC = () => {
  const validationSchema = useLoginSchema()
  const { formatMessage } = useIntl()
  const { open, close } = useModal()
  const [query, setQuery] = useQuery<{ token: string }>()
  const [, setJwt] = useJwt()
  const [, setUser] = useCurrentUser()

  return (
    <Formik
      initialValues={{ email: '', password: '', totp: '', step: 1 }}
      validationSchema={validationSchema}
      onSubmit={async ({ email, ...values }, form) => {
        try {
          form.setStatus(undefined)

          if (query.token) {
            await activateUser(email, query.token)
          }

          const response = await authenticate({ username: email, ...values })
          setJwt(response.data)
          setUser(response.data.user)

          if (query.token) {
            setQuery({ token: undefined })
            open(ModalType.ImproveProfile)
          } else {
            close()
          }
        } catch (error) {
          const errorCode = get(error, 'response.data.code')
          const messagePath = 'response.data.errors.rest_jwt_auth_failure[0]'
          const message = get(error, messagePath)

          if (values.step === 1 && errorCode === 'totp_missing') {
            form.setFieldTouched('totp', false)
            form.setFieldValue('step', 2)
          } else {
            form.setStatus(message || formatMessage(messages.loginFailed))
          }

          form.setSubmitting(false)
        }
      }}
    >
      {({ values }) => (
        <Form noValidate>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <Status />
            <div className="columns">
              <div className="column is-half is-offset-one-quarter has-margin-top-4">
                {textFields[values.step].map(({ label, ...field }) => (
                  <Field
                    {...field}
                    key={field.name}
                    component={TextField}
                    label={formatMessage(label)}
                    required
                  />
                ))}
                <div className="field is-grouped">
                  <SubmitButton>{formatMessage(messages.submit)}</SubmitButton>
                  {values.step === 1 && (
                    <div className="control">
                      <button
                        type="button"
                        className="button is-text is-medium has-text-weight-bold"
                        onClick={() => open(ModalType.ForgotPassword)}
                      >
                        {formatMessage(messages.forgotPassword)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="is-borderless has-padding-y-3" />
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
