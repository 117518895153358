import { AxiosRequestConfig } from 'axios'
import { DependencyList, useEffect, useReducer } from 'react'
import axios, { CancelToken, isCancel } from './axios'
import { axiosReducer, infiniteScrollReducer } from './reducers'
import * as t from './types'

export const useAxios = <T>(
  config: AxiosRequestConfig,
  deps: DependencyList = []
): t.UseAxios<T> => {
  const [state, dispatch] = useReducer<t.AxiosReducer<T>>(axiosReducer, {
    isError: false,
    isLoading: true,
    isInitial: true,
  })

  const makeRequest = (): (() => void) => {
    dispatch({ type: 'load' })

    const source = CancelToken.source()

    axios
      .request<T>({ ...config, cancelToken: source.token })
      .then(({ status, data, headers }) => {
        if (status >= 400) {
          dispatch({ type: 'error' })
        } else {
          dispatch({ type: 'success', payload: { data, headers } })
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          dispatch({ type: 'error' })
        }
      })

    return () => {
      source.cancel()
    }
  }

  useEffect(makeRequest, deps)

  return [state, makeRequest]
}

export const useInfiniteScroll = <T>(config: AxiosRequestConfig): t.UseInfiniteScroll<T> => {
  const [state, dispatch] = useReducer<t.InfiniteScrollReducer<T>>(infiniteScrollReducer, {
    items: [],
    total: 0,
    page: 1,
    hasMore: true,
    isError: false,
    isLoading: true,
  })

  config.params.page = state.page

  const makeRequest = (): (() => void) => {
    const source = CancelToken.source()

    axios
      .request<T[]>({ ...config, cancelToken: source.token })
      .then(({ data, headers, status }) => {
        if (status >= 400) {
          dispatch({ type: 'error' })
        } else {
          dispatch({ type: 'success', payload: { data, headers } })
        }
      })
      .catch((error) => {
        if (isCancel(error)) {
          return
        }
      })

    return () => {
      source.cancel()
    }
  }

  useEffect(makeRequest, [state.page])

  return [state, () => dispatch({ type: 'load' })]
}
