import { truncate } from 'lodash-es'
import { htmlToText } from '../utils'
import { Product } from './types'

export const getProductUrl = (product: Product): string => `/shop/${product.id}/${product.slug}`

export const getMetaDescription = (product: Product): string =>
  truncate(htmlToText(product.excerptHtml || product.descriptionHtml), {
    length: 160,
  })
