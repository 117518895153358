import { BillingAddress } from '../user/types'
import { BillingAddressFormValues } from './types'

export const getCreateSourceOptions = (
  holder: string,
  address?: BillingAddress | BillingAddressFormValues
): stripe.SourceOptions => {
  const options: stripe.SourceOptions = {
    type: 'card',
    usage: 'reusable',
    currency: 'eur',
    owner: {
      name: holder,
    },
  }

  if (address) {
    options.owner = {
      ...options.owner,
      email: address.email,
      phone: address.phone || undefined,
      address: {
        line1: address.address1,
        city: address.city,
        state: address.state,
        // eslint-disable-next-line @typescript-eslint/camelcase
        postal_code: address.postcode,
        country: address.country,
      },
    }
  }

  return options
}
