import React, { FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import avatar from '../../../assets/img/ico_profile.svg'
import Image from '../../../bulma/components/Image'
import SubmitButton from '../../../formik/components/SubmitButton'
import { useModal } from '../../../modal/hooks'

const ConfirmationStep: FC = () => {
  const { close } = useModal()

  return (
    <Fragment>
      <div className="columns is-centered confirmation-step">
        <div className="column is-8 has-text-centered">
          <div className="has-margin-y-5">
            <Image className="is-96x96 is-placeholder" src={avatar} isRounded />
          </div>
          <p className="has-text-weight-bold has-margin-y-4">
            <FormattedMessage
              id="user.ImproveProfileModal.ConfirmationStep.description"
              description="Description text in improve profile modal"
              defaultMessage="To improve your ORRECA experience, please complete your user profile"
            />
          </p>
          <div className="buttons is-centered">
            <SubmitButton
              type="button"
              color="text"
              onClick={close}
              className="has-text-weight-bold is-outlined"
            >
              <FormattedMessage
                id="user.ImproveProfileModal.ConfirmationStep.skip"
                description="Skip button in improve profile modal"
                defaultMessage="Not now"
              />
            </SubmitButton>
            <SubmitButton color="link" className="has-text-weight-bold">
              <FormattedMessage
                id="user.ImproveProfileModal.ConfirmationStep.continue"
                description="Continue button in improve profile modal"
                defaultMessage="Finalize profile"
              />
            </SubmitButton>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ConfirmationStep
