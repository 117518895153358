import classNames from 'classnames'
import { FieldProps } from 'formik'
import { get } from 'lodash-es'
import React, { FC } from 'react'
import ErrorMessage from './ErrorMessage'

interface Props extends FieldProps {
  label: string
  isNarrow?: boolean
  helpText?: string
  hasLink?: boolean
}

const HorizontalField: FC<Props> = ({
  field,
  form,
  label,
  isNarrow,
  helpText,
  hasLink,
  children,
  ...props
}) => {
  const isTouched = get(form.touched, field.name)
  const hasError = isTouched && get(form.errors, field.name)
  const inputClasses = ['input', 'is-medium', hasError && 'is-danger']

  return (
    <div className="field is-horizontal">
      <div className="field-label is-medium">
        <label htmlFor={field.name} className="label">
          {label}
        </label>
      </div>
      <div className="field-body">
        <div className={classNames(['field', isNarrow && 'is-narrow'])}>
          <div className={`control ${hasLink ? 'has-link' : ''}`}>
            <input className={classNames(inputClasses)} {...field} {...props} />
            {children}
          </div>
          <ErrorMessage name={field.name} />
          {helpText && <p className="help is-medium">{helpText}</p>}
        </div>
      </div>
    </div>
  )
}

export default HorizontalField
