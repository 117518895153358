import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalType } from '../../../modal/enums'
import { useModal } from '../../../modal/hooks'
import CardDetails from '../../../user/components/CardDetails'
import { useCurrentUser } from '../../../user/hooks'

const PaymentMethod: FC = () => {
  const { open } = useModal()
  const [currentUser] = useCurrentUser()
  if (!currentUser) {
    return null
  }

  const card = currentUser.cardDetails

  return (
    <div className="panel">
      <p className="panel-heading">
        <FormattedMessage
          id="account.Billing.PaymentMethod.heading"
          description="Payment method card heading in billing account section"
          defaultMessage="Payment Method"
        />
      </p>
      <div className="panel-block is-column has-padding-bottom-4">
        {card && <CardDetails {...card} />}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => open(ModalType.PaymentMethod)} className="button is-outlined is-medium">
          {card ? (
            <FormattedMessage
              id="account.Billing.PaymentMethod.changeCard"
              description="Change card button in billing account section"
              defaultMessage="Change card"
            />
          ) : (
            <FormattedMessage
              id="account.Billing.PaymentMethod.addCard"
              description="Change card button in billing account section"
              defaultMessage="Add card"
            />
          )}
        </a>
      </div>
    </div>
  )
}

export default PaymentMethod
