import { FormikContextType, connect } from 'formik'
import React, { FC, useState } from 'react'
import Notification from '../../bulma/components/Notification'
import { useCart } from '../../cart/hooks'
import PayPalButton from '../../paypal/components/PayPalButton'
import Redirect from '../../router/components/Redirect'
import { useCurrentUser } from '../../user/hooks'
import { captureOrder, createOrder } from '../api'
import { CheckoutFormValues } from '../types'

interface Props {
  formik: FormikContextType<CheckoutFormValues>
}

const PaypalForm: FC<Props> = ({ formik }) => {
  const [cart, { updateCart }] = useCart()
  const [currentUser] = useCurrentUser()
  const [orderKey, setOrderKey] = useState<string | undefined>()
  const { values, isValid } = formik

  if (orderKey) {
    return <Redirect to={`/shop/orders/${orderKey}?success=true`} />
  }

  if (!isValid) {
    return <Notification type="warning">Please fill out the form</Notification>
  }

  return (
    <PayPalButton
      onCancel={() => formik.setSubmitting(false)}
      createOrder={async () => {
        const billingAddress = values.useOtherBillingAddress
          ? values.billingAddress
          : currentUser && currentUser.billingAddress

        if (!billingAddress) {
          return ''
        }

        let shippingAddress
        if (cart && cart.needsShipping) {
          shippingAddress = values.useOtherShippingAddress
            ? values.shippingAddress
            : currentUser && currentUser.shippingAddress
        }

        try {
          formik.setStatus(undefined)
          formik.setSubmitting(true)

          const { data } = await createOrder({
            billingAddress,
            shippingAddress,
          })

          return data.orderId
        } catch (error) {
          formik.setSubmitting(false)
          formik.setStatus('Something went wrong')
          return ''
        }
      }}
      onApprove={async ({ orderID }) => {
        try {
          formik.setSubmitting(true)
          const { data } = await captureOrder(orderID)
          updateCart()
          setOrderKey(data.key)
          formik.setSubmitting(false)
        } catch (error) {
          formik.setSubmitting(false)
          formik.setStatus('Something went wrong')
        }
      }}
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: 'EUR',
        intent: 'capture',
        commit: true,
      }}
    />
  )
}

export default connect<{}, CheckoutFormValues>(PaypalForm)
