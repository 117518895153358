import React, { FC, Fragment, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Modal from '../../../modal/components/Modal'
import { useModal } from '../../../modal/hooks'
import { disableTwoFactor } from '../../../user/api'
import { useCurrentUser } from '../../../user/hooks'

const messages = defineMessages({
  modalTitle: {
    id: 'account.Security.DisableTwoFactor.modalTitle',
    description: 'Two factor disabling modal title',
    defaultMessage: 'Disable two-factor authentication',
  },
})

const DisableTwoFactor: FC = () => {
  const { close } = useModal()
  const { formatMessage } = useIntl()
  const [user, setUser] = useCurrentUser()
  const [isLoading, setIsLoading] = useState(false)
  if (!user) {
    return null
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await disableTwoFactor(user.id)
      setIsLoading(false)
      setUser(response.data)
      close()
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Fragment>
      <Modal.Header title={formatMessage(messages.modalTitle)} />
      <Modal.Body>
        <p className="has-margin-bottom-4">
          <FormattedMessage
            id="account.Security.DisableTwoFactor.notice"
            description="Security notice in two factor disabling modal"
            defaultMessage="This reduces the security level of your account and is not recommended."
          />
        </p>
        <p>
          <FormattedMessage
            id="account.Security.DisableTwoFactor.confirmation"
            description="Confirmation question in two factor disabling modal"
            defaultMessage="Are you sure you want to continue?"
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="button is-light is-medium is-medium has-text-weight-bold">
          <FormattedMessage
            id="account.Security.DisableTwoFactor.cancel"
            description="Cancel button text in two factor disabling modal"
            defaultMessage="Cancel"
          />
        </button>
        <button
          type="button"
          disabled={isLoading}
          onClick={handleSubmit}
          className={`button is-danger is-medium is-medium has-text-weight-bold ${
            isLoading ? 'is-loading' : ''
          }`}
        >
          <FormattedMessage
            id="account.Security.DisableTwoFactor.confirm"
            description="Confirm button text in two factor disabling modal"
            defaultMessage="Yes, disable"
          />
        </button>
      </Modal.Footer>
    </Fragment>
  )
}

export default DisableTwoFactor
