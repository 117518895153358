import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import PremiumBlock from '../../posts/components/PremiumBlock'
import SignupBlock from '../../posts/components/SignupBlock'
import { useCurrentUser } from '../../user/hooks'
import TopicList from './TopicList'

const messages = defineMessages({
  premiumBlockTitle: {
    id: 'topics.PremiumPreview.premiumBlockTitle',
    description: 'Premium block title in topics premium preview',
    defaultMessage: 'This is a premium feature',
  },
  premiumBlockDescription: {
    id: 'topics.PremiumPreview.premiumBlockDescription',
    description: 'Premium block description in topics premium preview',
    defaultMessage:
      'Q&A functionality is a subscription based service that you will find really useful in your everyday work. This is an online consultancy functionality, where you can post questions to whole community of experts and you can browse through the discussion items that you might find useful for you. In addition to this, you can also post private questions directly to the field experts and these topics will stay anonymous from the community. By using this option, we can help you with reviewing your audit documents or answer any other specific questions that will help you to be covered if you are overloaded or need any assistance or guiding in your daily work.',
  },
  premiumBlockButton: {
    id: 'topics.PremiumPreview.premiumBlockButton',
    description: 'Premium block button text in topics premium preview',
    defaultMessage: 'Subscribe now',
  },
  listHeading: {
    id: 'topics.PremiumPreview.listHeading',
    description: 'Topics list heading in topics premium preview',
    defaultMessage: 'Q&A Topics',
  },
  createTopic: {
    id: 'topics.PremiumPreview.createTopic',
    description: 'Create new topic button text in topics premium preview',
    defaultMessage: 'Start new topic',
  },
})

const PremiumPreview: FC = () => {
  const [currentUser] = useCurrentUser()
  const { formatMessage } = useIntl()

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <div className="container">
        <div className="columns is-clearfix is-centered">
          <div className="column is-8 has-padding-bottom-0">
            {currentUser ? (
              <PremiumBlock
                icon="crown"
                title={formatMessage(messages.premiumBlockTitle)}
                description={formatMessage(messages.premiumBlockDescription)}
                buttonText={formatMessage(messages.premiumBlockButton)}
              />
            ) : (
              <SignupBlock
                icon="crown"
                title={formatMessage(messages.premiumBlockTitle)}
                description={formatMessage(messages.premiumBlockDescription)}
              />
            )}
            <h1 className="title is-3 is-pulled-left has-margin-bottom-0 has-margin-left-4 is-hidden-mobile has-text-grey-light">
              {formatMessage(messages.listHeading)}
            </h1>
            <button className="button is-primary is-pulled-right is-button-block" disabled>
              {formatMessage(messages.createTopic)}
            </button>
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-8 is-locked has-margin-bottom-6">
            <TopicList isPreview />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PremiumPreview
