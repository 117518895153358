import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ProductsFilterQueryParams } from '../../../products/types'
import { useQuery } from '../../../router/hooks'
import { useDebounce } from '../../../utils/hooks'

const messages = defineMessages({
  searchPlaceholder: {
    id: 'shop.FilterPanel.NameFilter.searchPlaceholder',
    description: 'Placeholder text in mobile product list filter',
    defaultMessage: 'Search from shop',
  },
})

const NameFilter: FC = () => {
  const [query, setQuery] = useQuery<ProductsFilterQueryParams>()
  const [value, setValue] = useState(query.search || '')
  const { formatMessage } = useIntl()

  useDebounce(() => setQuery({ search: value || undefined }), 350, [value])

  return (
    <div className="field is-search has-margin-bottom-5">
      <div className="control has-icons-right">
        <input
          type="text"
          value={value}
          className="input is-rounded is-medium"
          placeholder={formatMessage(messages.searchPlaceholder)}
          onChange={(event) => setValue(event.target.value)}
        />
        <span className="icon is-small is-right">
          <i className="icon icon-dark-search" />
        </span>
      </div>
    </div>
  )
}

export default NameFilter
