import React, { FC, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useConsents } from '../../consents/hooks'
import { useCurrentUser } from '../../user/hooks'
import { LanguageContext } from '../context'
import { Language } from '../enums'
import { useDefaultLanguage } from '../hooks'
import germanTranslations from '../translations/de.json'
import * as utils from '../utils'

const translations: { [key in Language]: { [key: string]: string } } = {
  [Language.English]: {},
  [Language.German]: germanTranslations,
}

const LanguageProvider: FC = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch<{ lang: string }>({ path: '/(en|de)' })
  const defaultLanguage = useDefaultLanguage()
  const [language, setLanguage] = useState<Language>(defaultLanguage)
  const [consents] = useConsents()
  const [user] = useCurrentUser()

  useEffect(() => {
    if (user && user.locale && !utils.getLanguageFromCookie()) {
      setLanguage(user.locale as Language)
    }
  }, [user])

  const canStoreLanguage = !!(consents && consents.comfort)

  useEffect(() => {
    canStoreLanguage && utils.storeLanguageInCookie(language)
  }, [canStoreLanguage, language])

  useEffect(() => {
    if (!match) {
      const pathname = `/${language}${location.pathname}`
      history.replace({ ...location, pathname })
    }
  }, [history, language, location, match])

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <IntlProvider locale={language} messages={translations[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
