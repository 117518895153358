import { Form, Formik } from 'formik'
import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { injectStripe, ReactStripeElements } from 'react-stripe-elements'
import StripeForm from '../../../checkout/components/StripeForm'
import { getCreateSourceOptions } from '../../../checkout/utils'
import Status from '../../../formik/components/Status'
import SubmitButton from '../../../formik/components/SubmitButton'
import Modal from '../../../modal/components/Modal'
import { useModal } from '../../../modal/hooks'
import { addPaymentMethod, getUser } from '../../../user/api'
import { useCurrentUser } from '../../../user/hooks'
import { usePaymentMethodSchema } from '../../schema'
import { PaymentMethodFormValues } from '../../types'

const messages = defineMessages({
  modalTitle: {
    id: 'account.Billing.PaymentMethodForm.modalTitle',
    description: 'Payment method modal title',
    defaultMessage: 'Payment method',
  },
  saveFailed: {
    id: 'account.Billing.PaymentMethodForm.saveFailed',
    description: 'General error message for payment method update failure',
    defaultMessage: "Sorry! We couldn't save your payment method. Please try again later.",
  },
})

const initialValues: PaymentMethodFormValues = {
  paymentMethod: {
    cardDetails: {
      holder: '',
      cvcValid: false,
      expirationDateValid: false,
      numberValid: false,
    },
  },
}

type Props = ReactStripeElements.InjectedStripeProps

const PaymentMethodForm = ({ stripe }: Props) => {
  const [currentUser, setCurrentUser] = useCurrentUser()
  const { formatMessage } = useIntl()
  const { close } = useModal()
  const validationSchema = usePaymentMethodSchema()

  if (!currentUser) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          if (!stripe) {
            throw new Error('Stripe not initialized')
          }

          actions.setStatus(undefined)

          const cardHolder = values.paymentMethod.cardDetails.holder
          const billingAddress = currentUser.billingAddress
          const options = getCreateSourceOptions(cardHolder, billingAddress)

          const { error, source } = await stripe.createSource(options)
          if (error || !source) {
            throw new Error('Failed to create Stripe source')
          }

          await addPaymentMethod(currentUser.id, source.id)

          const { data: user } = await getUser(currentUser.id)
          setCurrentUser(user)

          actions.setSubmitting(false)

          close()
        } catch (error) {
          actions.setStatus(formatMessage(messages.saveFailed))
          actions.setSubmitting(false)
        }
      }}
    >
      {() => (
        <Form noValidate>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <Status />
            <div className="columns is-centered has-margin-top-4">
              <div className="column is-10">
                <StripeForm />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="is-aligned-right">
            <Fragment>
              <button
                type="button"
                className="button is-text is-medium has-text-weight-bold is-pulled-right"
                onClick={() => close()}
              >
                <FormattedMessage
                  id="account.Billing.PaymentMethodForm.cancel"
                  description="Cancel button in payment method modal"
                  defaultMessage="Cancel"
                />
              </button>
              <SubmitButton className="is-pulled-right">
                <FormattedMessage
                  id="account.Billing.PaymentMethodForm.save"
                  description="Save button in payment method modal"
                  defaultMessage="Save"
                />
              </SubmitButton>
            </Fragment>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default injectStripe(PaymentMethodForm)
