/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePagination } from '../../../utils/hooks'
import Ellipsis from './Ellipsis'
import Link from './Link'

interface Props {
  pages: number
}

const Pagination: FC<Props> = ({ pages }) => {
  const [page, setPage] = usePagination()

  const hasPreviousPages = page > 1
  const hasMorePages = page < pages

  return (
    <nav
      className="pagination is-centered has-margin-top-3 has-margin-bottom-5"
      role="navigation"
      aria-label="pagination"
    >
      <a
        className="pagination-previous"
        // @ts-ignore
        disabled={!hasPreviousPages}
        onClick={() => hasPreviousPages && setPage(page - 1)}
      >
        <FormattedMessage
          id="bulma.Pagination.previous"
          description="Previous button in pagination"
          defaultMessage="Previous"
        />
      </a>
      <a
        className="pagination-next"
        // @ts-ignore
        disabled={!hasMorePages}
        onClick={() => hasMorePages && setPage(page + 1)}
      >
        <FormattedMessage
          id="bulma.Pagination.next"
          description="Next button in pagination"
          defaultMessage="Next"
        />
      </a>
      <ul className="pagination-list">
        {hasPreviousPages && (
          <Fragment>
            <Link page={1} onClick={() => setPage(1)} />
            {page - 1 > 1 && (
              <Fragment>
                {page - 2 > 1 && <Ellipsis />}
                <Link page={page - 1} onClick={() => setPage(page - 1)} />
              </Fragment>
            )}
          </Fragment>
        )}
        <Link page={page} onClick={() => setPage(page)} isCurrent />
        {hasMorePages && (
          <Fragment>
            {page + 1 < pages && (
              <Fragment>
                <Link page={page + 1} onClick={() => setPage(page + 1)} />
                {page + 2 < pages && <Ellipsis />}
              </Fragment>
            )}
            <Link page={pages} onClick={() => setPage(pages)} />
          </Fragment>
        )}
      </ul>
    </nav>
  )
}

export default Pagination
