import React, { FC } from 'react'
import { GalleryImage } from '../types'

type Props = GalleryImage & {
  className?: string
  onClick?: () => void
}

const GalleryThumbnail: FC<Props> = ({ sizes, alt, onClick, className }) => (
  <div className={className} onClick={onClick}>
    <figure className="image is-1by1">
      <img src={sizes.thumbnail.src} alt={alt} />
    </figure>
  </div>
)

export default GalleryThumbnail
