import React, { FC, Fragment } from 'react'
import LoadingPost from './LoadingPost'

const LoadingPosts: FC = () => (
  <Fragment>
    <div className="column is-6-tablet is-8-desktop">
      <LoadingPost size="large" />
    </div>
    <div className="column is-4">
      <LoadingPost />
    </div>
    <div className="column is-4">
      <LoadingPost />
    </div>
    <div className="column is-4">
      <LoadingPost />
    </div>
  </Fragment>
)

export default LoadingPosts
