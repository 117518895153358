import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuickview } from '../../quickview/hooks'
import Link from '../../router/components/Link'
import { useLogout } from '../../user/hooks'

const LoggedInButtons: FC = () => {
  const logout = useLogout()
  const { close } = useQuickview()
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  return (
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-12 has-margin-top-4 has-padding-bottom-3">
          <Link
            to="/account/profile"
            className="button is-medium is-primary is-outlined is-fullwidth"
            onClick={close}
          >
            <FormattedMessage
              id="header.LoggedInButtons.profile"
              description="User profile button in mobile menu"
              defaultMessage="Profile & Settings"
            />
          </Link>
        </div>
        <div className="column is-12 has-margin-bottom-4 has-padding-top-3">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={`button is-medium is-danger is-fullwidth has-text-weight-bold ${
              isLoggingOut ? 'is-loading' : ''
            }`}
            onClick={async () => {
              if (!isLoggingOut) {
                setIsLoggingOut(true)
                await logout()
                setIsLoggingOut(false)
                close()
              }
            }}
          >
            <FormattedMessage
              id="header.LoggedInButtons.logout"
              description="Log out button in mobile menu"
              defaultMessage="Log out"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LoggedInButtons
