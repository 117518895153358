import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  size: 'medium' | 'large'
}

const PremiumTag: FC<Props> = ({ size }) => (
  <span className={`tag is-${size} is-premium is-rounded has-margin-left-2`}>
    <FormattedMessage
      id="posts.PremiumTag.text"
      description="Premium tag for different content types"
      defaultMessage="Premium"
    />
  </span>
)

export default PremiumTag
