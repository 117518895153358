import React, { FC, useState } from 'react'
import { QuickviewContext } from '../context'
import { QuickviewType } from '../enums'

const QuickviewProvider: FC = ({ children }) => {
  const [quickview, setQuickview] = useState<QuickviewType | undefined>()

  return (
    <QuickviewContext.Provider value={{ quickview, setQuickview }}>
      {children}
    </QuickviewContext.Provider>
  )
}

export default QuickviewProvider
