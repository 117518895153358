import { times } from 'lodash-es'
import React, { FC, Fragment } from 'react'
import { GalleryImage } from '../types'
import GalleryThumbnail from './GalleryThumbnail'

interface Props {
  images: GalleryImage[]
  onClick?: (i: number) => void
}

const GalleryThumbnails: FC<Props> = ({ images, onClick }) => {
  const visibleImages = images.slice(0, 5)

  return (
    <Fragment>
      {times(5, (i) => {
        const className = `column is-${i > 2 ? 'hidden' : '4'}-mobile`
        const image = visibleImages[i]
        if (!image) {
          return <div key={i} className={className} />
        }

        return (
          <GalleryThumbnail
            {...image}
            key={image.id}
            className={className}
            onClick={() => {
              onClick && onClick(i)
            }}
          />
        )
      })}
    </Fragment>
  )
}

export default GalleryThumbnails
