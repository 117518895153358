import { useContext } from 'react'
import { useCurrentLanguage } from '../locale/hooks'
import * as api from './api'
import { CartContext } from './context'
import { UseCart } from './types'

export const useCart = (): UseCart => {
  const [language] = useCurrentLanguage()
  const { cart, setCart } = useContext(CartContext)

  const updateCart = (): Promise<void> => api.getCart(language).then(({ data }) => setCart(data))

  const emptyCart = (): Promise<void> => api.emptyCart().then(({ data }) => setCart(data))

  const addItem = (productId: number, quantity = 1): Promise<void> =>
    api.addToCart(productId, quantity).then(({ data }) => setCart(data))

  const removeItem = (item: string): Promise<void> =>
    api.removeFromCart(item).then(({ data }) => setCart(data))

  return [cart, { addItem, removeItem, updateCart, emptyCart }]
}
