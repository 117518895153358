import { lowerCase } from 'lodash-es'
import React, { FC, Fragment } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { ModalType } from '../../../modal/enums'
import { useModal } from '../../../modal/hooks'
import { periodTextRecord } from '../../../subscriptions/utils'
import { useCurrentUser } from '../../../user/hooks'
import CancelPlanButton from './CancelPlanButton'

const Plan: FC = () => {
  const [currentUser] = useCurrentUser()
  const { formatMessage } = useIntl()
  const { open } = useModal()
  if (!currentUser) {
    return null
  }

  const { subscription } = currentUser
  const canManagePlan = subscription && !subscription.isManualRenewal

  return (
    <div className="panel">
      <p className="panel-heading">
        <FormattedMessage
          id="account.Billing.Plan.heading"
          description="Plan card heading in billing account section"
          defaultMessage="Your plan"
        />
      </p>
      <div className="panel-block is-column is-value-pair">
        <div className="selected-plan title is-3 has-margin-y-0">
          {subscription ? (
            formatMessage(periodTextRecord[subscription.period])
          ) : (
            <FormattedMessage
              id="account.Billing.Plan.free"
              description="Free plan text in billing account section"
              defaultMessage="Free"
            />
          )}
        </div>
        <p className="is-secondary has-margin-bottom-3">
          {subscription && (
            <Fragment>
              <span dangerouslySetInnerHTML={{ __html: subscription.price }} />
              {' / '}
              {lowerCase(formatMessage(periodTextRecord[subscription.period]))}.{' '}
              {subscription.isManualRenewal ? (
                <FormattedMessage
                  id="account.Billing.Plan.validUntil"
                  description="Valid until date text in billing account section"
                  defaultMessage="Valid until {date}."
                  values={{
                    date: <FormattedDate value={subscription.nextPayment} />,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="account.Billing.Plan.nextPayment"
                  description="Next payment date text in billing account section"
                  defaultMessage="Next payment on {date}."
                  values={{
                    date: <FormattedDate value={subscription.nextPayment} />,
                  }}
                />
              )}
            </Fragment>
          )}
        </p>
        <div className="actions has-margin-bottom-2">
          {subscription ? (
            <Fragment>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => open(ModalType.Subscribe)} className="button is-primary is-medium">
                <FormattedMessage
                  id="account.Billing.Plan.changePlan"
                  description="Change plan button in billing account section"
                  defaultMessage="Change plan"
                />
              </a>
            </Fragment>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={() => open(ModalType.Subscribe)} className="button is-primary is-medium">
              <FormattedMessage
                id="account.Billing.Plan.choosePlan"
                description="Choose plan button in billing account section"
                defaultMessage="Choose plan"
              />
            </a>
          )}
          {canManagePlan && <CancelPlanButton />}
        </div>
      </div>
    </div>
  )
}

export default Plan
