import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'custom-event-polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './assets/css/main.css'
import './assets/css/styles.css'
import { registerLocale } from 'i18n-iso-countries'
import deCountries from 'i18n-iso-countries/langs/de.json'
import enCountries from 'i18n-iso-countries/langs/en.json'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import JwtProvider from './auth/components/JwtProvider'
import CartProvider from './cart/components/CartProvider'
import CategoryProvider from './categories/components/CategoryProvider'
import ConsentsProvider from './consents/components/ConsentsProvider'
import LanguageProvider from './locale/components/LanguageProvider'
import ModalProvider from './modal/components/ModalProvider'
import QuickviewProvider from './quickview/components/QuickviewProvider'
import UserProvider from './user/components/UserProvider'
import { nest } from './utils'

registerLocale(enCountries)
registerLocale(deCountries)

const Providers = nest(
  BrowserRouter,
  ModalProvider,
  QuickviewProvider,
  ConsentsProvider,
  JwtProvider,
  UserProvider,
  LanguageProvider,
  CategoryProvider,
  CartProvider
)

const createApp = (): JSX.Element => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  <Providers>
    <App />
  </Providers>
)

ReactDOM.render(createApp(), document.getElementById('root'))
