import { noop } from 'lodash-es'
import { createContext } from 'react'
import { SetState } from '../utils/types'
import { Consents } from './types'

interface Value {
  consents?: Consents
  setConsents: SetState<Consents | undefined>
}

export const ConsentsContext = createContext<Value>({ setConsents: noop })
