import { Field, Form, Formik } from 'formik'
import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import CaptchaField from '../../formik/components/CaptchaField'
import FileField from '../../formik/components/FileField'
import SelectField from '../../formik/components/SelectField'
import Status from '../../formik/components/Status'
import SubmitButton from '../../formik/components/SubmitButton'
import TextArea from '../../formik/components/TextArea'
import TextField from '../../formik/components/TextField'
import { useCurrentLanguage } from '../../locale/hooks'
import Redirect from '../../router/components/Redirect'
import { createAppointment } from '../api'
import { TimePreference } from '../enums'
import { useGetAppointmentTypes } from '../hooks'
import { useAppointmentSchema } from '../schema'

const messages = defineMessages({
  heading: {
    id: 'appointments.AppointmentForm.heading',
    description: 'Appointment form heading',
    defaultMessage: 'Need help?',
  },
  subheading: {
    id: 'appointments.AppointmentForm.subheading',
    description: 'Appointment form subheading',
    defaultMessage: 'Sign up for an appointment',
  },
  appointmentTypeLabel: {
    id: 'appointments.AppointmentForm.appointmentTypeLabel',
    description: 'Appointment type label in appointment form',
    defaultMessage: 'Appointment type',
  },
  descriptionLabel: {
    id: 'appointments.AppointmentForm.descriptionLabel',
    description: 'Description label in appointment form',
    defaultMessage: 'Description',
  },
  timePreferenceLabel: {
    id: 'appointments.AppointmentForm.timePreferenceLabel',
    description: 'Time preference label in appointment form',
    defaultMessage: 'Time preference',
  },
  timePreferenceCallBack: {
    id: 'appointments.AppointmentForm.timePreferenceCallBack',
    description: "Time preference option 'Call me back'",
    defaultMessage: 'Call me back',
  },
  timePreferenceOfferDate: {
    id: 'appointments.AppointmentForm.timePreferenceOfferDate',
    description: "Time preference option 'Offer me a coaching date'",
    defaultMessage: 'Offer me a coaching date',
  },
  timePreferenceCustom: {
    id: 'appointments.AppointmentForm.timePreferenceCustom',
    description: 'Time preference option for selecting a custom datetime in appointment form',
    defaultMessage: 'Select a custom date and time',
  },
  specifyTimeLabel: {
    id: 'appointments.AppointmentForm.specifyTimeLabel',
    description: 'Specify time label in appointment form',
    defaultMessage: 'Specify time',
  },
  attachmentsLabel: {
    id: 'appointments.AppointmentForm.attachmentsLabel',
    description: 'Attachments label in appointment form',
    defaultMessage: 'Attachments',
  },
  submit: {
    id: 'appointments.AppointmentForm.attachmentsSubmit',
    description: 'Submit button title in appointment form',
    defaultMessage: 'Send request',
  },
  saveFailed: {
    id: 'appointments.AppointmentForm.saveFailed',
    description: 'General error message for submitting the appointment form',
    defaultMessage: "Sorry! We couldn't save your appointment request. Please try again later.",
  },
})

const timePreferenceOptions = [
  { value: TimePreference.Call, text: messages.timePreferenceCallBack },
  { value: TimePreference.Offer, text: messages.timePreferenceOfferDate },
  { value: TimePreference.Custom, text: messages.timePreferenceCustom },
]

const AppointmentForm: FC = () => {
  const [language] = useCurrentLanguage()
  const [request] = useGetAppointmentTypes({ lang: language })
  const { data: appointmentTypes = [] } = request
  const { formatMessage } = useIntl()
  const validationSchema = useAppointmentSchema()
  const [redirect, setRedirect] = useState(false)

  if (redirect) {
    return <Redirect to="/" />
  }

  return (
    <Formik
      initialValues={{
        type: 0,
        description: '',
        time: '',
        specifyTime: '',
        files: [],
        captcha: '',
      }}
      isInitialValid={false}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          actions.setSubmitting(true)
          await createAppointment(values)
          actions.setSubmitting(false)
          setRedirect(true)
        } catch (error) {
          actions.setSubmitting(false)
          actions.setStatus(formatMessage(messages.saveFailed))
        }
      }}
    >
      {({ values }) => (
        <Form>
          <div className="panel has-margin-bottom-4">
            <div className="panel-block panel-heading has-padding-y-5">
              <h1 className="title is-3 has-text-centered">{formatMessage(messages.heading)}</h1>
              <h2 className="subtitle is-4 is-secondary has-text-centered">
                {formatMessage(messages.subheading)}
              </h2>
            </div>
            <div className="panel-block has-padding-y-5">
              <div className="columns is-centered is-multiline has-padding-top-2 has-padding-bottom-4 has-padding-x-3">
                <Status />
                <div className="column is-10 has-padding-bottom-2">
                  <Field
                    component={SelectField}
                    label={formatMessage(messages.appointmentTypeLabel)}
                    name="type"
                    required
                    options={appointmentTypes.map((appointmentType) => ({
                      value: appointmentType.id,
                      label: appointmentType.name,
                    }))}
                  />
                </div>
                <div className="column is-10 has-padding-bottom-2">
                  <Field
                    component={TextArea}
                    label={formatMessage(messages.descriptionLabel)}
                    name="description"
                  />
                </div>
                <div className="column is-10 has-padding-bottom-3">
                  <Field
                    component={SelectField}
                    label={formatMessage(messages.timePreferenceLabel)}
                    name="time"
                    required
                    options={timePreferenceOptions.map((timePreferenceOption) => ({
                      value: timePreferenceOption.value,
                      label: formatMessage(timePreferenceOption.text),
                    }))}
                  />
                </div>
                {values.time === TimePreference.Custom && (
                  <div className="column is-10 has-padding-bottom-2">
                    <Field
                      component={TextField}
                      label={formatMessage(messages.specifyTimeLabel)}
                      name="specifyTime"
                      type="text"
                    />
                  </div>
                )}
                <div className="column is-10 has-padding-bottom-1">
                  <Field
                    component={FileField}
                    label={formatMessage(messages.attachmentsLabel)}
                    accept="image/*, text/*, .pdf, .xlsx, .xls, .doc, .docx, .csv"
                    name="files"
                    multiple
                  />
                </div>
                <div className="column is-10">
                  <Field component={CaptchaField} name="captcha" />
                </div>
              </div>
            </div>
            <div className="panel-block panel-footer">
              <SubmitButton className="has-text-weight-bold is-pulled-right">
                {formatMessage(messages.submit)}
              </SubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AppointmentForm
