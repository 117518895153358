import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Spinner from '../../../bulma/components/Spinner'

interface Props {
  isLoading: boolean
  itemsLeft: number
  onClick: () => void
}

const LoadMoreButton: FC<Props> = (props) => {
  const { isLoading, itemsLeft } = props
  if (!itemsLeft && !isLoading) {
    return null
  }

  return (
    <div className="panel-block is-order-row has-padding-y-0">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={isLoading ? undefined : props.onClick}
        className="card-footer-item has-text-weight-bold"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <FormattedMessage
            id="account.ShoppingHistory.LoadMoreButton.text"
            description="Load more button text in shopping history"
            defaultMessage="More orders {count}"
            values={{
              count: (
                <span className="group-count tag is-light has-text-weight-bold has-margin-left-3 has-padding-y-0 has-padding-x-3">
                  {itemsLeft}
                </span>
              ),
            }}
          />
        )}
      </a>
    </div>
  )
}

export default LoadMoreButton
