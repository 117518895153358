import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'

const RegisterButton: FC = () => {
  const { open } = useModal()

  return (
    <span
      className="button is-primary has-text-weight-bold"
      onClick={() => open(ModalType.Register)}
    >
      <FormattedMessage
        id="header.RegisterButton.text"
        description="Link to Register in navbar"
        defaultMessage="Register"
      />
    </span>
  )
}

export default RegisterButton
