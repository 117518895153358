import { merge } from 'lodash-es'

const red = '#FF6060'
const white = '#FFFFFF'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getStripeOptions = (extraOptions: object = {}) =>
  merge(
    {
      style: {
        base: {
          fontSize: '14px',
          fontWeight: 300,
          color: white,
          textAlign: 'left',
          fontFamily: 'sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
          ':-webkit-autofill': {
            color: white,
          },
        },
        invalid: {
          color: red,
          '::placeholder': {
            color: red,
          },
          ':-webkit-autofill': {
            color: red,
          },
        },
      },
    },
    extraOptions
  )
