import { first } from 'lodash-es'
import React, { FC, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import avatar from '../../../assets/img/ico_profile.svg'
import Image from '../../../bulma/components/Image'
import { updateAvatar } from '../../../user/api'
import { useCurrentUser } from '../../../user/hooks'

const Avatar: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isRemoving, setIsRemoving] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [user, setUser] = useCurrentUser()
  if (!user) {
    return null
  }

  return (
    <div className="panel-block is-profile-picture has-padding-5">
      <Image
        className="is-64x64 has-margin-x-3"
        src={user.avatar || avatar}
        alt={user.name}
        isRounded
      />
      {user.avatar && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className={`button is-medium is-outlined has-margin-x-2 ${
            isRemoving ? 'is-loading' : ''
          }`}
          onClick={async () => {
            try {
              if (!isRemoving) {
                setIsRemoving(true)
                const response = await updateAvatar(user.id, undefined)
                setUser(response.data)
                setIsRemoving(false)
              }
            } catch (error) {
              setIsRemoving(false)
            }
          }}
        >
          <FormattedMessage
            id="account.Details.Avatar.remove"
            description="Remove avatar button in user profile"
            defaultMessage="Remove"
          />
        </a>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={() => inputRef.current && inputRef.current.click()}
        className={`button is-medium is-primary has-margin-x-2 ${isUploading ? 'is-loading' : ''}`}
      >
        <FormattedMessage
          id="account.Details.Avatar.upload"
          description="Upload avatar button in user profile"
          defaultMessage="Upload"
        />
      </a>
      <input
        hidden
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={async (event) => {
          try {
            const file = first(event.target.files)
            if (!isUploading && file) {
              setIsUploading(true)
              const response = await updateAvatar(user.id, file)
              setUser(response.data)
              setIsUploading(false)
            }
          } catch (error) {
            setIsUploading(false)
          }
        }}
      />
    </div>
  )
}

export default Avatar
