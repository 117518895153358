import { FieldProps } from 'formik'
import React, { FC } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const CaptchaField: FC<FieldProps> = ({ form, field }) => {
  const onChange = (value: string): void => form.setFieldValue(field.name, value)

  return (
    <div className="field">
      <div className="control">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onChange={(token) => onChange(token || '')}
          onErrored={() => onChange('')}
          onExpired={() => onChange('')}
        />
      </div>
    </div>
  )
}

export default CaptchaField
