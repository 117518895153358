import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  required: {
    id: 'formik.validation.required',
    description: 'Required message in forms',
    defaultMessage: 'This is a required field',
  },
  invalidEmail: {
    id: 'formik.validation.invalidEmail',
    description: 'Invalid email message in forms',
    defaultMessage: 'This is an invalid email',
  },
})
