import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingNewsListItem: FC = () => (
  <div className="news-list-item box is-shadowless is-borderless is-transparent has-margin-y-0 has-padding-y-0 has-margin-bottom-4 has-padding-x-0">
    <article className="media">
      <div className="media-left">
        <Skeleton width={200} height={120} />
      </div>
      <div className="media-content">
        <div className="content has-margin-left-1">
          <h2 className="title is-5 has-margin-y-0">
            <Skeleton width="65%" />
          </h2>
          <Skeleton width="25%" />
          <Skeleton width="80%" />
          <Skeleton width="45%" />
        </div>
      </div>
    </article>
  </div>
)

export default LoadingNewsListItem
