import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import CategoryPicker from '../../categories/components/CategoryPicker'
import Calendar from './Calendar'
import EventList from './EventList'

const messages = defineMessages({
  title: {
    defaultMessage: 'Events',
    description: 'Page title for events page',
    id: 'events.EventsPage.title',
  },
})

const EventsPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="container">
        <CategoryPicker />
        <div className="columns">
          <div className="column is-9">
            <EventList />
          </div>
          <div className="column is-3 has-text-centered">
            <Calendar />
          </div>
        </div>
      </div>
    </section>
  )
}

export default EventsPage
