import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  goToPage: {
    id: 'bulma.Pagination.Link.goToPage',
    description: 'Page button in pagination',
    defaultMessage: 'Goto page {page}',
  },
})

interface Props {
  page: number
  isCurrent?: boolean
  onClick: (page: number) => void
}

const Link: FC<Props> = ({ page, onClick, isCurrent = false }) => {
  const { formatMessage } = useIntl()

  return (
    <li onClick={() => onClick(page)}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`pagination-link ${isCurrent && 'is-current'}`}
        aria-label={formatMessage(messages.goToPage, { page })}
        aria-current={isCurrent ? 'page' : undefined}
      >
        {page}
      </a>
    </li>
  )
}

export default Link
