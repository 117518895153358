import React, { FC, useState } from 'react'
import useBoolean from 'react-hanger/array/useBoolean'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore no up to date typings for this library atm
import Carousel, { Modal, ModalGateway } from 'react-images'
import { FormattedMessage } from 'react-intl'
import GalleryThumbnails from '../../posts/components/GalleryThumbnails'
import { GalleryImage } from '../../posts/types'

interface Props {
  images: GalleryImage[]
}

const Gallery: FC<Props> = ({ images }) => {
  const [isModalOpen, modalActions] = useBoolean(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  if (!images.length) {
    return null
  }

  return (
    <div className="column is-12 is-field">
      <label>
        <FormattedMessage
          id="events.Gallery.label"
          description="Gallery label in event page"
          defaultMessage="Gallery"
        />
      </label>
      <div className="gallery-grid columns is-gapless is-mobile has-padding-top-2 has-padding-bottom-3">
        <GalleryThumbnails
          images={images}
          onClick={(i) => {
            setCurrentIndex(i)
            modalActions.toggle()
          }}
        />
      </div>
      <ModalGateway>
        {isModalOpen && (
          <Modal onClose={modalActions.toggle}>
            <Carousel
              currentIndex={currentIndex}
              views={images.map((image) => ({
                source: image.sizes.orreca_front_large.src,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  )
}

export default Gallery
