import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingSearchPage: FC = () => (
  <section className="section has-padding-top-4 has-padding-top-5-tablet">
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-8-desktop">
          <h1 className="title is-3">
            <Skeleton width="30%" />
          </h1>
          <p className="subtitle has-text-grey-light">
            <Skeleton width="40%" />
          </p>
          <div className="news-list-item box is-shadowless is-borderless is-transparent has-margin-y-0 has-margin-bottom-4 is-paddingless">
            <h2 className="title is-5 has-margin-y-0">
              <Skeleton width="40%" />
            </h2>
            <span className="meta">
              <Skeleton width="75%" />
            </span>
          </div>
          <div className="news-list-item box is-shadowless is-borderless is-transparent has-margin-y-0 has-margin-bottom-4 is-paddingless">
            <h2 className="title is-5 has-margin-y-0">
              <Skeleton width="35%" />
            </h2>
            <span className="meta">
              <Skeleton width="65%" />
            </span>
          </div>
          <div className="news-list-item box is-shadowless is-borderless is-transparent has-margin-y-0 has-margin-bottom-4 is-paddingless">
            <h2 className="title is-5 has-margin-y-0">
              <Skeleton width="45%" />
            </h2>
            <span className="meta">
              <Skeleton width="80%" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default LoadingSearchPage
