import React, { FC } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import Spinner from '../../../bulma/components/Spinner'
import { useScript } from '../../../utils/hooks'
import PaymentMethodForm from './PaymentMethodForm'

const PaymentMethodFormContainer: FC = () => {
  const [isLoaded] = useScript('https://js.stripe.com/v3')
  if (!isLoaded) {
    return <Spinner />
  }

  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
      <Elements>
        <PaymentMethodForm />
      </Elements>
    </StripeProvider>
  )
}

export default PaymentMethodFormContainer
