import { AxiosPromise } from 'axios'
import axios from '../api/axios'
import * as t from './types'

export const createUser = (user: t.CreateUserRequest): AxiosPromise<t.User> =>
  axios.post<t.User>('/orreca/v1/users', user)

export const updateUser = (id: number, user: t.UpdateUserRequest): AxiosPromise<t.User> =>
  axios.put<t.User>(`/orreca/v1/users/${id}`, user)

export const updateCategories = (id: number, categories: number[]): AxiosPromise<t.User> =>
  axios.post<t.User>(`/orreca/v1/users/${id}/categories`, { categories })

export const getUser = (id: number, params: t.GetUserQueryParams = {}): AxiosPromise<t.User> =>
  axios.get<t.User>(`/orreca/v1/users/${id}`, { params })

export const updateBillingAddress = (
  userId: number,
  address: t.BillingAddress
): AxiosPromise<t.User> => axios.post<t.User>(`/orreca/v1/users/${userId}/billing-address`, address)

export const cancelSubscription = (userId: number): AxiosPromise<t.User> =>
  axios.post<t.User>(`/orreca/v1/users/${userId}/cancel-subscription`)

export const addPaymentMethod = (id: number, token: string): AxiosPromise<void> =>
  axios.post<void>(`/orreca/v1/users/${id}/payment-methods`, {
    paymentToken: token,
  })

export const activateUser = (email: string, token: string): AxiosPromise<t.User> =>
  axios.post<t.User>('/orreca/v1/users/activate', { email, token })

export const deactivateUser = (id: number): AxiosPromise<void> =>
  axios.post<void>(`/orreca/v1/users/${id}/deactivate`)

export const forgotPassword = (email: string): AxiosPromise<void> =>
  axios.post<void>('/orreca/v1/users/forgot-password', { email })

export const resetPassword = (data: t.ResetPasswordRequest): AxiosPromise<void> =>
  axios.post<void>('/orreca/v1/users/reset-password', data)

export const enableTwoFactor = (id: number, data: t.EnableTwoFactorRequest): AxiosPromise<t.User> =>
  axios.post<t.User>(`/orreca/v1/users/${id}/2fa/enable`, data)

export const disableTwoFactor = (id: number): AxiosPromise<t.User> =>
  axios.post<t.User>(`/orreca/v1/users/${id}/2fa/disable`)

export const updateAvatar = (id: number, avatar?: File): AxiosPromise<t.User> => {
  const data = new FormData()
  avatar && data.append('avatar', avatar)
  return axios.post<t.User>(`/orreca/v1/users/${id}/avatar`, data)
}
