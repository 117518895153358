import { isNil, sumBy, toInteger } from 'lodash-es'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import * as yup from 'yup'
import { useCategories } from '../categories/hooks'
import { messages as generalMessages } from '../formik/messages'

const maxFilesSizeMb = process.env.REACT_APP_FILES_UPLOAD_SIZE_LIMIT_MB
const maxFilesSizeKb = toInteger(maxFilesSizeMb) * 1024 * 1024

const messages = defineMessages({
  fileSizeExceeded: {
    id: 'topics.schema.fileSizeExceeded',
    description: 'Error message for when the user has added files larger than expected',
    defaultMessage: 'Total size of the attachments can not be over 10Mb',
  },
  invalidCategories: {
    id: 'topics.schema.invalidCategories',
    description: 'Error message when user has selected invalid category',
    defaultMessage: 'Invalid categories selected',
  },
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCreateTopicSchema = () => {
  const [categories] = useCategories()
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(generalMessages.required)
    const categoryIds = categories.map((category) => category.id)

    return yup.object().shape({
      title: yup.string().required(requiredMessage),
      categories: yup
        .array<number>()
        .of(yup.number())
        .test(
          'isValidCategory',
          formatMessage(messages.invalidCategories),
          (value) => !isNil(value) && value.every((id) => id && categoryIds.includes(id))
        )
        .required(requiredMessage),
      isPrivate: yup.boolean().required(requiredMessage),
      content: yup.string().required(requiredMessage),
      files: yup
        .array<File>()
        .test(
          'hasNotExceededFileSize',
          formatMessage(messages.fileSizeExceeded),
          (files) => sumBy(files, (file) => file.size) <= maxFilesSizeKb
        ),
    })
  }, [categories, formatMessage])
}
