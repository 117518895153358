import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

interface Props {
  size?: 'medium' | 'large'
}

const LoadingPost: FC<Props> = ({ size = 'medium' }) => (
  <div className="news-item card is-shadowless is-borderless is-transparent has-margin-bottom-0 has-margin-bottom-4-tablet">
    <div className="card-image">
      <div className="is-hidden-mobile">
        <Skeleton height={size === 'large' ? 383 : 186} />
      </div>
      <div className="is-hidden-tablet">
        <Skeleton height={165} />
      </div>
    </div>
    <div className="card-content has-padding-0 has-padding-bottom-2 has-padding-top-4 has-padding-top-4-tablet">
      <div className="content">
        <h3 className="title is-3 has-margin-bottom-2">
          <Skeleton />
        </h3>
        <Skeleton width="65%" />
        <Skeleton width="85%" />
        <Skeleton width="75%" />
      </div>
    </div>
  </div>
)

export default LoadingPost
