import React, { FC } from 'react'
import { FormattedDate } from 'react-intl'
import avatar from '../../assets/img/ico_profile.svg'
import FormattedCategory from '../../categories/components/FormattedCategory'
import { getAuthor } from '../../posts/utils'
import { Video } from '../types'

const VideoAuthorBlock: FC<Video> = (props) => {
  const author = getAuthor(props)

  return (
    <div className="video-head has-margin-top-4 has-margin-top-5-desktop">
      <h1 className="title is-2 has-margin-bottom-3">
        <span dangerouslySetInnerHTML={{ __html: props.title.rendered }} />
      </h1>
      <div className="meta is-clearfix has-padding-3">
        <figure className="image is-pulled-left has-margin-right-">
          {author && (
            <img
              className="profile-pic is-rounded"
              src={author.avatar || avatar}
              alt={author.name}
            />
          )}
        </figure>
        <div className="text is-pulled-left has-padding-top-2 has-padding-x-3">
          {author && <p className="name has-text-weight-bold has-margin-bottom-0">{author.name}</p>}
          <span className="date has-margin-0 has-text-grey">
            <FormattedDate value={props.date} />
            {props.categories.map((category) => (
              <FormattedCategory as="a" id={category} key={category} />
            ))}
          </span>
        </div>
      </div>
    </div>
  )
}

export default VideoAuthorBlock
