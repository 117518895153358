/* eslint-disable @typescript-eslint/camelcase */
import React, { FC } from 'react'
import Image from '../../bulma/components/Image'
import { Hide } from '../../bulma/types'
import { ImageSize, Post } from '../types'
import { getFeaturedMedia } from '../utils'

type Props = Hide & {
  post: Post
  size?: ImageSize
}

const FeaturedImage: FC<Props> = ({ post, size = 'full', isHidden }) => {
  const media = getFeaturedMedia(post)
  if (!media) {
    return null
  }

  const { sizes } = media.media_details

  if (!sizes.hasOwnProperty(size)) {
    size = 'full'
  }

  return <Image src={sizes[size].source_url} alt={media.alt_text} isHidden={isHidden} />
}

export default FeaturedImage
