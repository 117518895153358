/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, Fragment, useRef } from 'react'
import { useBoolean } from 'react-hanger/array/useBoolean'
import { useIntl } from 'react-intl'
import { sections } from '../../account/sections'
import avatar from '../../assets/img/ico_profile.svg'
import Link from '../../router/components/Link'
import LogoutButton from '../../user/components/LogoutButton'
import { useCurrentUser } from '../../user/hooks'
import { useClickOutside } from '../../utils/hooks'

const UserDropdown: FC = () => {
  const [currentUser] = useCurrentUser()
  const [isOpen, { setFalse, toggle }] = useBoolean(false)
  const { formatMessage } = useIntl()
  const ref = useRef(null)
  useClickOutside(ref, setFalse)

  if (!currentUser) {
    return null
  }

  return (
    <div
      ref={ref}
      className={`dropdown is-right user-menu has-margin-left-3 ${isOpen ? 'is-active' : ''}`}
    >
      <div className="dropdown-trigger">
        <button
          className="button is-white has-text-weight-bold has-padding-left-2"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={toggle}
        >
          <span className="icon is-small has-margin-left-0 has-margin-right-3">
            <i className="icon icon-user">
              <img src={currentUser.avatar || avatar} alt={currentUser.name} />
            </i>
          </span>
          <span>{currentUser.name}</span>
          <span className="icon is-small has-margin-left-0">
            <i className="icon icon-carret" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {sections.map((section, i) => (
            <Fragment key={i}>
              <span className="dropdown-item divider-title has-text-weight-bold">
                {formatMessage(section.title)}
              </span>
              {section.links.map(({ id, url, text }) => (
                <Link key={id} to={url} onClick={setFalse} className="dropdown-item">
                  {formatMessage(text)}
                </Link>
              ))}
              <hr className="dropdown-divider" />
            </Fragment>
          ))}
          <LogoutButton />
        </div>
      </div>
    </div>
  )
}

export default UserDropdown
