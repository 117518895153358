import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from '../../../router/components/Link'

const Consents: FC = () => (
  <div className="panel">
    <p className="panel-heading">
      <FormattedMessage
        id="account.Consents.title"
        description="Consents title in user consents page"
        defaultMessage="Terms of Service"
      />
    </p>
    <div className="panel-block is-column">
      <p>
        <FormattedMessage
          id="account.Consents.description"
          description="Consents description in user consents page"
          defaultMessage="With your registration you agree to our general terms and conditions, as well as terms of service, cancellation policy and data protection regulations."
        />
      </p>
      <Link
        to="/account/deactivate"
        className="button is-outlined is-medium has-margin-bottom-2 has-margin-top-4"
      >
        <FormattedMessage
          id="account.Consents.reject"
          description="Reject consents button in user consents page"
          defaultMessage="Reject consents"
        />
      </Link>
    </div>
  </div>
)

export default Consents
