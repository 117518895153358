import React, { FC } from 'react'
import Image from '../../bulma/components/Image'
import { ImageSize, Image as ImageType } from '../types'

interface Props {
  image?: ImageType
  size?: ImageSize
  className?: string
}

const ProductImage: FC<Props> = ({ image, size = 'full', className }) => {
  if (!image) {
    return null
  }

  const { alt, sizes } = image

  if (!sizes.hasOwnProperty(size)) {
    size = 'full'
  }

  return <Image src={sizes[size].src} alt={alt} className={className} />
}

export default ProductImage
