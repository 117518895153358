import classNames from 'classnames'
import { castArray, xor } from 'lodash-es'
import React, { FC, Fragment, useEffect } from 'react'
import { useQuery } from '../../router/hooks'
import { useCategories, useSelectedCategories } from '../hooks'
import { QueryParams } from '../types'
import CategoryPanelButton from './CategoryPanelButton'
import CategoryTag from './CategoryTag'

interface Props {
  isMobile?: boolean
}

const CategoryPicker: FC<Props> = ({ isMobile = false }) => {
  const [categories] = useCategories()
  const [query, setQuery] = useQuery<QueryParams>()
  const [selectedCategories, setSelectedCategories] = useSelectedCategories()
  const { categories: queryCategories } = query

  useEffect(() => {
    if (queryCategories) {
      setSelectedCategories(castArray(queryCategories).map(Number))
    }
  }, [queryCategories, setSelectedCategories])

  const classes = [
    'cat-filters',
    'buttons',
    'are-medium',
    'is-centered',
    isMobile ? 'has-margin-x-5' : 'is-hidden-mobile',
  ]

  return (
    <Fragment>
      {!isMobile && <CategoryPanelButton />}
      <div className={classNames(classes)}>
        {categories.map(({ id }) => (
          <CategoryTag
            key={id}
            id={id}
            isSelected={selectedCategories.includes(id)}
            onClick={(id: number): void => {
              const categories = xor(selectedCategories, [id])
              setSelectedCategories(categories)
              setQuery({ categories: categories.map(String) })
            }}
          />
        ))}
      </div>
    </Fragment>
  )
}

export default CategoryPicker
