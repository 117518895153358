import { startCase, toLower } from 'lodash-es'
import React, { FC } from 'react'
import { CardDetails as CardDetailsType } from '../types'

const CardDetails: FC<CardDetailsType> = (props) => (
  <ul className="is-value-list">
    <li className="is-highlighted-value">
      <span className="has-text-weight-bold">{startCase(toLower(props.brand))}</span>
    </li>
    <li>●●●● ●●●● ●●●● {props.last4}</li>
  </ul>
)

export default CardDetails
