import React, { FC } from 'react'
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom'
import { useCurrentLanguage } from '../../locale/hooks'

const NavLink: FC<NavLinkProps> = (props) => {
  const [currentLanguage] = useCurrentLanguage()
  const prefixedPath = `/${currentLanguage}${props.to}`
  return <BaseNavLink {...props} to={prefixedPath} />
}

export default NavLink
