import { DependencyList, useMemo } from 'react'
import { useAxios } from '../api/hooks'
import { UseAxios } from '../api/types'
import { GetPostQueryParams, GetPostsQueryParams, Post } from './types'

export const useGetPosts = (params: GetPostsQueryParams = {}): UseAxios<Post[]> => {
  const mParams = useMemo(() => JSON.stringify(params), [params])
  return useAxios<Post[]>({ url: '/orreca/v1/posts', params }, [mParams])
}

export const useGetPost = (
  id: number,
  params: GetPostQueryParams = {},
  deps: DependencyList = []
): UseAxios<Post> => useAxios<Post>({ url: `/orreca/v1/posts/${id}`, params }, [id, ...deps])

export const useGetPostPreview = (
  id: number,
  params: GetPostQueryParams = {},
  deps: DependencyList = []
): UseAxios<Post> =>
  useAxios<Post>({ url: `/orreca/v1/posts/${id}/preview`, params }, [id, ...deps])
