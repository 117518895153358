import { ComponentClass, FC, ReactNode, createFactory } from 'react'

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development'

export const isProduction = (): boolean => process.env.NODE_ENV === 'production'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const nest = (...Components: (ComponentClass | FC | string)[]) => {
  const factories = Components.map(createFactory)

  return ({ children, ...props }: { children: ReactNode }) =>
    factories.reduceRight((child, factory) => factory(props, child), children)
}

export const htmlToText = (html: string): string => {
  const tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

/**
 * Generates a random string with a specified length.
 * Note: This is not a cryptographically secure string generator!
 *
 * @param length string length
 */
export const randomString = (length: number): string => {
  const allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567'

  let secret = ''

  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * allowedChars.length)
    secret += allowedChars.charAt(index)
  }

  return secret
}

export const downloadFile = (data: string, filename: string, mime: string): void => {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
