import { noop } from 'lodash-es'
import { createContext } from 'react'
import { Language } from './enums'

interface Value {
  language: Language
  setLanguage: (language: Language) => void
}

export const LanguageContext = createContext<Value>({
  language: Language.English,
  setLanguage: noop,
})
