import { noop } from 'lodash-es'
import { createContext } from 'react'
import { QuickviewType } from './enums'

interface Value {
  quickview?: QuickviewType
  setQuickview: (quickview?: QuickviewType) => void
}

export const QuickviewContext = createContext<Value>({ setQuickview: noop })
