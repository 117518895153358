import { format, parse } from 'date-fns'
import React, { FC } from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { addDayToRange } from '../../dates/utils'
import * as dateUtils from '../../locale/dateUtils'
import { useCurrentLanguage } from '../../locale/hooks'
import { useQuery } from '../../router/hooks'
import { EventsFilterQueryParams } from '../types'
import './Calendar.css'

const DATE_FORMAT = 'yyyyMMdd'

const Calendar: FC = () => {
  const [language] = useCurrentLanguage()
  const [query, setQuery] = useQuery<EventsFilterQueryParams>()

  const now = new Date()
  const from = query.from ? parse(query.from, DATE_FORMAT, now) : undefined
  const to = query.to ? parse(query.to, DATE_FORMAT, now) : undefined

  return (
    // @ts-ignore this is straight from the documentation and it works
    <DayPicker
      className="Selectable"
      locale={language}
      months={dateUtils.MONTHS[language]}
      firstDayOfWeek={dateUtils.FIRST_DAY[language]}
      weekdaysLong={dateUtils.WEEKDAYS_LONG[language]}
      weekdaysShort={dateUtils.WEEKDAYS_SHORT[language]}
      selectedDays={[from, { from, to }]}
      modifiers={{ start: from, end: to }}
      onDayClick={(day) => {
        const range = addDayToRange(day, { from, to })
        setQuery({
          from: range.from ? format(range.from, DATE_FORMAT) : undefined,
          to: range.to ? format(range.to, DATE_FORMAT) : undefined,
        })
      }}
    />
  )
}

export default Calendar
