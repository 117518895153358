import { Headers } from './types'

const getHeader = (key: keyof Headers, headers: Headers): string | undefined =>
  headers[key] ? headers[key] : undefined

export const parsePaginationHeaders = (
  headers: Headers
): {
  total: number
  totalPages: number
} => {
  const total = getHeader('x-wp-total', headers)
  const totalPages = getHeader('x-wp-totalpages', headers)

  return {
    total: total ? parseInt(total, 10) : 0,
    totalPages: totalPages ? parseInt(totalPages, 10) : 0,
  }
}
