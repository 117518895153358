import React, { FC } from 'react'
import { FormattedDate } from 'react-intl'
import { Event as EventType } from '../../events/types'
import { getEventUrl } from '../../events/utils'
import Link from '../../router/components/Link'

const Event: FC<EventType> = (props) => {
  const eventUrl = getEventUrl(props)

  return (
    <div className="box event-item is-shadowless is-borderless is-transparent has-margin-y-0 has-margin-b-6 has-padding-y-4">
      <article className="media">
        <div className="media-left event-date">
          <FormattedDate day="numeric" value={props.startDate} />
        </div>
        <div className="media-content event-description">
          <div className="content has-text-weight-bold">
            <Link to={eventUrl} className="has-text-black">
              {props.title}
            </Link>
          </div>
        </div>
        <div className="media-right">
          <Link to={eventUrl} className="event-action">
            <i className="icon icon-arrow" />
          </Link>
        </div>
      </article>
    </div>
  )
}

export default Event
