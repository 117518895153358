import { Field } from 'formik'
import React, { FC, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import SelectField from '../../formik/components/SelectField'
import TextField from '../../formik/components/TextField'
import { useCountries } from '../../utils/hooks'

const messages = defineMessages({
  address: {
    id: 'user.BillingAddressFields.address',
    description: 'Address field in user billing address form',
    defaultMessage: 'Address',
  },
  country: {
    id: 'user.BillingAddressFields.country',
    description: 'Country field in user billing address form',
    defaultMessage: 'Country',
  },
  state: {
    id: 'user.BillingAddressFields.state',
    description: 'State/Province field in user billing address form',
    defaultMessage: 'State/Province',
  },
  postcode: {
    id: 'user.BillingAddressFields.postcode',
    description: 'ZIP/Postal code field in user billing address form',
    defaultMessage: 'ZIP/Postal code',
  },
  city: {
    id: 'user.BillingAddressFields.city',
    description: 'City field in user billing address form',
    defaultMessage: 'City',
  },
  company: {
    id: 'user.BillingAddressFields.company',
    description: 'Company field in user billing address form',
    defaultMessage: 'Company <o>(optional)</o>',
  },
  firstName: {
    id: 'user.BillingAddressFields.firstName',
    description: 'First name field in user billing address form',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'user.BillingAddressFields.lastName',
    description: 'Last name field in user billing address form',
    defaultMessage: 'Last name',
  },
  email: {
    id: 'user.BillingAddressFields.email',
    description: 'Email field in user billing address form',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'user.BillingAddressFields.phone',
    description: 'Phone field in user billing address form',
    defaultMessage: 'Phone <o>(optional)</o>',
  },
  vatNo: {
    id: 'user.BillingAddressFields.vatNo',
    description: 'VAT number field in user billing address form',
    defaultMessage: 'VAT no. <o>(optional)</o>',
  },
})

const BillingAddressFields: FC = () => {
  const { formatMessage } = useIntl()
  const countries = useCountries()

  const countryOptions = useMemo(
    () => countries.map(({ code, name }) => ({ value: code, label: name })),
    [countries]
  )

  return (
    <div className="columns is-multiline has-padding-top-2 has-padding-bottom-4 has-padding-x-3">
      <div className="column is-12 has-padding-bottom-1">
        <Field
          type="text"
          name="billingAddress.address1"
          autocomplete="billing street-address"
          label={formatMessage(messages.address)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-4 has-padding-bottom-1">
        <Field
          name="billingAddress.country"
          autocomplete="billing country"
          label={formatMessage(messages.country)}
          component={SelectField}
          options={countryOptions}
          required
        />
      </div>
      <div className="column is-4 has-padding-bottom-1">
        <Field
          type="text"
          name="billingAddress.state"
          autocomplete="billing region"
          label={formatMessage(messages.state)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-4 has-padding-bottom-1">
        <Field
          type="text"
          name="billingAddress.postcode"
          autocomplete="billing postal-code"
          label={formatMessage(messages.postcode)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="billingAddress.city"
          autocomplete="billing locality"
          label={formatMessage(messages.city)}
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="billingAddress.company"
          component={TextField}
          label={formatMessage(messages.company, {
            o: (msg: string) => <span className="is-opt">{msg}</span>,
          })}
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="billingAddress.firstName"
          label={formatMessage(messages.firstName)}
          autocomplete="billing fname"
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          name="billingAddress.lastName"
          label={formatMessage(messages.lastName)}
          autocomplete="billing lname"
          type="text"
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="email"
          name="billingAddress.email"
          label={formatMessage(messages.email)}
          autocomplete="billing email"
          component={TextField}
          required
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          name="billingAddress.phone"
          autocomplete="billing tel"
          component={TextField}
          type="tel"
          label={formatMessage(messages.phone, {
            o: (msg: string) => <span className="is-opt">{msg}</span>,
          })}
        />
      </div>
      <div className="column is-6 has-padding-bottom-1">
        <Field
          type="text"
          name="billingAddress.vatNo"
          component={TextField}
          label={formatMessage(messages.vatNo, {
            o: (msg: string) => <span className="is-opt">{msg}</span>,
          })}
        />
      </div>
    </div>
  )
}

export default BillingAddressFields
