import React, { FC, Fragment } from 'react'
import { FormattedDate } from 'react-intl'

interface Props {
  value: string | number | Date | undefined
}

const FormattedDateTime: FC<Props> = (props) => (
  <Fragment>
    <FormattedDate
      value={props.value}
      hour="2-digit"
      minute="2-digit"
      month="2-digit"
      year="2-digit"
      day="2-digit"
    />{' '}
    GMT
  </Fragment>
)

export default FormattedDateTime
