import { Field, FormikContextType, connect } from 'formik'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Switch from '../../formik/components/Switch'
import BillingAddressDetails from '../../user/components/BillingAddress'
import { useCurrentUser } from '../../user/hooks'
import { CheckoutFormValues } from '../types'
import BillingAddressForm from './BillingAddressForm'

interface Props {
  formik: FormikContextType<CheckoutFormValues>
}

const BillingAddress: FC<Props> = ({ formik }) => {
  const [currentUser] = useCurrentUser()

  const hasSavedAddress = !!(currentUser && currentUser.billingAddress)
  const isFormVisible = !hasSavedAddress || formik.values.useOtherBillingAddress

  return (
    <div className="panel">
      <p className="panel-heading">
        <FormattedMessage
          id="checkout.BillingAddress.heading"
          description="Billing address heading in checkout page"
          defaultMessage="Billing Address"
        />
      </p>
      {hasSavedAddress && (
        <div className="panel-block is-highlighted-block">
          <Field name="useOtherBillingAddress" label="Use different address" component={Switch} />
        </div>
      )}
      <div className="panel-block">
        {isFormVisible || !currentUser ? (
          <BillingAddressForm />
        ) : (
          currentUser &&
          currentUser.billingAddress && <BillingAddressDetails {...currentUser.billingAddress} />
        )}
      </div>
    </div>
  )
}

export default connect<{}, CheckoutFormValues>(BillingAddress)
