import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Capacity as CapacityType } from '../types'

const Capacity: FC<CapacityType> = ({ total, available }) => {
  if (!total) {
    return null
  }

  return (
    <div className="column is-3 is-field">
      <label>
        <FormattedMessage
          id="events.Capacity.label"
          description="Available seats label in event view"
          defaultMessage="Available seats"
          values={{ available, total }}
        />
      </label>
      <div className="value has-padding-top-2">
        <FormattedMessage
          id="events.Capacity.amount"
          description="Available and total seat amount in event view"
          defaultMessage="{available} of {total}"
          values={{ available, total }}
        />
      </div>
    </div>
  )
}

export default Capacity
