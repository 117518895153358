import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { getFeaturedMedia, isNewContent } from '../../posts/utils'
import { ImageSize } from '../../products/types'
import { Podcast } from '../types'

type Props = Podcast & {
  position?: number
  size?: ImageSize
}

const PodcastThumbnail: FC<Props> = (props) => {
  const { position = 1, size = 'orreca_front_small', ...podcast } = props

  const media = getFeaturedMedia(podcast)

  const mediaDetails = (media && media.media_details) || undefined
  const backgroundImage = mediaDetails ? mediaDetails.sizes[size] : undefined

  const overlayClassMap = [1, 2, 3, 2, 3, 1]
  const overlayClassName = overlayClassMap[position % overlayClassMap.length] || 1

  return (
    <span
      className={`card-content overlay${overlayClassName}`}
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage.source_url})` : undefined,
      }}
    >
      <span className="title">
        <span dangerouslySetInnerHTML={{ __html: podcast.title.rendered }} />
        {isNewContent(podcast) && (
          <span className="tag is-medium is-uppercase is-new is-rounded has-margin-left-2">
            <FormattedMessage
              id="podcasts.PodcastsThumbNail.isNew"
              description="The 'is new' tag of a podcast item in the podcast list."
              defaultMessage="New"
            />
          </span>
        )}
      </span>
      <span className="duration">{podcast.crb_podcast_duration}</span>
      <span className="play">
        <FormattedMessage
          id="podcasts.PodcastsThumbNail.listenNow"
          description="The 'listen now' text in the podcasts list."
          defaultMessage="Listen now"
        />
      </span>
    </span>
  )
}

export default PodcastThumbnail
