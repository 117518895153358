import { Field, FieldProps, Form, Formik } from 'formik'
import { xor } from 'lodash-es'
import React, { FC } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Spinner from '../../bulma/components/Spinner'
import Status from '../../formik/components/Status'
import SubmitButton from '../../formik/components/SubmitButton'
import TextField from '../../formik/components/TextField'
import { useCurrentLanguage } from '../../locale/hooks'
import Modal from '../../modal/components/Modal'
import { useModal, usePageModal } from '../../modal/hooks'
import { useGetTermsOfService } from '../../pages/hooks'
import { subscribe } from '../api'
import { useGetLists } from '../hooks'
import { useSignupSchema } from '../schema'
import ListSwitch from './ListSwitch'

const messages = defineMessages({
  modalTitle: {
    id: 'newsletter.SignupForm.modalTitle',
    description: 'Newsletter signup modal title',
    defaultMessage: 'Subscribe for newsletter',
  },
  email: {
    id: 'newsletter.SignupForm.email',
    description: 'Email field label in newsletter signup modal',
    defaultMessage: 'Enter your email address',
  },
  generalError: {
    id: 'newsletter.SignupForm.generalError',
    description: 'General error message in newsletter signup modal',
    defaultMessage: "Sorry! We couldn't subscribe you right now. Please try again later.",
  },
})

const SignupForm: FC = () => {
  const [language] = useCurrentLanguage()
  const [request] = useGetLists({ lang: language })
  const validationSchema = useSignupSchema()
  const { formatMessage } = useIntl()
  const { close } = useModal()
  const { data: lists = [] } = request
  const { open: openPage } = usePageModal()
  const [tosRequest] = useGetTermsOfService(language)
  const { data: tosPage } = tosRequest

  return (
    <Formik
      initialValues={{
        email: '',
        lists: [],
        agreeToTerms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          actions.setStatus(undefined)
          actions.setSubmitting(true)
          const { email, lists } = values
          await subscribe({ email, lists })
          actions.setSubmitting(false)
        } catch (error) {
          actions.setSubmitting(false)
          actions.setStatus(formatMessage(messages.generalError))
        }
      }}
    >
      {() => (
        <Form>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <section className="pullout-block has-padding-4">
            <Field name="email" placeholder={formatMessage(messages.email)} component={TextField} />
          </section>
          <Modal.Body>
            <Status />
            <h5 className="title is-5">
              <FormattedMessage
                id="newsletter.SignupForm.title"
                description="Newsletter signup modal title"
                defaultMessage="Choose topics you are interested in:"
              />
            </h5>
            <ul className="switch-list has-padding-x-3">
              {request.isLoading ? (
                <Spinner />
              ) : (
                <Field name="lists">
                  {(props: FieldProps) => {
                    const { field, form } = props
                    const value: number[] = field.value
                    return lists.map((list) => (
                      <ListSwitch
                        key={list.id}
                        {...list}
                        isDisabled={false}
                        isSelected={value.includes(list.id)}
                        onClick={(id) => form.setFieldValue(field.name, xor(value, [id]))}
                      />
                    ))
                  }}
                </Field>
              )}
            </ul>
            <div className="box tos-box is-shadowless has-padding-3">
              <Field required name="agreeToTerms">
                {(props: FieldProps) => (
                  <label className="checkbox has-margin-top-2">
                    <input type="checkbox" className="has-margin-right-3" {...props.field} />
                    <FormattedMessage
                      id="newsletter.SignupForm.termsAndConditions"
                      description="Terms and conditions text in newsletter signup modal"
                      defaultMessage="By signing up for Newsletter I accept <a>Terms and Conditions</a>."
                      values={{
                        a: (msg: string) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a onClick={() => tosPage && openPage(tosPage.id)}>{msg}</a>
                        ),
                      }}
                    />
                  </label>
                )}
              </Field>
            </div>
          </Modal.Body>
          <Modal.Footer className="is-aligned-right">
            <button
              type="button"
              className="button is-text is-medium has-text-weight-bold is-pulled-right"
              onClick={() => close()}
            >
              <FormattedMessage
                id="newsletter.SignupForm.cancel"
                description="Cancel button in newsletter signup modal"
                defaultMessage="Cancel"
              />
            </button>
            <SubmitButton className="is-pulled-right">
              <FormattedMessage
                id="newsletter.SignupForm.subscribe"
                description="Subscribe button in newsletter signup modal"
                defaultMessage="Subscribe"
              />
            </SubmitButton>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default SignupForm
