import { Form, Formik } from 'formik'
import { compact } from 'lodash-es'
import React, { FC, Fragment } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Steps from '../../../bulma/components/Steps'
import SubmitButton from '../../../formik/components/SubmitButton'
import Modal from '../../../modal/components/Modal'
import { useModal } from '../../../modal/hooks'
import { useCurrentUser } from '../../hooks'
import AvatarStep from './AvatarStep'
import CategoriesStep from './CategoriesStep'
import ConfirmationStep from './ConfirmationStep'
import NewsletterStep from './NewsletterStep'

const messages = defineMessages({
  categoriesStep: {
    id: 'user.ImproveProfileModal.categoriesStep',
    description: "Profile improvement modal categories' step name",
    defaultMessage: 'Preferred topics',
  },
  newsletterStep: {
    id: 'user.ImproveProfileModal.newsletterStep',
    description: 'Profile improvement modal newsletter step name',
    defaultMessage: 'Newsletter subscriptions',
  },
  avatarStep: {
    id: 'user.ImproveProfileModal.avatarStep',
    description: 'Profile improvement modal avatar step name',
    defaultMessage: 'Profile picture',
  },
})

const steps = [
  { name: undefined, component: ConfirmationStep },
  { name: messages.categoriesStep, component: CategoriesStep },
  { name: messages.newsletterStep, component: NewsletterStep },
  { name: messages.avatarStep, component: AvatarStep },
]

const stepMessages = compact(steps.map((step) => step.name))

const ImproveProfileModal: FC = () => {
  const { formatMessage } = useIntl()
  const { close } = useModal()
  const [currentUser] = useCurrentUser()

  if (!currentUser) {
    close()
  }

  const stepNames = stepMessages.map((msg) => formatMessage(msg))

  return (
    <Formik
      isInitialValid
      initialValues={{ step: 0 }}
      onSubmit={(values, form) => {
        values.step === 3 && close()
        form.setSubmitting(false)
        form.setFieldValue('step', values.step + 1)
      }}
    >
      {({ values, setFieldValue }) => {
        const isFirstStep = values.step === 0

        return (
          <Form>
            <Modal.Header title="Complete profile" />
            <Modal.Body>
              {!isFirstStep && <Steps steps={stepNames} activeStep={values.step - 1} />}
              {React.createElement(steps[values.step].component)}
            </Modal.Body>
            <Modal.Footer className={isFirstStep ? 'is-borderless' : undefined}>
              {!isFirstStep && (
                <Fragment>
                  {values.step > 1 && (
                    <button
                      type="button"
                      className="button is-text is-medium is-outlined is-f-left has-text-weight-bold"
                      onClick={() => setFieldValue('step', values.step - 1)}
                    >
                      <FormattedMessage
                        id="user.ImproveProfileModal.previous"
                        description="Previous button in profile improvement modal"
                        defaultMessage="Previous"
                      />
                    </button>
                  )}
                  <button
                    type="button"
                    className="button is-text is-medium has-text-weight-bold is-f-right has-margin-l-auto"
                    onClick={() => close()}
                  >
                    <FormattedMessage
                      id="user.ImproveProfileModal.cancel"
                      description="Cancel button in profile improvement modal"
                      defaultMessage="Cancel"
                    />
                  </button>
                  <SubmitButton className="is-f-right">
                    {values.step === 3 ? (
                      <FormattedMessage
                        id="user.ImproveProfileModal.submit"
                        description="Submit button in profile improvement modal"
                        defaultMessage="Finalize"
                      />
                    ) : (
                      <FormattedMessage
                        id="user.ImproveProfileModal.next"
                        description="Next button in profile improvement modal"
                        defaultMessage="Next"
                      />
                    )}
                  </SubmitButton>
                </Fragment>
              )}
            </Modal.Footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ImproveProfileModal
