import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { messages } from '../formik/messages'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSubscriptionSchema = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const requiredMessage = formatMessage(messages.required)

    return yup.object().shape({
      step: yup.number().required(requiredMessage).min(1).max(4),
      plan: yup.number().when('step', {
        is: 1,
        then: yup.number().moreThan(0).required(requiredMessage),
        otherwise: yup.number().notRequired(),
      }),
      billingAddress: yup.object().when('step', {
        is: 2,
        then: yup.object().shape({
          firstName: yup.string().required(requiredMessage),
          lastName: yup.string().required(requiredMessage),
          company: yup.string().notRequired(),
          vatNo: yup.string().notRequired(),
          address1: yup.string().required(requiredMessage),
          city: yup.string().required(requiredMessage),
          state: yup.string().required(requiredMessage),
          postcode: yup.string().required(requiredMessage),
          country: yup.string().required(requiredMessage),
          email: yup.string().email(formatMessage(messages.invalidEmail)).required(requiredMessage),
          phone: yup.string().notRequired(),
        }),
        otherwise: yup.object().notRequired(),
      }),
      paymentMethod: yup.object().when('step', {
        is: 3,
        then: yup.object().shape({
          cardDetails: yup.object().shape({
            holder: yup.string().required(requiredMessage),
            cvcValid: yup.boolean().oneOf([true]).required(requiredMessage),
            expirationDateValid: yup.boolean().oneOf([true]).required(requiredMessage),
            numberValid: yup.boolean().oneOf([true]).required(requiredMessage),
          }),
        }),
        otherwise: yup.object().notRequired(),
      }),
    })
  }, [formatMessage])
}
