import { FieldProps } from 'formik'
import { pullAt } from 'lodash-es'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import ErrorMessage from './ErrorMessage'

interface Props extends FieldProps {
  label: string
  multiple?: boolean
  accept?: string
}

const FileField: FC<Props> = ({ field, form, label, ...props }) => (
  <div className="field">
    <label className="label is-medium">{label}</label>
    <div className="control">
      <div className="file is-right is-medium has-name is-fullwidth">
        <label className="file-label has-padding-left-0">
          <input
            {...props}
            className="file-input"
            type="file"
            onClick={(event) => (event.currentTarget.value = '')}
            onChange={(event) => {
              const files = event.target.files || []
              form.setFieldValue(field.name, Array.from(files))
              form.setFieldTouched(field.name)
            }}
          />
          <span className="file-cta">
            <span className="file-label">
              <FormattedMessage
                id="fields.file.browse"
                description="Browse files button"
                defaultMessage="Browse files"
              />
            </span>
          </span>
          <span className="file-name" />
        </label>
      </div>
    </div>
    {field.value && field.value.length > 0 && (
      <ul className="attachments has-padding-top-4">
        {field.value.map((file: File, i: number) => (
          <li key={i}>
            <span className="name">{file.name}</span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a
              className="remove"
              onClick={() => {
                const files = field.value
                pullAt(files, i)
                form.setFieldValue(field.name, files)
              }}
            >
              <FormattedMessage
                id="formik.FileField.remove"
                description="File remove button"
                defaultMessage="Remove"
              />
            </a>
          </li>
        ))}
      </ul>
    )}
    <ErrorMessage name={field.name} />
  </div>
)

export default FileField
