import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalType } from '../../../modal/enums'
import { useModal } from '../../../modal/hooks'
import BillingAddress from '../../../user/components/BillingAddress'
import { useCurrentUser } from '../../../user/hooks'

const Address: FC = () => {
  const { open } = useModal()
  const [currentUser] = useCurrentUser()
  if (!currentUser) {
    return null
  }

  const { billingAddress } = currentUser

  return (
    <div className="panel">
      <p className="panel-heading">
        <FormattedMessage
          id="account.Billing.Address.heading"
          description="Address card heading in billing account section"
          defaultMessage="Billing Info"
        />
      </p>
      <div className="panel-block is-column has-padding-bottom-4">
        {billingAddress && <BillingAddress {...billingAddress} />}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => open(ModalType.BillingAddress)} className="button is-outlined is-medium">
          {billingAddress ? (
            <FormattedMessage
              id="account.Billing.Address.edit"
              description="Change billing address button in billing account section"
              defaultMessage="Change billing info"
            />
          ) : (
            <FormattedMessage
              id="account.Billing.Address.add"
              description="Add billing address button in billing account section"
              defaultMessage="Add billing info"
            />
          )}
        </a>
      </div>
    </div>
  )
}

export default Address
