import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'

interface Props {
  title: string
  description: string
  icon: string
}

const SignupBlock: FC<Props> = (props) => {
  const { open } = useModal()

  return (
    <div className="box box-premium-info has-margin-top-4 has-padding-bottom-5 is-radiusless has-text-centered">
      <div className={`picto picto-${props.icon} has-margin-top-4`} />
      <h2 className="title is-4">{props.title}</h2>
      <p>{props.description}</p>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={() => open(ModalType.Register)}
        className="button is-primary has-text-weight-bold has-margin-top-5"
      >
        <FormattedMessage
          id="posts.SignupBlock.register"
          description="Register link in generic signup block"
          defaultMessage="Register"
        />
      </a>
      <p className="has-margin-top-5">
        <FormattedMessage
          id="posts.SignupBlock.logIn"
          description="Message that opens login modal in generic signup block"
          defaultMessage="Or <a>log in</a> if you’re already a registered user."
          values={{
            a: (msg: string) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a onClick={() => open(ModalType.Login)}>{msg}</a>
            ),
          }}
        />
      </p>
    </div>
  )
}
export default SignupBlock
