import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import CategoryPicker from '../../categories/components/CategoryPicker'
import NewsList from './NewsList'

const messages = defineMessages({
  title: {
    defaultMessage: 'News',
    description: 'Page title for news page',
    id: 'news.NewsPage.title',
  },
})

const NewsPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="container">
        <CategoryPicker />
        <NewsList />
      </div>
    </section>
  )
}

export default NewsPage
