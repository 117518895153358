import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { messages } from '../formik/messages'
import { useBillingAddressSchema } from '../user/schema'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCheckoutSchema = () => {
  const { formatMessage } = useIntl()
  const billingAddressSchema = useBillingAddressSchema()

  return useMemo(() => {
    const requiredMessage = formatMessage(messages.required)

    return yup.object().shape({
      useOtherShippingAddress: yup.boolean(),
      shippingAddress: yup.object().when('useOtherShippingAddress', {
        is: true,
        then: yup.object().shape({
          firstName: yup.string().required(requiredMessage),
          lastName: yup.string().required(requiredMessage),
          address1: yup.string().required(requiredMessage),
          city: yup.string().required(requiredMessage),
          company: yup.string().notRequired(),
          state: yup.string().required(requiredMessage),
          postcode: yup.string().required(requiredMessage),
          country: yup.string().required(requiredMessage),
        }),
        otherwise: yup.object().notRequired(),
      }),
      useOtherBillingAddress: yup.boolean(),
      billingAddress: yup.object().when('useOtherBillingAddress', {
        is: true,
        then: billingAddressSchema,
        otherwise: yup.object().notRequired(),
      }),
      useOtherPaymentMethod: yup.boolean(),
      paymentMethod: yup.object().when('useOtherPaymentMethod', {
        is: true,
        then: yup.object().shape({
          method: yup.string().oneOf(['paypal', 'stripe', 'free']).required(requiredMessage),
          agreeToTerms: yup.boolean().when('method', {
            is: (value) => ['paypal', 'stripe'].includes(value),
            then: yup.boolean().oneOf([true]).required(requiredMessage),
            else: yup.boolean().notRequired(),
          }),
          cardDetails: yup.object().when('method', {
            is: 'stripe',
            then: yup.object().shape({
              holder: yup.string().required(requiredMessage),
              cvcValid: yup.boolean().oneOf([true]).required(requiredMessage),
              expirationDateValid: yup.boolean().oneOf([true]).required(requiredMessage),
              numberValid: yup.boolean().oneOf([true]).required(requiredMessage),
            }),
            otherwise: yup.object().notRequired(),
          }),
        }),
        otherwise: yup.object().notRequired(),
      }),
    })
  }, [billingAddressSchema, formatMessage])
}
