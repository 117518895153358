import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, useIntl } from 'react-intl'
import CategoryPicker from '../../categories/components/CategoryPicker'
import AllPodcastsSwitch from './AllPodcastsSwitch'
import PodcastList from './PodcastList'

const messages = defineMessages({
  title: {
    id: 'podcasts.PodcastsPage.title',
    description: 'Page title for the podcasts page.',
    defaultMessage: 'Podcasts',
  },
})

const PodcastsPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="container">
        <CategoryPicker />
        <AllPodcastsSwitch />
        <PodcastList perPage={18} />
      </div>
    </section>
  )
}

export default PodcastsPage
