import { Field } from 'formik'
import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import StripeField from '../../formik/components/StripeField'
import TextField from '../../formik/components/TextField'

const messages = defineMessages({
  cardNumber: {
    id: 'checkout.StripeForm.cardNumber',
    description: 'Credit card number field label',
    defaultMessage: 'Card number',
  },
  cardExpiry: {
    id: 'checkout.StripeForm.cardExpiry',
    description: 'Credit card expiry field label',
    defaultMessage: 'Expiration date',
  },
  cardCvc: {
    id: 'checkout.StripeForm.cardCvc',
    description: 'Credit card CVC field label',
    defaultMessage: 'CVC',
  },
  cardHolder: {
    id: 'checkout.StripeForm.cardHolder',
    description: 'Credit card holder field label',
    defaultMessage: "Cardholder's name",
  },
})

const StripeForm: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <div className="creditcard has-margin-bottom-5">
      <div className="columns has-padding-left-5 has-padding-right-5 has-padding-top-4  has-padding-bottom-5 is-multiline">
        <div className="column is-12 has-padding-bottom-1">
          <Field
            type="cardNumber"
            component={StripeField}
            label={formatMessage(messages.cardNumber)}
            name="paymentMethod.cardDetails.numberValid"
            options={{ style: { base: { textAlign: 'center' } } }}
          />
        </div>
        <div className="column is-4 has-padding-bottom-1">
          <Field
            type="cardExpiry"
            label={formatMessage(messages.cardExpiry)}
            component={StripeField}
            name="paymentMethod.cardDetails.expirationDateValid"
          />
        </div>
        <div className="column is-3 has-padding-bottom-1">
          <Field
            type="cardCVC"
            label={formatMessage(messages.cardCvc)}
            component={StripeField}
            name="paymentMethod.cardDetails.cvcValid"
          />
        </div>
        <div className="column is-8 has-padding-bottom-0">
          <Field
            type="text"
            autocomplete="cc-name"
            name="paymentMethod.cardDetails.holder"
            label={formatMessage(messages.cardHolder)}
            component={TextField}
            required
          />
        </div>
      </div>
    </div>
  )
}

export default StripeForm
