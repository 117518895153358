import { Field, FieldProps, FormikContextType, connect } from 'formik'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { CheckoutFormValues } from '../types'
import AgreeToTerms from './AgreeToTerms'
import PaypalForm from './PaypalForm'
import StripeForm from './StripeForm'

interface Props {
  formik: FormikContextType<CheckoutFormValues>
}

const PaymentMethodForm: FC<Props> = ({ formik: { values } }) => (
  <div className="column is-10 is-offset-1 has-margin-top-4">
    <div className="tabs is-toggle is-medium is-fullwidth has-padding-bottom-4">
      <Field name="paymentMethod.method">
        {(props: FieldProps) => {
          const { form, field } = props

          return (
            <ul>
              <li
                className={field.value === 'stripe' ? 'is-active' : ''}
                onClick={() => form.setFieldValue('paymentMethod.method', 'stripe')}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <FormattedMessage
                    id="checkout.PaymentMethodForm.creditCard"
                    description="Credit card tab in checkout form"
                    defaultMessage="Credit Card"
                  />
                </a>
              </li>
              <li
                className={field.value === 'paypal' ? 'is-active' : ''}
                onClick={() => form.setFieldValue('paymentMethod.method', 'paypal')}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <FormattedMessage
                    id="checkout.PaymentMethodForm.payPal"
                    description="PayPal tab in checkout form"
                    defaultMessage="PayPal"
                  />
                </a>
              </li>
            </ul>
          )
        }}
      </Field>
    </div>
    {values.paymentMethod.method === 'stripe' ? <StripeForm /> : <PaypalForm />}
    <AgreeToTerms />
  </div>
)

export default connect<{}, CheckoutFormValues>(PaymentMethodForm)
