import React, { FC, Fragment } from 'react'
import { Attribute } from '../types'

interface Props {
  attributes: Attribute[]
}

const ProductAttributes: FC<Props> = ({ attributes }) => (
  <dl>
    {attributes.map(({ name, position, options }) => (
      <Fragment key={position}>
        <dt>{name}:</dt>
        <dd>{options.join(',')}</dd>
      </Fragment>
    ))}
  </dl>
)

export default ProductAttributes
