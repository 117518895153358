import { noop } from 'lodash-es'
import { createContext } from 'react'
import { SetState } from '../utils/types'
import { User } from './types'

interface Value {
  user?: User
  setUser: SetState<User | undefined>
}

export const UserContext = createContext<Value>({ setUser: noop })
