import classNames from 'classnames'
import React, { FC } from 'react'
import FormattedCategory from '../../categories/components/FormattedCategory'

interface Props {
  categories: number[]
  className?: string
}

const Categories: FC<Props> = ({ categories, className }) => (
  <div className={classNames(['tags', className])}>
    {categories.map((category) => (
      <FormattedCategory as="a" id={category} key={category} className="tag is-medium is-white" />
    ))}
  </div>
)

export default Categories
