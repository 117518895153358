import classNames from 'classnames'
import React, { FC } from 'react'

interface Props {
  className?: string
}

const Footer: FC<Props> = ({ children, className }) => (
  <div className={classNames(['modal-card-foot', className])}>{children}</div>
)

export default Footer
