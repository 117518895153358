import { useCart } from '../cart/hooks'
import { useCurrentUser } from '../user/hooks'
import { CheckoutFormValues } from './types'

export const useInitialValues = (): CheckoutFormValues => {
  const [user] = useCurrentUser()
  const [cart] = useCart()

  const hasShippingAddress = !!(user && user.shippingAddress)
  const hasBillingAddress = !!(user && user.billingAddress)
  const cartNeedsShipping = !!(cart && cart.needsShipping)
  const cartNeedsPayment = !!(cart && cart.needsPayment)
  const hasOtherPaymentMethod = !!(user && user.cardDetails)

  return {
    useOtherShippingAddress: cartNeedsShipping && (!user || !hasShippingAddress),
    useOtherPaymentMethod: cartNeedsPayment && (!user || !hasOtherPaymentMethod),
    useOtherBillingAddress: !user || !hasBillingAddress,
    shippingAddress: {
      firstName: '',
      lastName: '',
      address1: '',
      city: '',
      company: '',
      state: '',
      postcode: '',
      country: '',
    },
    billingAddress: {
      firstName: '',
      lastName: '',
      company: '',
      vatNo: '',
      address1: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
      email: '',
      phone: '',
    },
    paymentMethod: {
      method: cartNeedsPayment ? 'stripe' : 'free',
      agreeToTerms: false,
      cardDetails: {
        holder: '',
        cvcValid: false,
        expirationDateValid: false,
        numberValid: false,
      },
    },
  }
}
