import { flatten, sortBy } from 'lodash-es'
import React, { FC, Fragment, RefObject, useMemo, useState } from 'react'
import { useArray } from 'react-hanger/array'
import { FormattedMessage } from 'react-intl'
import Spinner from '../../bulma/components/Spinner'
import { useCurrentUser } from '../../user/hooks'
import { createComment } from '../api'
import { useGetPaginatedComments } from '../hooks'
import { Comment } from '../types'
import CommentListItem from './CommentListItem'

interface Props {
  postId: number
  canComment: boolean
  containerRef?: RefObject<HTMLDivElement>
}

const Comments: FC<Props> = ({ postId, ...props }) => {
  const [currentUser] = useCurrentUser()
  const [newComment, setNewComment] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [addedComments, { push: addComment }] = useArray<Comment>([])
  const exclude = addedComments.map((addedComment) => addedComment.id)
  const [request, loadMore] = useGetPaginatedComments({ postId, exclude })
  const { items: comments = [], total, isLoading, hasMore } = request
  const canComment = props.canComment && currentUser
  const mergedComments = useMemo(() => sortBy(flatten([comments, addedComments]), 'date'), [
    comments,
    addedComments,
  ])

  if (!canComment && mergedComments.length < 1) {
    return null
  }

  return (
    <div ref={props.containerRef} className="card comments-panel content-block has-margin-y-5">
      <header className="card-header is-shadowless is-borderless has-padding-top-4 has-padding-bottom-3 has-padding-left-4">
        <h2 className="title is-4">
          <FormattedMessage
            id="comments.Comments.title"
            description="Comments block title"
            defaultMessage="Comments"
          />
        </h2>
      </header>
      <div className="divider">
        {(hasMore || isLoading) && (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <a
            className="card-footer-item has-text-weight-bold"
            onClick={() => !isLoading && loadMore()}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <FormattedMessage
                id="comments.Comments.previousComments"
                description="Previous comments link in comments list"
                defaultMessage="Previous comments {count}"
                values={{
                  count: (
                    <span className="group-count tag is-light has-text-weight-bold has-margin-left-3 has-padding-y-1 has-padding-x-3">
                      {total - comments.length}
                    </span>
                  ),
                }}
              />
            )}
          </a>
        )}
      </div>
      <div className="card-content has-padding-x-4 has-padding-top-4">
        {mergedComments.map((comment) => (
          <CommentListItem key={comment.id} {...comment} />
        ))}
        {canComment && (
          <Fragment>
            {mergedComments.length > 0 && <hr />}
            <footer className="card-footer">
              <div className="box comment-entry is-radiusless is-borderless is-shadowless has-margin-0 is-fullwidth">
                <div className="control is-fullwidth">
                  <h3 className="title has-margin-top-4 has-margin-bottom-3">
                    <FormattedMessage
                      id="comments.Comments.joinDiscussion"
                      description="Call to action above comment textarea"
                      defaultMessage="Join the discussion"
                    />
                  </h3>
                  <textarea
                    value={newComment}
                    disabled={isSubmitting}
                    className="textarea is-fullwidth has-fixed-size is-normal has-margin-bottom-4"
                    onChange={(event) => setNewComment(event.target.value)}
                  />
                  <button
                    disabled={isSubmitting || !newComment}
                    className={`button is-link has-text-weight-bold is-pulled-right ${
                      isSubmitting ? 'is-loading' : ''
                    }`}
                    onClick={async () => {
                      setIsSubmitting(true)
                      const { data } = await createComment(postId, newComment)
                      addComment(data)
                      setNewComment('')
                      setIsSubmitting(false)
                    }}
                  >
                    <FormattedMessage
                      id="comments.Comments.submit"
                      description="Comment submit button text"
                      defaultMessage="Post Comment"
                    />
                  </button>
                </div>
              </div>
            </footer>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default Comments
