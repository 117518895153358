import { xor } from 'lodash-es'
import React, { FC, Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Spinner from '../../bulma/components/Spinner'
import { useCurrentLanguage } from '../../locale/hooks'
import { subscribe, unsubscribe } from '../../newsletter/api'
import ListSwitch from '../../newsletter/components/ListSwitch'
import { useGetLists } from '../../newsletter/hooks'
import { useCurrentUser } from '../hooks'

const Newsletters: FC = () => {
  const [currentUser, setCurrentUser] = useCurrentUser()
  const [language] = useCurrentLanguage()
  const [request] = useGetLists({ lang: language })
  const [isUpdating, setIsUpdating] = useState(false)

  if (!currentUser) {
    return null
  }

  const { data: lists = [] } = request

  return (
    <Fragment>
      <div className="has-padding-4">
        <h1 className="title is-5 has-margin-bottom-3">
          <FormattedMessage
            id="user.Newsletters.title"
            description="Title in user's newsletter subscriptions"
            defaultMessage="Choose which newsletters are you interested in:"
          />
        </h1>
        <p className="is-secondary">
          <FormattedMessage
            id="user.Newsletters.description"
            description="Description text in user's newsletter subscriptions"
            defaultMessage="Here you can indicate which topics you are interested in."
          />
        </p>
      </div>
      <ul className="switch-list has-padding-x-3">
        {request.isLoading ? (
          <Spinner />
        ) : (
          lists.map((list) => {
            const isSelected = currentUser.newsletters.includes(list.id)

            return (
              <ListSwitch
                {...list}
                key={list.id}
                isSelected={isSelected}
                isDisabled={isUpdating}
                onClick={async (id) => {
                  try {
                    if (!isUpdating) {
                      setIsUpdating(true)
                      const data = { lists: [id] }
                      await (isSelected ? unsubscribe(data) : subscribe(data))
                      const newsletters = xor(currentUser.newsletters, [id])
                      setCurrentUser({ ...currentUser, newsletters })
                      setIsUpdating(false)
                    }
                  } catch (error) {
                    setIsUpdating(false)
                  }
                }}
              />
            )
          })
        )}
      </ul>
    </Fragment>
  )
}

export default Newsletters
