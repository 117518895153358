import { flatten } from 'lodash-es'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { sections } from './sections'
import { SectionLink } from './types'

export const useCurrentLink = (): SectionLink | undefined => {
  const { section } = useParams<{ section: string }>()

  return useMemo(() => {
    const allLinks = flatten(sections.map((aSection) => aSection.links))
    return allLinks.find((aLink) => aLink.id === section)
  }, [section])
}
