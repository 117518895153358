import { defineMessages } from 'react-intl'

const messages = defineMessages({
  appointments: {
    id: 'header.links.appointmentsLink',
    description: 'Link to Registration in navbar',
    defaultMessage: 'Appointments',
  },
  events: {
    id: 'header.links.eventsLink',
    description: 'Link to Registration in navbar',
    defaultMessage: 'Events',
  },
  home: {
    id: 'header.links.homeLink',
    description: 'Link to Registration in navbar',
    defaultMessage: 'Home',
  },
  news: {
    id: 'header.links.newsLink',
    description: 'Link to News in navbar',
    defaultMessage: 'News',
  },
  qa: {
    id: 'header.links.qaLink',
    description: 'Link to Registration in navbar',
    defaultMessage: 'Q&A',
  },
  shop: {
    id: 'header.links.shopLink',
    description: 'Link to Registration in navbar',
    defaultMessage: 'Shop',
  },
  videosAndPodcasts: {
    id: 'header.links.videosAndPodcastsLink',
    description: 'Link to Registration in navbar',
    defaultMessage: 'Videos & Podcasts',
  },
})

export const links = [
  {
    text: messages.home,
    to: '/',
    exact: true,
  },
  {
    text: messages.news,
    to: '/news',
  },
  {
    text: messages.events,
    to: '/events',
  },
  {
    text: messages.appointments,
    to: '/appointments',
  },
  {
    text: messages.qa,
    to: '/topics',
  },
  {
    text: messages.shop,
    to: '/shop',
  },
  {
    text: messages.videosAndPodcasts,
    to: '/videos-and-podcasts',
  },
]
