import React, { FC, Fragment } from 'react'
import { useIntl } from 'react-intl'
import Quickview from '../../quickview/components/Quickview'
import { useQuickview } from '../../quickview/hooks'
import NavLink from '../../router/components/NavLink'
import { useCurrentUser } from '../../user/hooks'
import { links } from '../links'
import LoggedInButtons from './LoggedInButtons'
import LoggedOutButtons from './LoggedOutButtons'

const MobileMenu: FC = () => {
  const [user] = useCurrentUser()
  const { formatMessage } = useIntl()
  const { close } = useQuickview()

  return (
    <Fragment>
      <Quickview.Header />
      <Quickview.Body>
        <div className="quickview-block">
          <nav className="navbar">
            {links.map(({ to, text }) => (
              <NavLink
                onClick={close}
                className="navbar-item "
                activeClassName="is-active"
                key={to}
                to={to}
                exact
              >
                {formatMessage(text)}
              </NavLink>
            ))}
          </nav>
        </div>
      </Quickview.Body>
      <Quickview.Footer>{user ? <LoggedInButtons /> : <LoggedOutButtons />}</Quickview.Footer>
    </Fragment>
  )
}

export default MobileMenu
