import classNames from 'classnames'
import React, { FC, Fragment } from 'react'
import { FormattedDate } from 'react-intl'
import { Event } from '../types'
import EventListItem from './EventListItem'

interface Props {
  date: string
  events: Event[]
  isFirst: boolean
}

const EventListBlock: FC<Props> = ({ date, events, isFirst }) => {
  const className = classNames([
    'title',
    'is-events-separator',
    'is-5',
    `has-margin-top-${isFirst ? 1 : 5}`,
    'has-margin-bottom-3',
    'has-margin-left-3',
  ])

  return (
    <Fragment>
      <h2 className={className}>
        <FormattedDate value={date} year="numeric" month="long" />
      </h2>
      <div className="box is-bordered has-margin-y-0 has-padding-y-0 has-margin-bottom-4 has-padding-x-0">
        <ul className="events-list">
          {events.map((event) => (
            <EventListItem key={event.id} {...event} />
          ))}
        </ul>
      </div>
    </Fragment>
  )
}

export default EventListBlock
