import React, { FC } from 'react'
import LoadingPodcastListItem from './LoadingPodcastListItem'

const LoadingPodcastList: FC = () => (
  <div className="columns is-multiline has-margin-top-3">
    <LoadingPodcastListItem />
    <LoadingPodcastListItem />
    <LoadingPodcastListItem />
  </div>
)

export default LoadingPodcastList
