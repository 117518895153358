export enum ModalType {
  BillingAddress = 'billing-address',
  CookieConsent = 'cookie-consent',
  CreateTopic = 'create-topic',
  DisableTwoFactor = 'disable-two-factor',
  EnableTwoFactor = 'enable-two-factor',
  ForgotPassword = 'forgot-password',
  ImproveProfile = 'improve-profile',
  Login = 'login',
  NewsletterSignup = 'newsletter-signup',
  OrderDetails = 'order-details',
  Page = 'page',
  PaymentMethod = 'payment-method',
  Register = 'register',
  RegisterSuccess = 'register-success',
  ResetPassword = 'reset-password',
  Subscribe = 'subscribe',
  UserDetails = 'user-details',
}
