import { differenceInMonths } from 'date-fns'
import { User } from '../user/types'
import { Topic } from './types'

export const getTopicUrl = (topic: Topic): string => `/topics/${topic.id}/${topic.slug}`

export const isNewTopic = (topic: Topic): boolean =>
  differenceInMonths(new Date(), new Date(topic.created)) < 1

export const isPrivateTopic = (topic: Topic): boolean => topic.isPrivate

export const getAuthor = (topic: Topic): Partial<User> | undefined =>
  topic._embedded && topic._embedded.author && topic._embedded.author[0]
