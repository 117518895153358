import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import CategoryPicker from '../../categories/components/CategoryPicker'
import PodcastList from '../../podcasts/components/PodcastList'
import Link from '../../router/components/Link'
import VideoList from '../../videos/components/VideoList'

const messages = defineMessages({
  title: {
    id: 'videosPodcasts.VideosPodcasts.title',
    description: 'Page title for the videos and podcasts page.',
    defaultMessage: 'Videos & Podcasts',
  },
})

const VideosPodcasts: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <div className="bd-main">
      <section className="section has-padding-top-4 has-padding-bottom-6 has-padding-top-5-tablet">
        <Helmet>
          <title>{formatMessage(messages.title)}</title>
        </Helmet>
        <div className="container">
          <CategoryPicker />
          <div className="has-margin-top-3">
            <div className="level">
              <div className="level-left">
                <div className="level-item has-padding-top-3 has-padding-bottom-0">
                  <h2 className="title is-4">
                    <FormattedMessage
                      id="videosPodcasts.VideosList.latestPodcasts"
                      description="Latest podcasts button in the video and podcast list."
                      defaultMessage="Latest <span>Podcasts</span>"
                      values={{
                        span: (msg: string) => <span className="has-text-grey-light">{msg}</span>,
                      }}
                    />
                  </h2>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item is-title-actions">
                  <Link
                    to="/videos-and-podcasts/podcasts"
                    className="button is-secondary is-medium has-text-weight-bold is-fullwidth-mobile"
                  >
                    <FormattedMessage
                      id="videosPodcasts.VideosList.seeAllPodcasts"
                      description="See all podcasts button in the video and podcast list."
                      defaultMessage="See all Podcasts"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <PodcastList perPage={3} hidePagination />

            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h2 className="title is-4">
                    <FormattedMessage
                      id="videosPodcasts.VideosList.latestVideos"
                      description="Latest videos button in the video and podcast list."
                      defaultMessage="Latest <span>Videos</span>"
                      values={{
                        span: (msg: string) => <span className="has-text-grey-light">{msg}</span>,
                      }}
                    />
                  </h2>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item is-title-actions">
                  <Link
                    to="/videos-and-podcasts/videos"
                    className="button is-secondary is-medium has-text-weight-bold is-fullwidth-mobile"
                  >
                    <FormattedMessage
                      id="videosPodcasts.VideosList.seeAll"
                      description="See all videos button in the video and podcast list."
                      defaultMessage="See all Videos"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <VideoList perPage={8} hidePagination />
          </div>
        </div>
      </section>
    </div>
  )
}

export default VideosPodcasts
