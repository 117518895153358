import { isEqual } from 'lodash-es'
import React, { FC, Fragment, useState } from 'react'
import { FormattedMessage, MessageDescriptor, defineMessages, useIntl } from 'react-intl'
import * as t from '../../../products/types'
import { useQuery } from '../../../router/hooks'
import { useDebounce } from '../../../utils/hooks'

const messages = defineMessages({
  sortByTitleAsc: {
    id: 'shop.FilterPanel.OrderFilter.sortByTitleAsc',
    description: 'Sort by title ascending option in mobile product filter',
    defaultMessage: 'A to Z',
  },
  sortByTitleDesc: {
    id: 'shop.FilterPanel.OrderFilter.sortByTitleDesc',
    description: 'Sort by title descending option in mobile product filter',
    defaultMessage: 'Z to A',
  },
  sortByPriceAsc: {
    id: 'shop.FilterPanel.OrderFilter.sortByPriceAsc',
    description: 'Sort by price ascending option in mobile product filter',
    defaultMessage: 'Price (Lowest first)',
  },
  sortByPriceDesc: {
    id: 'shop.FilterPanel.OrderFilter.sortByPriceDesc',
    description: 'Sort by price descending option in mobile product filter',
    defaultMessage: 'Price (Highest first)',
  },
})

interface OrderOption {
  label: MessageDescriptor
  orderby: t.SelectableOrderBy
  order: t.ProductOrder
}

type SelectedOption = Omit<OrderOption, 'label'>

const orderOptions: OrderOption[] = [
  { label: messages.sortByTitleAsc, orderby: 'title', order: 'asc' },
  { label: messages.sortByTitleDesc, orderby: 'title', order: 'desc' },
  { label: messages.sortByPriceAsc, orderby: 'price', order: 'asc' },
  { label: messages.sortByPriceDesc, orderby: 'price', order: 'desc' },
]

const OrderFilter: FC = () => {
  const { formatMessage } = useIntl()
  const [query, setQuery] = useQuery<t.ProductsFilterQueryParams>()
  const { order = 'asc', orderby = 'title' } = query
  const [selectedOption, setSelectedOption] = useState<SelectedOption>({
    order,
    orderby,
  })

  useDebounce(() => setQuery(selectedOption), 350, [selectedOption])

  return (
    <Fragment>
      <p className="subtitle">
        <FormattedMessage
          id="shop.FilterPanel.FormatFilter.label"
          description="Product sort label in mobile product filter"
          defaultMessage="Sort by:"
        />
      </p>
      <ul className="filter-list">
        {orderOptions.map(({ label, ...option }, i) => (
          <li key={`sort${i}`}>
            <div className="field">
              <input
                id={`sort${i}`}
                type="radio"
                checked={isEqual(selectedOption, option)}
                className="is-checkradio has-background-color is-info"
              />
              <label htmlFor={`sort${i}`} onClick={() => setSelectedOption(option)}>
                {formatMessage(label)}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </Fragment>
  )
}

export default OrderFilter
