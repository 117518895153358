import { Field, Form, Formik } from 'formik'
import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import Status from '../../formik/components/Status'
import SubmitButton from '../../formik/components/SubmitButton'
import TextField from '../../formik/components/TextField'
import Modal from '../../modal/components/Modal'
import { useModal } from '../../modal/hooks'
import { useQuery } from '../../router/hooks'
import { resetPassword } from '../api'
import { useResetPasswordSchema } from '../schema'

const messages = defineMessages({
  email: {
    id: 'user.ResetPasswordForm.email',
    description: 'Email field in reset password form',
    defaultMessage: 'Email',
  },
  password: {
    id: 'user.ResetPasswordForm.password',
    description: 'Password field in reset password form',
    defaultMessage: 'New password',
  },
  passwordConfirm: {
    id: 'user.ResetPasswordForm.passwordConfirm',
    description: 'Confirm password field in reset password form',
    defaultMessage: 'Confirm password',
  },
  submit: {
    id: 'user.ResetPasswordForm.submit',
    description: 'Submit button in reset password form',
    defaultMessage: 'Submit',
  },
  modalTitle: {
    id: 'user.ResetPasswordForm.modalTitle',
    description: 'Reset password modal title',
    defaultMessage: 'Reset password',
  },
  unknownError: {
    id: 'user.ResetPasswordForm.unknownError',
    description: 'Unknown error message in reset password form',
    defaultMessage: 'Something went wrong! Please try again later.',
  },
})

const TextFields = [
  {
    name: 'email',
    type: 'email',
    label: messages.email,
    autocomplete: 'username',
  },
  {
    name: 'password',
    type: 'password',
    label: messages.password,
    autoComplete: 'new-password',
  },
  {
    name: 'passwordConfirm',
    type: 'password',
    label: messages.passwordConfirm,
    autoComplete: 'new-password',
  },
]

const ResetPasswordForm: FC = () => {
  const validationSchema = useResetPasswordSchema()
  const { formatMessage } = useIntl()
  const { close } = useModal()
  const [query, setQuery] = useQuery<{ token: string }>()
  const { token } = query
  if (!token) {
    close()
  }

  return (
    <Formik
      initialValues={{ email: '', password: '', passwordConfirm: '' }}
      validationSchema={validationSchema}
      onSubmit={({ email, password }, actions) => {
        resetPassword({ email, password, token })
          .then(() => {
            actions.setStatus(undefined)
            setQuery({ token: undefined })
          })
          .catch(({ response: { data } }) => {
            actions.setSubmitting(false)
            actions.setStatus(data.message || formatMessage(messages.unknownError))
          })
      }}
    >
      {() => (
        <Form noValidate>
          <Modal.Header title={formatMessage(messages.modalTitle)} />
          <Modal.Body>
            <Status />
            {TextFields.map(({ label, ...props }) => (
              <Field
                {...props}
                key={props.name}
                required
                component={TextField}
                label={formatMessage(label)}
              />
            ))}
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton>{formatMessage(messages.submit)}</SubmitButton>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
