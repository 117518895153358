import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCart } from '../hooks'

interface Props {
  productId: number
  quantity: number
}

const CartButton: FC<Props> = ({ productId, quantity }) => {
  const [cart, { addItem }] = useCart()
  const [isAdding, setIsAdding] = useState(false)

  const item = cart && cart.items.find((item) => item.product.id === productId)
  const newQuantity = item ? item.quantity + quantity : quantity

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={`button is-primary is-medium has-text-weight-bold ${isAdding ? 'is-loading' : ''}`}
      onClick={async () => {
        try {
          if (!isAdding) {
            setIsAdding(true)
            await addItem(productId, newQuantity)
            setIsAdding(false)
          }
        } catch (error) {
          setIsAdding(false)
        }
      }}
    >
      <FormattedMessage
        id="cart.CartButton.addToCart"
        description="Add to cart button in product detail view"
        defaultMessage="Add to cart"
      />
    </a>
  )
}

export default CartButton
