import React, { FC } from 'react'
import Newsletters from '../../../user/components/Newsletters'

const Newsletter: FC = () => (
  <div className="panel">
    <p className="panel-heading is-borderless has-padding-y-1" />
    <div className="panel-block is-column">
      <Newsletters />
    </div>
  </div>
)

export default Newsletter
