import React, { FC } from 'react'
import LoadingProductListItem from './LoadingProductListItem'

const LoadingProductList: FC = () => (
  <div className="columns is-multiline has-margin-top-3">
    <div className="column is-4">
      <LoadingProductListItem />
    </div>
    <div className="column is-4">
      <LoadingProductListItem />
    </div>
    <div className="column is-4">
      <LoadingProductListItem />
    </div>
  </div>
)

export default LoadingProductList
