import { FieldProps } from 'formik'
import React, { FC } from 'react'

interface Props extends FieldProps {
  label: string
}

const Switch: FC<Props> = ({ field, label, form, ...props }) => {
  const { value, ...fieldProps } = field

  const onClick = (): void => form.setFieldValue(field.name, !field.value)

  return (
    <div className="field">
      <div className="control">
        <input
          type="checkbox"
          className="switch is-rounded is-medium"
          checked={value}
          {...fieldProps}
          {...props}
        />
        <label onClick={onClick}>{label}</label>
      </div>
    </div>
  )
}

export default Switch
