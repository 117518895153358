import React, { FC } from 'react'
import { Color } from '../types'

interface Props {
  type?: Color
  onClose?: () => void
}

const Notification: FC<Props> = ({ type = 'primary', onClose, children }) => (
  <div className={`notification is-${type}`}>
    {onClose && <span className="delete" onClick={onClose} />}
    {children}
  </div>
)

export default Notification
