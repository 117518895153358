import React, { FC, useEffect, useState } from 'react'
import { ModalContext } from '../context'
import { ModalType } from '../enums'

const ModalProvider: FC = ({ children }) => {
  const [modal, setModal] = useState<ModalType | undefined>()

  useEffect(() => {
    const classList = document.documentElement.classList
    modal ? classList.add('is-clipped') : classList.remove('is-clipped')
  }, [modal])

  return <ModalContext.Provider value={{ modal, setModal }}>{children}</ModalContext.Provider>
}

export default ModalProvider
