import React, { FC, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from '../../router/components/Link'
import { useCurrentUser } from '../../user/hooks'
import { Order } from '../types'

const SuccessMessage: FC<Order> = (props) => {
  const [currentUser] = useCurrentUser()

  const hasCustomerId = !!(props.customerId && props.customerId > 0)
  const canSeeHistory = !!(hasCustomerId && currentUser)

  return (
    <div className="panel">
      <div className="panel-block is-centered">
        <div className="columns">
          <div className="msg-success column has-text-centered is-full-width has-margin-top-4 has-margin-bottom-5">
            <div className="picto-success has-margin-top-5 has-margin-bottom-4" />
            <h1 className="title is-4">
              <FormattedMessage
                id="orders.SuccessMessage.success"
                description="Success message in order page"
                defaultMessage="Payment success!"
              />
            </h1>
            <h2 className="subtitle is-5">
              <FormattedMessage
                id="orders.SuccessMessage.downloadDigital"
                description="Digital download message in order page"
                defaultMessage="You can download your digital content below."
              />
              {canSeeHistory && (
                <Fragment>
                  <br />
                  <FormattedMessage
                    id="orders.SuccessMessage.shoppingHistory"
                    description="Message that directs to shopping history in order page"
                    defaultMessage="Or you can access it later under your <a>shopping history</a>"
                    values={{
                      a: (msg: string) => <Link to="/account/shopping-history">{msg}</Link>,
                    }}
                  />
                </Fragment>
              )}
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessMessage
