import { compact } from 'lodash-es'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import CartButton from '../../cart/components/CartButton'
import Categories from '../../posts/components/Categories'
import Redirect from '../../router/components/Redirect'
import { useGetProduct } from '../hooks'
import { getMetaDescription, getProductUrl } from '../utils'
import LoadingProduct from './LoadingProduct'
import PreviewButton from './PreviewButton'
import Price from './Price'
import ProductAttributes from './ProductAttributes'
import ProductGallery from './ProductGallery'
import QuantityPicker from './QuantityPicker'

const Product: FC = () => {
  const [quantity, setQuantity] = useState(1)
  const params = useParams<{ id: string; slug: string }>()
  const [request] = useGetProduct(+params.id)
  const { data: product, isLoading } = request

  if (isLoading) {
    return <LoadingProduct />
  }

  if (!product || !product.isVisible) {
    return <Redirect to="/shop" />
  }

  if (product.id === +params.id && params.slug !== product.slug) {
    return <Redirect to={getProductUrl(product)} />
  }

  return (
    <section className="section has-padding-y-4 has-padding-y-5-tablet">
      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content={getMetaDescription(product)} />
      </Helmet>
      <div className="container">
        <div className="columns is-multiline">
          <div className="column product-description is-6 has-margin-bottom-6-tablet">
            <h1 className="title is-3 has-margin-bottom-3">{product.title}</h1>
            <div className="has-margin-bottom-4">
              <Price priceHtml={product.priceHtml} />
            </div>
            <div className="has-margin-bottom-4">
              <QuantityPicker hasLabel min={1} value={quantity} onChange={setQuantity} />
            </div>
            <div className="actions">
              <CartButton productId={product.id} quantity={quantity} />
              <PreviewButton url={product.preview} />
            </div>
            <ProductAttributes attributes={product.attributes} />
            <p dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
            <Categories categories={product.categories} />
          </div>
          <div className="column product-images is-6">
            <ProductGallery images={compact([product.featuredImage, ...product.galleryImages])} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Product
