import classNames from 'classnames'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalType } from '../../../modal/enums'
import { useModal } from '../../../modal/hooks'
import { useCurrentUser } from '../../../user/hooks'

const Security: FC = () => {
  const { open } = useModal()
  const [currentUser] = useCurrentUser()
  if (!currentUser) {
    return null
  }

  const has2fa = currentUser.twoFactorEnabled
  const modal = has2fa ? ModalType.DisableTwoFactor : ModalType.EnableTwoFactor

  return (
    <div className="panel">
      <p className="panel-heading is-borderless has-padding-y-1" />
      <div className="panel-block is-column has-padding-5">
        <h1 className="title is-5 has-margin-bottom-3">
          <FormattedMessage
            id="account.Security.twoFactorTitle"
            description="2FA title text in user security page"
            defaultMessage="Two-factor authentication"
          />
        </h1>
        <p>
          <FormattedMessage
            id="account.Security.twoFactorDescription"
            description="2FA description text in user security page"
            defaultMessage="To improve security ORRECA integrates with Google Authenticator for two-factor authentication."
          />
        </p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => open(modal)}
          className={classNames([
            'button',
            'is-medium',
            'has-margin-top-4',
            'has-margin-bottom-2',
            has2fa ? 'is-danger' : 'is-primary',
            has2fa && 'is-outlined',
          ])}
        >
          {has2fa ? (
            <FormattedMessage
              id="account.Security.disableTwoFactor"
              description="Disable 2FA button text in user security page"
              defaultMessage="Disable"
            />
          ) : (
            <FormattedMessage
              id="account.Security.enableTwoFactor"
              description="Enable 2FA button text in user security page"
              defaultMessage="Enable"
            />
          )}
        </a>
      </div>
    </div>
  )
}

export default Security
