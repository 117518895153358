import * as dates from 'date-fns'
import { last } from 'lodash-es'
import { Range, TimeDifference } from './types'

export const clone = (d: Date): Date => new Date(d.getTime())

export const isDayBefore = (d1: Date, d2: Date): boolean => {
  const day1 = clone(d1).setHours(0, 0, 0, 0)
  const day2 = clone(d2).setHours(0, 0, 0, 0)
  return day1 < day2
}

export const addDayToRange = (
  day: Date,
  range: Range = { from: undefined, to: undefined }
): Range => {
  let { from, to } = range
  if (!from) {
    from = day
  } else if (from && to && dates.isSameDay(from, to)) {
    from = undefined
    to = undefined
  } else if (to && isDayBefore(day, from)) {
    from = day
  } else if (to && dates.isSameDay(day, to)) {
    from = day
    to = day
  } else {
    to = day
    if (isDayBefore(to, from)) {
      to = from
      from = day
    }
  }

  return { from, to }
}

export const getTimeDifference = (timestamp: string): TimeDifference | undefined => {
  const now = new Date()
  const modified = dates.parseISO(timestamp)

  const diffs: TimeDifference[] = [
    { unit: 'day', value: dates.differenceInDays(modified, now) },
    { unit: 'hour', value: dates.differenceInHours(modified, now) },
    { unit: 'minute', value: dates.differenceInMinutes(modified, now) },
    { unit: 'second', value: dates.differenceInSeconds(modified, now) },
  ]

  return diffs.find((diff) => diff.value !== 0) || last(diffs)
}
