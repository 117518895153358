import React, { FC, useEffect, useState } from 'react'
import { ModalType } from '../../modal/enums'
import { useModal } from '../../modal/hooks'
import { ConsentsContext } from '../context'
import { Consents } from '../types'
import { getConsents, storeConsents } from '../utils'

const ConsentsProvider: FC = ({ children }) => {
  const [consents, setConsents] = useState<Consents | undefined>(getConsents())
  const { open } = useModal()

  useEffect(() => {
    consents ? storeConsents(consents) : open(ModalType.CookieConsent)
  }, [consents, open])

  return (
    <ConsentsContext.Provider value={{ consents, setConsents }}>
      {children}
    </ConsentsContext.Provider>
  )
}

export default ConsentsProvider
