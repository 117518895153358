import React, { FC } from 'react'
import Categories from '../../posts/components/Categories'
import Link from '../../router/components/Link'
import { Product } from '../types'
import { getProductUrl } from '../utils'
import Price from './Price'
import ProductImage from './ProductImage'

const ProductListItem: FC<Product> = (props) => {
  const productUrl = getProductUrl(props)

  return (
    <div className="shop-item card is-shadowless is-borderless is-transparent has-margin-y-0 has-padding-y-0 has-padding-x-0 has-margin-bottom-4">
      <div className="card-image">
        <Link to={productUrl}>
          <ProductImage
            image={props.featuredImage}
            size="orreca_shop_thumbnail"
            className="is-4by3"
          />
        </Link>
      </div>
      <div className="card-content has-padding-x-0 has-padding-bottom-0">
        <div className="content">
          <h2 className="title is-5 has-margin-y-0 has-text-weight-bold">
            <Link to={productUrl}>{props.title}</Link>
          </h2>
          <div className="has-margin-y-2">
            <Price priceHtml={props.priceHtml} />
          </div>
          <p
            className="description has-margin-bottom-2"
            dangerouslySetInnerHTML={{ __html: props.excerptHtml }}
          />
          <Categories categories={props.categories} />
        </div>
      </div>
    </div>
  )
}

export default ProductListItem
