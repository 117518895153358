import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Attachment } from '../types'

interface Props {
  attachments: Attachment[]
}

const Attachments: FC<Props> = ({ attachments }) => {
  if (!attachments.length) {
    return null
  }

  return (
    <div className="columns is-multiline">
      <div className="column is-8 is-field">
        <label>
          <FormattedMessage
            id="topics.Attachments.label"
            description="Attachments list label"
            defaultMessage="Attachments"
          />
        </label>
        <ul className="attachments has-padding-top-2">
          {attachments.map((attachment) => (
            <li key={attachment.id}>
              <span className="name" key={attachment.id}>
                {attachment.name}
              </span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href={attachment.url}
                className="download"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="topics.Attachments.download"
                  description="Attachment download link"
                  defaultMessage="Download"
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Attachments
